import { Marker, Polygon, Tooltip, useMap } from "react-leaflet";
import { createClusterCustomIcon, gpzIcon } from "../LeafletIcons";
import GeometryUtil from "leaflet-geometryutil";
import { useEffect, useMemo, useRef, useState } from "react";
import L, { LatLngExpression } from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import CustomPolyline from "./CustomPolyline";
import { getDistance } from "../getDistance";
import { MapOptionsInterface } from "../ElectricityMap";
import { Trans, useTranslation } from "react-i18next";
import { switchLatLng } from "../../../../../../../common/helpers";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const parse = require("wellknown");

const CustomElements = ({
  analysisData,
  mapOptions,
}: {
  analysisData: any;
  mapOptions: MapOptionsInterface;
}) => {
  const timeoutID = useRef<any>(null);
  const map = useMap();
  const polygonRef = useRef<any>();
  const [closesPole, setClosesPole] = useState<any>(null);
  const [lineToClosesPole, setLineToClosesPole] = useState<any>(null);
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [closesPlotPoint, setClosesPlotPoint] = useState<any>(null);
  const { t } = useTranslation();

  const cablesUrls = useMemo(
    () =>
      analysisData?.electricity?.find((data: any) => data.type === "cable")
        ?.urls || [],
    [analysisData?.electricity]
  );

  const substationsUrls = useMemo(
    () =>
      analysisData?.electricity?.find((data: any) => data.type === "substation")
        ?.urls || [],
    [analysisData?.electricity]
  );

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;
      if (analysisData?.electricity) {
        for (const url of cablesUrls) {
          //https://wiki.openstreetmap.org/wiki/Overpass_API#Public_Overpass_API_instances
          //overpass.kumi.systems is better than over-api.de
          const changedUrl = url?.replace(
            "overpass-api.de",
            "overpass.kumi.systems"
          );

          const getCablesData = () => {
            return new Promise<void>(
              (resolve) =>
                (timeoutID.current = setTimeout(() => {
                  fetch(changedUrl)
                    .then((r) => {
                      if (r.ok) {
                        return r.json();
                      } else {
                        throw Error(r.status.toString());
                      }
                    })
                    .then((r) => {
                      setMapData((prev) => ({
                        ...prev,
                        cables: {
                          ...prev?.cables,
                          success: prev?.cables.success + 1,
                          data: prev?.cables.data?.concat(...r?.elements),
                        },
                      }));
                      resolve();
                    })
                    .catch(async () => {
                      //refetch if error
                      await getCablesData();
                      resolve();
                    });
                }, delay))
            );
          };
          await getCablesData();
        }
        for (const url of substationsUrls) {
          const changedUrl = url.replace(
            "overpass-api.de",
            "overpass.kumi.systems"
          );

          const getSubstationsData = () =>
            new Promise<void>(
              (resolve) =>
                (timeoutID.current = setTimeout(() => {
                  fetch(changedUrl)
                    .then((r) => {
                      if (r.ok) {
                        return r.json();
                      } else {
                        throw Error(r.status.toString());
                      }
                    })
                    .then((r) => {
                      setMapData((prev) => ({
                        ...prev,
                        substations: {
                          ...prev.substations,
                          success: prev.substations.success + 1,
                          data: prev.substations.data?.concat(...r.elements),
                        },
                      }));
                      resolve();
                    })
                    .catch(async () => {
                      await getSubstationsData();
                      resolve();
                    });
                }, delay))
            );
          getSubstationsData();
          // or await getSubstationsData(); to do to it synchronously
        }
      }
    };

    fetchData();

    return () => {
      clearInterval(timeoutID.current);
    };
  }, [analysisData?.electricity, cablesUrls, substationsUrls]);

  const wkt = analysisData?.wkts[0];
  const bounds = wkt && parse(wkt);
  //reverse coordinates
  //const plotBoundsPositionsArray = bounds?.coordinates[0]?.map((i: any) => [i[1], i[0]]);
  const plotBoundsPositionsArray = switchLatLng(bounds?.coordinates[0]);

  const plots = analysisData?.wkts.map((wkt: any) =>
    switchLatLng(parse(wkt)?.coordinates[0])
  );

  const [mapData, setMapData] = useState({
    substations: { urls: substationsUrls?.length, success: 0, data: [] },
    cables: { urls: cablesUrls?.length, success: 0, data: [] },
  });

  const substationsNodes = mapData?.substations?.data?.filter(
    (item: any) => item.type === "node"
  );
  const substationsWays = mapData?.substations?.data?.filter(
    (item: any) => item.type === "way"
  );

  const cablesNodes = mapData?.cables?.data?.filter(
    (item: any) => item.type === "node"
  );
  const cablesWays = mapData?.cables?.data?.filter(
    (item: any) => item.type === "way"
  );

  const lowVoltageLinesWays = cablesWays?.filter(
    (item: any) => !item.tags.voltage || parseInt(item.tags.voltage) <= 110000
  );
  const highVoltageLinesWays = cablesWays?.filter(
    (item: any) => parseInt(item.tags.voltage) > 110000
  );

  const substationsObj = analysisData?.electricity?.find(
    (data: any) => data.type === "substation"
  );
  const nearestSubstation = {
    coordsFrom: [substationsObj?.from_y, substationsObj?.from_x],
    coordsTo: [substationsObj?.to_y, substationsObj?.to_x],
    distance: substationsObj?.distance,
    name: substationsObj?.name,
    voltage: substationsObj?.voltage,
  };

  const cableObj = analysisData?.electricity?.find(
    (data: any) => data.type === "cable"
  );
  const nearestCable = cableObj && {
    coordsFrom: [cableObj?.from_y, cableObj.from_x],
    coordsTo: [cableObj?.to_y, cableObj.to_x],
    distance: cableObj?.distance,
    name: cableObj?.name,
    voltage: cableObj?.voltage,
  };

  const getNodePos = (nodesArr: any, node: any) => {
    const selectedNode = nodesArr?.find((item: any) => item.id === node);
    const { lat, lon } = selectedNode;
    return [lat, lon];
  };

  const createWayCoordinatesArray = (way: any, nodesArr: any) => {
    const ids = way?.nodes;
    return ids?.map((id: any) => getNodePos(nodesArr, id));
  };

  const handleSetClosesPole = (e: any, poleNode: any) => {
    const coord = e?.latlng;
    const closesPoint = GeometryUtil.closest(map, poleNode, coord, true);
    const lineToClosestPoint = GeometryUtil.closest(
      map,
      plotBoundsPositionsArray,
      closesPoint as LatLngExpression,
      true
    );
    setClosesPole(closesPoint);
    setLineToClosesPole(lineToClosestPoint);
    map.fitBounds(
      [closesPoint as any, [lineToClosestPoint?.lat, lineToClosestPoint?.lng]],
      {
        padding: [80, 80],
      }
    );
  };

  const handleFindClosest = (e: any, markerPos: any) => {
    if (!map) return;

    const closesPoint = GeometryUtil.closest(
      map,
      plotBoundsPositionsArray,
      markerPos,
      true
    ) as any;

    setSelectedPoint(markerPos);
    setClosesPlotPoint(closesPoint);
    map.fitBounds([markerPos, [closesPoint?.lat, closesPoint?.lng]], {
      padding: [80, 80],
    });
  };

  return (
    <>
      {plots.map((plot: any) => (
        <Polygon
          positions={plot}
          pathOptions={{ color: "#850b03", weight: 1 }}
          ref={polygonRef}
          key={`${plot[0]}${plot[1]}`}
        >
          <Tooltip sticky>
            <p>{t("report.electricityMap.analyzedArea")}</p>
          </Tooltip>
        </Polygon>
      ))}

      {mapOptions.nearestSubstationVisibility && (
        <CustomPolyline
          key="nearestSubstation"
          positions={[
            nearestSubstation?.coordsFrom,
            nearestSubstation?.coordsTo,
          ]}
          color="black"
        >
          <Tooltip sticky>
            <p>{t("report.electricityParams.closestGPZ")}</p>
            {nearestSubstation.name && <p>{nearestSubstation.name}</p>}
            <p>
              <Trans
                i18nKey="report.electricityMap.nearestSubstationDistance"
                values={{
                  distance: (
                    Math.round(nearestSubstation.distance) / 1000
                  ).toFixed(2),
                }}
              />
            </p>
          </Tooltip>
        </CustomPolyline>
      )}

      {closesPole && <Marker position={closesPole} icon={gpzIcon} />}

      {mapOptions.substationsVisibility && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
          {substationsWays.map((way: any, index) => {
            const polygon = createWayCoordinatesArray(way, substationsNodes);
            const pos = L.latLngBounds(polygon).getCenter();
            return (
              <Marker
                icon={gpzIcon}
                key={`substationsWays${way.id}${index}`}
                position={pos}
                eventHandlers={{
                  click: (e) => {
                    handleFindClosest(e, pos);
                  },
                }}
              >
                <Tooltip>
                  <div>
                    <p>
                      {way.tags?.name
                        ? way.tags?.name
                        : t("report.electricityMap.substation")}
                    </p>
                    <p>{t("report.electricityMap.clickToMeasure")}</p>
                  </div>
                </Tooltip>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      )}

      {mapOptions.nearestCableVisibility && nearestCable && (
        <CustomPolyline
          key="nearestCable"
          positions={[nearestCable?.coordsFrom, nearestCable?.coordsTo]}
          color="black"
        >
          <Tooltip sticky>
            {nearestCable?.name && <p>{nearestCable?.name}</p>}
            <p>
              <Trans
                i18nKey="report.electricityMap.nearestLineDistance"
                values={{
                  distance: (Math.round(nearestCable?.distance) / 1000).toFixed(
                    2
                  ),
                }}
              />
            </p>
          </Tooltip>
        </CustomPolyline>
      )}

      {mapOptions.substationsVisibility &&
        nearestSubstation &&
        substationsWays.map((way: any, index) => (
          <Polygon
            key={`substation${way.id}${index}`}
            pathOptions={{
              color: "#18b6ff",
            }}
            positions={createWayCoordinatesArray(way, substationsNodes)}
          ></Polygon>
        ))}

      {mapOptions.highVoltageLinesWaysVisibility &&
        highVoltageLinesWays.map((way: any) => (
          <CustomPolyline
            key={`highVolt${way.id}`}
            positions={createWayCoordinatesArray(way, cablesNodes)}
            color="blue"
            closestPole={(e: any) =>
              handleSetClosesPole(
                e,
                createWayCoordinatesArray(way, cablesNodes)
              )
            }
          >
            <Tooltip sticky>
              <p>
                {way.tags.name
                  ? way.tags.name
                  : t("report.electricityMap.electricityLine")}
              </p>
            </Tooltip>
          </CustomPolyline>
        ))}

      {mapOptions.lowVoltageLinesWaysVisibility &&
        lowVoltageLinesWays.map((way: any) => (
          <CustomPolyline
            key={`lowVoltWay${way.id}`}
            positions={createWayCoordinatesArray(way, cablesNodes)}
            color="#187fff"
            closestPole={(e: any) =>
              handleSetClosesPole(
                e,
                createWayCoordinatesArray(way, cablesNodes)
              )
            }
          >
            <Tooltip sticky>
              <p>
                {way.tags.name
                  ? way.tags.name
                  : t("report.electricityMap.electricityLine")}
              </p>
            </Tooltip>
          </CustomPolyline>
        ))}

      {closesPlotPoint && (
        <CustomPolyline
          key={`closestPointToSelectedGpz`}
          positions={[
            [closesPlotPoint?.lat, closesPlotPoint?.lng],
            selectedPoint,
          ]}
          color="#058d05"
          clearPlotePoint={setClosesPlotPoint}
        >
          <Tooltip
            key={closesPlotPoint.distance}
            permanent
            direction="bottom"
            opacity={0.9}
            className="leaflet-tooltip-selectedGpzDistance"
          >
            <Trans
              i18nKey="report.electricityMap.nearestGPZDistance"
              values={{
                distance: getDistance(
                  [closesPlotPoint?.lat, closesPlotPoint?.lng],
                  [selectedPoint?.lat, selectedPoint?.lng]
                ),
              }}
            />
            <p>{t("report.electricityMap.clickTwiceToClose")}</p>
          </Tooltip>
        </CustomPolyline>
      )}

      {lineToClosesPole && (
        <CustomPolyline
          key={`closestPointToSelectedPole`}
          positions={[
            [lineToClosesPole?.lat, lineToClosesPole?.lng],
            closesPole,
          ]}
          color="#b31e1e"
          clearLine={setLineToClosesPole}
          clearPole={setClosesPole}
        >
          <Tooltip
            key={lineToClosesPole.distance}
            permanent
            direction="bottom"
            opacity={0.9}
            className="leaflet-tooltip-selectedPoleDistance"
          >
            <Trans
              i18nKey="report.electricityMap.markedPoleDistance"
              values={{
                distance: getDistance(
                  [lineToClosesPole?.lat, lineToClosesPole?.lng],
                  [closesPole?.lat, closesPole?.lng]
                ),
              }}
            />
            <p>{t("report.electricityMap.clickTwiceToClose")}</p>
          </Tooltip>
        </CustomPolyline>
      )}
    </>
  );
};

export default CustomElements;
