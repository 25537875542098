import { useState } from "react";
import "./InvoiceData.scss";
import NaturalPersonForm from "./NaturalPersonForm";
import CompanyForm from "./CompanyForm";
import { useTranslation } from "react-i18next";
import SwitchBtn from "../../../../common/components/SwitchBtn/SwitchBtn";

const InvoiceData = () => {
  const [activeTab, setActiveTab] = useState(false);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTab(e.target.checked);
  };

  return (
    <div className="invoice-data-wrapper">
      <div className="invoice-data">
        <SwitchBtn
          handleChange={handleChange}
          leftLabel={t("userData.company")}
          rightLabel={t("userData.naturalPerson")}
        />
        {activeTab ? <NaturalPersonForm /> : <CompanyForm />}
      </div>
    </div>
  );
};

export default InvoiceData;
