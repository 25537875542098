import { useTranslation } from "react-i18next";
import DownloadIcon from "../../../../assets/icons/profile/downloadInv.svg";
import Tooltip from "@mui/material/Tooltip";

interface Payment {
  payment: PaymentItemInterface;
}

export interface PaymentItemInterface {
  date: string;
  transaction_name: string;
  payment_amount: any;
  invoice_url: string;
}

const PaymentItem = ({ payment }: Payment) => {
  const { date, invoice_url, payment_amount, transaction_name } = payment;
  const { i18n, t } = useTranslation();

  const getName = (name: string) => {
    const productName = name.substring(0, 4);
    if (productName === "plot") {
      return `Raport Sunmetric ${name.split("'")[1]}`;
    } else if (productName === "land") {
      return `KW Sunmetric ${name.split("'")[1]}`;
    } else return name;
  };

  return (
    <li className="payment-item" key={date}>
      <div className="payment-wrapper">
        <span className="payment-title">
          {transaction_name && getName(transaction_name)}
        </span>
        <span className="payment-date">
          {new Intl.DateTimeFormat(i18n.language, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(date && date))}
        </span>
        <span className="payment-price center">
          {payment_amount?.amount ? `${payment_amount?.amount / 100}zł` : "-"}
        </span>

        {invoice_url ? (
          <a
            href={invoice_url}
            target="_blank"
            className="payment-invoice center"
          >
            <Tooltip
              title={t("paymentsHistory.download")}
              placement="right"
              arrow
            >
              <img src={DownloadIcon} alt="DownloadIcon" />
            </Tooltip>
          </a>
        ) : (
          <img
            src={DownloadIcon}
            alt="DownloadIcon"
            className="invoice-disabled"
          />
        )}
      </div>
    </li>
  );
};

export default PaymentItem;
