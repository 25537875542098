import { Pane, Rectangle, Tooltip, useMapEvents } from "react-leaflet";
import { useTranslation } from "react-i18next";
import { ZoomOnClickProps } from "../interfaces";
import { useAppDispatch } from "../../../redux/hooks";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";

const ZoomOnClick = ({ zoom, minZoomToDisplayPlots }: ZoomOnClickProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const lngBounds = { min: 14, max: 24.34 };
  const latBounds = { min: 48.9, max: 54.9 };

  const lngInPlBounds = (lng: number) =>
    lng > lngBounds.min && lngBounds.max > lng;
  const latInPlBounds = (lat: number) =>
    lat > latBounds.min && latBounds.max > lat;

  const inPlBounds = ({ lat, lng }: { lat: number; lng: number }) =>
    lngInPlBounds(lng) && latInPlBounds(lat);

  const map = useMapEvents({
    click(e) {
      if (map.getZoom() <= minZoomToDisplayPlots) {
        if (inPlBounds(e.latlng)) {
          map.setView(e.latlng, minZoomToDisplayPlots + 1);
        } else {
          dispatch(
            addNewNotification({
              text: t("plotAnalysisPage.notPlBounds"),
              variant: NotificationVariant.ERROR,
            })
          );
        }
      }
    },
  });

  return zoom <= minZoomToDisplayPlots ? (
    <Pane name="rectanglePane">
      <Rectangle
        bounds={[
          [latBounds.max, lngBounds.max],
          [latBounds.min, lngBounds.min],
        ]}
        fillColor="transparent"
        stroke={false}
      >
        <Pane name="ttPane">
          <Tooltip sticky>{t("plotAnalysisPage.clickToZoomIn")}</Tooltip>
        </Pane>
      </Rectangle>
    </Pane>
  ) : null;
};

export default ZoomOnClick;
