import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setAsPaid,
  updateStatus,
} from "../lib/Report/ReportsList/ReportsList.reducers";
import { API_URL } from "../config/config";

interface WindowSizeState {
  width: number;
  height: number;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSizeState>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

export const useWebsockets = () => {
  const ws = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((state) => state.auth);

  useEffect(() => {
    try {
      ws.current = new WebSocket(
        `${API_URL.replace("https", "wss")}plot-analysis/ws/${accessToken}`
      );

      const wsCurrent = ws.current;

      return () => {
        wsCurrent.close();
      };
    } catch (e) {
      console.log(e);
    }
  }, [accessToken]);

  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e: { data: string }) => {
      const message = JSON.parse(e.data);
      const { type, payload } = message; // {id: 43, status: 'in_progress'}
			
      if (type === "task.status.update") {
        dispatch(updateStatus(payload));
      }

      if (type === "service.access.save") {
        dispatch(setAsPaid({ id: parseInt(message.entity_id, 10) }));
      }
    };
  }, [dispatch]);
};
