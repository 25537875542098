import ChooseBusinessEntity from "../steps/ChooseBusinessEntity";
import Login from "../steps/Login";
import Payment from "../steps/Payment";
import BillingData from "../steps/BillingData";
import { PaymentFlow, StepsConfigurationProps } from "../interfaces";

export const stepsConfiguration = ({
  sharedStepProps,
  paymentStepProps,
  setInvoiceFor,
  invoiceFor,
}: StepsConfigurationProps) => {
  return {
    [PaymentFlow.reportUser]: [
      {
        component: (
          <ChooseBusinessEntity
            {...sharedStepProps}
            setInvoiceFor={setInvoiceFor}
            classname="business-entity"
          />
        ),
      },
      {
        component: (
          <BillingData
            {...sharedStepProps}
            invoiceFor={invoiceFor}
            classname="billing-data"
          />
        ),
      },
      {
        component: (
          <Payment
            {...sharedStepProps}
            {...paymentStepProps}
            classname="payment-step"
          />
        ),
      },
    ],
    [PaymentFlow.kw]: [
      {
        component: (
          <ChooseBusinessEntity
            {...sharedStepProps}
            setInvoiceFor={setInvoiceFor}
            classname="business-entity"
          />
        ),
      },
      {
        component: (
          <BillingData
            {...sharedStepProps}
            invoiceFor={invoiceFor}
            classname="billing-data"
          />
        ),
      },
      {
        component: (
          <Payment
            {...sharedStepProps}
            {...paymentStepProps}
            classname="payment-step"
          />
        ),
      },
    ],
    [PaymentFlow.reportGuest]: [
      { component: <Login {...sharedStepProps} classname="hide-steps" /> },
      {
        component: (
          <ChooseBusinessEntity
            {...sharedStepProps}
            setInvoiceFor={setInvoiceFor}
            classname="business-entity"
          />
        ),
      },
      {
        component: (
          <BillingData
            {...sharedStepProps}
            invoiceFor={invoiceFor}
            classname="billing-data billing-data-guest"
          />
        ),
      },
      {
        component: (
          <Payment
            {...sharedStepProps}
            {...paymentStepProps}
            classname="payment-step"
          />
        ),
      },
    ],
  };
};
