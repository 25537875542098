import { http } from "../../config/http";
import { API_URL } from "../../config/config";

export const submitPlot = async (plotId: string) => {
  try {
    await http.post(`${API_URL}plot-analysis/tasks`, {
      plot_ids: [plotId],
      name: "",
    });
  } catch (e: any) {
    console.log("error", e.response.data.detail);
  }
};

//todo request to the endpoint that allows array of plots ids
export const submitPlots = async (plotsIds: (string | undefined)[]) => {
  await http.post(`${API_URL}plot-analysis/tasks`, {
    plot_external_ids: plotsIds,
    name: "",
  });
};