import { http } from "../../config/http";
import { API_URL } from "../../config/config";

export const fetchReportsList = async (query: any) => {
  const response = await http.get(`${API_URL}plot-analysis/tasks?${query}`);
  return response.data;
};

export const fetchReport = async (id: any) => {
  const response = await http.get(`${API_URL}plot-analysis/multiplots/${id}`);
  return response.data;
};

export const regenerateReport = async (id: number) => {
  await http.post(`${API_URL}plot-analysis/tasks/${id}/regenerate`, {});
};

export const fetchMissingReportData = async (id: number) => {
  try {
    await http.post(`${API_URL}plot-analysis/plots/${id}/get_missing_data`, {});
  } catch (e: any) {
    console.log("error", e.response.data.detail);
  }
};

export const fetchReportToken = async (id: number, duration: number) => {
  const response = await http.post(
    `${API_URL}plot-analysis/tasks/${id}/share`,
    {
      // get duration in miliseconds
      duration: duration * 86400,
    }
  );
  return response.data;
};

export const fetchSharedReport = async (token: string) => {
  const response = await http.get(`${API_URL}plot-analysis/shared/${token}`);
  return response.data;
};

export const deactivateSharedLink = async (token: string) => {
  const response = await http.get(
    `${API_URL}plot-analysis/shared/${token}/deactivate`
  );
  return response.data;
};

export const prolongSharedLink = async (token: string, duration: number) => {
  const response = await http.post(
    `${API_URL}plot-analysis/shared/${token}/prolong`,
    {
      duration: duration * 86400,
    }
  );
  return response.data;
};

export const fetchClientSecret = async (
  productId: string,
  customerType: string,
	paymentIntent: string | null,
	customerDetails: any
) => {
	 const userAttributes : { [key: string]: any } =  {
     street: customerDetails?.street,
     house_nr: customerDetails?.houseNr,
     apartment_nr: customerDetails?.apartmentNr,
     post_code: customerDetails?.postCode,
     city: customerDetails?.city,
   };
   if (customerType === "np") {
		 userAttributes.first_name = customerDetails?.firstName,
		 userAttributes.last_name = customerDetails?.lastName
   } else {
		 userAttributes.company_name = customerDetails?.companyName,
		 userAttributes.vat_id = customerDetails?.nip
   }
	 
  const response = await http.post(`${API_URL}payments/transactions_intent`, {
    product_id: productId,
    payment_intent_token: paymentIntent,
    customer_type: customerType,
    customer_details: {
      email: customerDetails.email,
      attributes: userAttributes,
      marketing_agreement: true,
    },
  });
  return response.data;
};

export const getAvailableReportsCount = async () => {
  const response = await http.get(`${API_URL}plot-analysis/tasks/available`);
  return response.data;
};

export const submitPlots = async (plotsIds: (string | undefined)[]) => {
  await http.post(`${API_URL}plot-analysis/tasks`, {
    plot_external_ids: plotsIds,
    name: "",
  });
};

export const generatePDF = async (id: string | undefined) => {
  const response = await http.get(`${API_URL}plot-analysis/multiplots/${id}/pdf`);
  return response.data;
};