import "./EmailConfirmed.scss";
import WhiteButton from "../../../common/components/WhiteButton/WhiteButton";
import LogoShort from "../../../assets/images/logoShort.png";
import NotLoggedInContainer from "../../../common/components/NotLoggedInContainer/NotLoggedInContainer";
import YellowButton from "../../../common/components/YellowButton/YellowButton";
import YellowFrame from "../../../common/components/YellowFrame/YellowFrame";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const EmailConfirmed = () => {
  const { t } = useTranslation();

  return (
    <NotLoggedInContainer
      text={t("signupFlow.emailConfirmed.heading")}
      variant="email-confirmed-wrapper"
      title={t("common.registration")}
    >
      <div className="cards-wrapper">
        <YellowFrame>
          <p className="email">
            <Trans
              i18nKey="signupFlow.emailConfirmed.info"
              components={[<span />]}
            />
          </p>
          <Link to="/login">
            <YellowButton text={t("common.signin")} />
          </Link>
        </YellowFrame>
        <YellowFrame reverse className="help">
          <p className="title">{t("signupFlow.common.cta")}</p>
          <img height="100px" src={LogoShort} alt="logo" />
          <a href="https://www.sunmetric.pl/upselling" target="_blank">
            <WhiteButton text={t("signupFlow.common.ctaBtn")} />
          </a>
        </YellowFrame>
      </div>
    </NotLoggedInContainer>
  );
};

export default EmailConfirmed;
