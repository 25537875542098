import { Trans, useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./AgreementsBoxes.scss";
import CheckIcon from "@mui/icons-material/Check";

type Props = {
  values: {
    [key: string]: boolean | undefined;
  };
  errors: {
    [key: string]: string;
  };
  touched: {
    [key: string]: boolean;
  };
  handleChange: () => void;
};

const AgreementsBoxes = ({ values, errors, touched, handleChange }: Props) => {
  const { t } = useTranslation();

  return (
    <FormGroup className="agreements-boxes">
      <FormControlLabel
        className={!!errors.tosAgreement && touched.tosAgreement ? "error" : ""}
        control={
          <Checkbox checked={values.tosAgreement} checkedIcon={<CheckIcon />} />
        }
        label={
          <>
            <Trans
              i18nKey="signupFlow.registerPage.tosAgreement"
              components={[
                <a href="https://www.sunmetric.pl/rules" target="_blank" />,
                <a href="https://www.sunmetric.pl/privacy" target="_blank" />,
              ]}
            />
            {!!errors.tosAgreement && touched.tosAgreement && (
              <p className="errorMsg">{t("validation.fieldRequired")}</p>
            )}
          </>
        }
        onChange={handleChange}
        name="tosAgreement"
      />

      <FormControlLabel
        className={
          !!errors.marketingAgreement && touched.marketingAgreement
            ? "error"
            : ""
        }
        control={
          <Checkbox
            checked={values.marketingAgreement}
            checkedIcon={<CheckIcon />}
          />
        }
        label={
          <>
            {t("signupFlow.registerPage.marketingAgreement")}
            {!!errors.marketingAgreement && touched.marketingAgreement && (
              <span className="errorMsg">{t("validation.fieldRequired")}</span>
            )}
          </>
        }
        onChange={handleChange}
        name="marketingAgreement"
      />
    </FormGroup>
  );
};

export default AgreementsBoxes;
