import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./AreaMap.scss";
import Section from "../SectionComponents/Section/Section";
import AreaMapIcon from "../../../../../assets/icons/sidebar/areaMap.svg";
import { useAppSelector } from "../../../../../redux/hooks";
import { Projection } from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";
import { getCenter } from "ol/extent";
import { defaults } from "ol/interaction";
import { defaults as controlDefaults } from "ol/control";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import VectorLayer from "ol/layer/Vector";
import { Fill, Stroke, Style } from "ol/style";
import VectorSource from "ol/source/Vector";
import WKT from "ol/format/WKT";
import { getImpedimentsData } from "./utils";
import { useTranslation } from "react-i18next";
import CategoriesSelect from "./components/Select/CategoriesSelect";
import SubcategoriesSelect from "./components/Select/SubcategoriesSelect";
import ErrorIcon from "../../../../../assets/icons/report/error.svg";

export type LayerOptionType = { key: string; name: string };
const groundsColors = [
  "139,69,19",
  "160,82,45",
  "210,105,30",
  "205,133,63",
  "244,164,96",
  "222,184,135",
  "188,143,143",
  "255,228,181",
  "255,218,185",
  "255,228,225",
  "255,245,238",
  "112,128,144",
  "176,196,222",
];

function AreaMap() {
  const impedimentsData = getImpedimentsData();
  const impedimentTypes = impedimentsData.map((impediment) => impediment.type);
  let groundLayerIndex = 0;

  const mapRef = useRef<HTMLDivElement>(null);
  const { orthophoto, wkts, layers } = useAppSelector(
    (state: any) => state.report.report
  );
  const [allOptionsList, setAllOptionsList] = useState<{
    grounds: LayerOptionType[];
    impediments: LayerOptionType[];
  }>({ grounds: [], impediments: [] });

  const [renderedLayers, setRenderedLayers] = useState<null | any[]>(null);
  const [activeTab, setActiveTab] = useState<string>("area");
  const [subcategories, setSubcategories] = useState<string[]>(impedimentTypes);

  const { t } = useTranslation();

  useEffect(() => {
    if (activeTab === "area") {
      setSubcategories([""]);
    }
  }, [activeTab]);

  const filterEmptyLayers = useCallback(() => {
    const filteredLayers = [] as any[];
    layers
      ?.filter(
        (layer: any) => layer.percent > 0 && layer.type !== "valid_grounds"
      )
      .map((layer: any) => {
        const alreadyExists = filteredLayers.find(
          (item) =>
            item.type === layer.type && item.description === layer.description
        );
        if (!alreadyExists) {
          filteredLayers.push(layer);
        }
        if (alreadyExists && alreadyExists.version < layer.version) {
          filteredLayers[filteredLayers.indexOf(alreadyExists)] = layer;
        }
      });
    return filteredLayers;
  }, [layers]);

  const filterLayersBySelectedCategory = useCallback(
    (layers: any[]) => {
      return layers.filter((layer) => {
        const groundLabel =
          layer.values_.type === "grounds" && layer.values_.label?.slice(13);
        const groundTypeSelected = subcategories.find((sub) => {
          return (
            sub &&
            groundLabel &&
            groundLabel.toLowerCase() === sub.toLowerCase()
          );
        });

        return (
          [...subcategories, "orthophoto", "wkt"].includes(
            layer.values_.type
          ) || groundTypeSelected
        );
      });
    },
    [subcategories]
  );

  const map = useMemo(() => {
    const bounds = orthophoto?.orthophoto_img_bounds;
    const projection = new Projection({
      code: "EPSG:4326",
      units: "degrees",
      extent: bounds,
    });

    const map = new Map({
      interactions: defaults({
        mouseWheelZoom: false,
        dragPan: false,
        doubleClickZoom: false,
      }),
      controls: controlDefaults({
        attribution: false,
        zoom: false,
        rotate: false,
      }),
      view: new View({
        projection: projection,
        center: bounds && getCenter(bounds),
        zoom: 1.3,
        minZoom: 1.3,
      }),
      target: "map",
    });

    if (mapRef.current) {
      mapRef.current.innerHTML = "";
    }
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.innerHTML = "";
        map.setTarget(mapRef.current);
      }
    }, 500);

    return map;
  }, [orthophoto?.orthophoto_img_bounds]);

  useEffect(() => {
    const availableLayers = filterEmptyLayers();

    const groundsOptions = availableLayers
      .filter((layer: any) => layer.type === "grounds" && layer.percent)
      .map((layer: any) => ({
        key: layer.description,
        name: layer.description,
      }));

    const impedimentsOptions = getImpedimentsData()
      .filter((impediment) =>
        availableLayers.find((layer: any) => layer.type === impediment.type)
      )
      .map((data) => ({ key: data.type, name: data.text }));

    setAllOptionsList({
      grounds: groundsOptions,
      impediments: impedimentsOptions,
    });
  }, [filterEmptyLayers]);

  const renderMapLayers = useCallback(
    (layers: any[]) => {
      map.setLayers(layers);
    },
    [map]
  );

  useEffect(() => {
    if (!renderedLayers) return;

    const filteredLayers = filterLayersBySelectedCategory(
      renderedLayers
    ) as any;
    renderMapLayers(filteredLayers);
  }, [
    activeTab,
    filterLayersBySelectedCategory,
    renderMapLayers,
    renderedLayers,
    subcategories,
  ]);

  useEffect(() => {
    const renderLayer = (layer: any, index: any) => {
      const { type, description, version } = layer;
      const format = new WKT();
      const vectorSource = new VectorSource({
        format: format,
        loader: function () {
          const features = layer?.polygons?.flat().map((polygon: any) => {
            const newFeature = new WKT().readFeature(polygon.wkt, {
              dataProjection: "EPSG:4326",
            });
            newFeature.setProperties({ polygonID: polygon.id });
            return newFeature;
          });
          features.forEach((feature: any, featureIndex: number) => {
            feature.setProperties({
              visibility: true,
              selected: false,
              checked: true,
              layerType: type,
              layerDesc: description,
              layerVersion: version,
              layerIndex: index,
              featureIndex,
            });
          });
          vectorSource.addFeatures(features);
        },
        useSpatialIndex: false,
      });

      let color, label;
      switch (type) {
        case "grounds":
          color = groundsColors[groundLayerIndex];
          label = `Grunty klasy ${description}`;
          groundLayerIndex++;
          break;
        case "forests":
          color = "61, 122, 44";
          label = `Lasy`;
          break;
        case "floodplains":
          color = "31, 67, 185";
          label = `Tereny zalewowe`;
          break;
        case "water":
          color = "75, 96, 162";
          label = `Wody`;
          break;
        case "environment_protections":
          color = "21, 135, 145";
          label = description;
          break;
        case "buildings":
          color = "28,9,2";
          label = "Budynki na działce";
          break;
        case "all_buildings":
          color = "28,9,2";
          label = "Wszystkie budynki";
          break;
        case "ditches":
          color = "31, 67, 185";
          label = "Rowy melioracyjne";
          break;
        default:
          color = "24,80,9";
          label = "-";
      }

      const renderedLayer = new VectorLayer({
        style: new Style({
          stroke: new Stroke({
            color: `rgba(${color})`,
            width: 1.3,
          }),
          fill: new Fill({
            color: `rgba(${color}, 0.6)`,
          }),
        }),
        source: vectorSource,
      });
      renderedLayer.setProperties({ label, version, type, color });
      return renderedLayer;
    };

    const renderBaseLayers = () => {
      const bounds = orthophoto?.orthophoto_img_bounds;
      const image = orthophoto?.orthophoto_img_url;
      const projection = new Projection({
        code: "EPSG:4326",
        units: "degrees",
        extent: bounds,
      });

      const orthophotoLayer = new ImageLayer({
        source: new Static({
          url: image,
          projection: projection,
          imageExtent: bounds,
        }),
      });
      orthophotoLayer.setProperties({ type: "orthophoto" });

      const plotVectorSource = new VectorSource({
        format: new WKT(),
        loader: function () {
          const plots = wkts?.map((polygon: string) => {
            const newFeature = new WKT().readFeature(polygon, {
              dataProjection: "EPSG:4326",
            });
            return newFeature;
          });
          plotVectorSource.addFeatures(plots);
        },
        useSpatialIndex: false,
      });

      const plotLayer = new VectorLayer({
        style: new Style({
          fill: new Fill({
            color: "rgba(236,189,61,0.49)",
          }),
          stroke: new Stroke({
            color: "rgba(138, 101, 1)",
            width: 2,
          }),
        }),
        source: plotVectorSource,
      });
      plotLayer.setProperties({ type: "wkt" });

      return [orthophotoLayer, plotLayer];
    };

    const renderedLayers = renderBaseLayers();
    const filteredEmptyLayers = filterEmptyLayers();
    filteredEmptyLayers.map((layer: any, index: number) => {
      renderedLayers.push(renderLayer(layer, index + 2));
    });

    setRenderedLayers(renderedLayers);
  }, [
    filterEmptyLayers,
    groundLayerIndex,
    orthophoto?.orthophoto_img_bounds,
    orthophoto?.orthophoto_img_url,
    wkts,
  ]);

  const optionsList =
    activeTab === "grounds"
      ? allOptionsList.grounds
      : allOptionsList.impediments;

  return (
    <Section
      title={t("report.areaMap.title")}
      icon={AreaMapIcon}
      id="areaMap"
      borderless
    >
      {orthophoto ? (
        <div className="area-map-inner">
          <div className="category-selects-wrapper">
            <CategoriesSelect
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              allOptionsList={allOptionsList}
            />
            {["impediments", "grounds"].includes(activeTab) && (
              <SubcategoriesSelect
                subcategories={subcategories}
                setSubcategories={setSubcategories}
                optionsList={optionsList}
              />
            )}
          </div>
          <div className="area-map-wrapper">
            <div ref={mapRef} className="areaLayersMap" id="map" />
          </div>
        </div>
      ) : (
        <div className="data area-map-error">
          <img src={ErrorIcon} alt="" />
          <p>{t("report.areaMap.error")}</p>
        </div>
      )}
    </Section>
  );
}
export default AreaMap;
