import { useState } from "react";
import ModalContainer from "../ModalContainer/ModalContainer";
import "./PaymentModal.scss";
import { useAppDispatch } from "../../../../redux/hooks";
import { stepsConfiguration } from "./components/StepsConfiguration";
import { PaymentModalProps } from "./interfaces";
import StepsWrapper from "./components/StepsWrapper";
import { closePaymentModal } from "./Payment.reducers";

const PaymentModal = ({
  productId,
  flow,
  selectedPlotsIds,
}: PaymentModalProps) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [invoiceFor, setInvoiceFor] = useState<string>("");
  const dispatch = useAppDispatch();

  const onModalClose = () => {
    setActiveStep(1);
    setInvoiceFor("");
    dispatch(closePaymentModal());
  };

  const sharedStepProps = {
    setActiveStep,
    activeStep,
    flow,
    selectedPlotsIds,
  };

  const paymentStepProps = {
    productId,
    invoiceFor,
  };

  const steps = stepsConfiguration({
    sharedStepProps,
    paymentStepProps,
    setInvoiceFor,
    invoiceFor,
  });

  return (
    <ModalContainer variant="payment-modal" onClose={onModalClose}>
      <StepsWrapper
        classname={steps[flow][activeStep - 1].component.props.classname}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        stepsAmount={steps[flow].length}
      >
        {steps[flow][activeStep - 1].component}
      </StepsWrapper>
    </ModalContainer>
  );
};

export default PaymentModal;
