import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { Checkbox, FormControlLabel, LinearProgress } from "@mui/material";
import { useAppDispatch } from "../../../../../redux/hooks";
import { NotificationVariant } from "../../../Notification/Notification.types";
import { addNewNotification } from "../../../Notification/Notification.reducers";
import YellowButton from "../../../YellowButton/YellowButton";
import { useTranslation } from "react-i18next";
import { PaymentFormProps } from "../interfaces";

const PaymentForm = ({ amount }: PaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [agreementChecked, setAgreementChecked] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreementChecked(e.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    setIsSubmitting(false);

    if (error.type === "card_error" || error.type === "validation_error") {
      dispatch(
        addNewNotification({
          text: error.message as string,
          variant: NotificationVariant.ERROR,
        })
      );
    } else {
      dispatch(
        addNewNotification({
          text: t("common.error"),
          variant: NotificationVariant.ERROR,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && <LinearProgress />}
      <PaymentElement onReady={() => setIsLoading(false)} />
      <FormControlLabel
        control={
          <Checkbox onChange={handleChange} checked={agreementChecked} />
        }
        label={t("paymentModal.stepFourCheckbox")}
      />
      <YellowButton
        text={
          isSubmitting
            ? t("paymentModal.inProgress")
            : `${t("common.pay")} ${amount}zł`
        }
        disabled={isSubmitting || !agreementChecked}
      />
    </form>
  );
};

export default PaymentForm;
