export const COGNITO_REGION = window.REACT_APP_COGNITO_REGION || "";
export const COGNITO_USER_POOL_ID = window.REACT_APP_COGNITO_USER_POOL_ID || "";
export const COGNITO_USER_POOL_CLIENT_ID =
  window.REACT_APP_COGNITO_USER_POOL_CLIENT_ID || "";
export const API_URL = window.REACT_APP_API || "";
export const STRIPE_PUBLIC_KEY = window.REACT_APP_STRIPE_PUBLIC_KEY || "";
export const STRIPE_PLOT_PRODUCT_ID =
  window.REACT_APP_STRIPE_PLOT_PRODUCT_ID || "";
export const STRIPE_KW_PRODUCT_ID = window.REACT_APP_STRIPE_KW_PRODUCT_ID || "";
export const APP_URL = window.REACT_APP_URL || "";
