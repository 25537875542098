import { useState } from "react";
import "./AccountSettings.scss";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import SwitchBtn from "../../../../common/components/SwitchBtn/SwitchBtn";
import WhiteButton from "../../../../common/components/WhiteButton/WhiteButton";
import infoIcon from "../../../../assets/icons/info.svg";
import deleteAccountIcon from "../../../../assets/icons/profile/unfriend.svg";
import cancelSubscriptionIcon from "../../../../assets/icons/profile/subscribe.svg";
import deleteAccountButtonIcon from "../../../../assets/icons/profile/delete-account.svg";
import cancelSubscriptionButtonIcon from "../../../../assets/icons/profile/cancel-subscription.svg";
import { changeLang } from "../../../Auth/Auth.reducers";
import AccountSettingsModal from "../Modals/AccountSettingsModal";
import { addNewNotification } from "../../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../../common/components/Notification/Notification.types";
import { http } from "../../../../config/http";
import { API_URL } from "../../../../config/config";
import { useNavigate } from "react-router-dom";

type SectionHeadingType = {
  text: string;
  icon: string;
};

const SectionHeading = ({ text, icon }: SectionHeadingType) => (
  <h2 className="account-settings-heading">
    <img src={icon} />
    {text}
  </h2>
);

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appLang } = useAppSelector((state) => state.auth);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const { t } = useTranslation();

  const deleteUser = async () => {
    await http.delete(`${API_URL}plot-analysis/users`);
    dispatch(
      addNewNotification({
        text: t("accountSettings.deleteAccount.accountDeleted"),
        variant: NotificationVariant.INFO,
      })
    );
    navigate("/logout");
  };

  const cancelSubscription = async () => {
    await dispatch(
      addNewNotification({
        text: "cancelSubscription",
        variant: NotificationVariant.INFO,
      })
    );
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked ? "en" : "pl";
    dispatch(changeLang({ lang: value }));
    await i18next.changeLanguage(value);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:language": value,
      });
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  // UPDATE AFTER BE FOR SUBS CANCELATION IS FINISHED
  const tempSubsHidden = true;
  const tempSubButtonDisable = true;

  return (
    <div className="account-settings">
      {deleteAccountModal && (
        <AccountSettingsModal
          onClose={() => setDeleteAccountModal(false)}
          submitAction={deleteUser}
          title={t("accountSettings.deleteAccount.title")}
          lead={t("accountSettings.deleteAccount.lead")}
          button={{
            icon: deleteAccountButtonIcon,
            text: t("accountSettings.deleteAccount.buttonText"),
          }}
          className="delete-profile-modal"
        />
      )}
      {cancelSubscriptionModal && (
        <AccountSettingsModal
          onClose={() => setCancelSubscriptionModal(false)}
          submitAction={cancelSubscription}
          title={t("accountSettings.subscription.title")}
          lead={t("accountSettings.subscription.lead")}
          button={{
            icon: cancelSubscriptionButtonIcon,
            text: t("accountSettings.subscription.submitBtnText"),
          }}
          className="cancel-subscritpion-modal"
        />
      )}
      <section>
        <SectionHeading
          text={t("accountSettings.language.heading")}
          icon={infoIcon}
        />
        <article>
          <SwitchBtn
            handleChange={handleChange}
            leftLabel={t("accountSettings.language.polish")}
            rightLabel={t("accountSettings.language.english")}
            defaultValue={+(appLang === "en")}
          />
        </article>
      </section>
      {!tempSubsHidden && (
        <section>
          <SectionHeading
            text={t("accountSettings.subscription.heading")}
            icon={cancelSubscriptionIcon}
          />
          <article>
            <p className="account-settings-lead">
              {t("userData.remainingReports")}: 0
            </p>
            <WhiteButton
              text={t(
                `accountSettings.subscription.${
                  tempSubButtonDisable ? "disabledButtonText" : "buttonText"
                }`
              )}
              icon={cancelSubscriptionButtonIcon}
              onClick={() => setCancelSubscriptionModal(true)}
              disabled={tempSubButtonDisable}
            />
          </article>
        </section>
      )}
      <section className="account-section-delete-user">
        <SectionHeading
          text={t("accountSettings.deleteAccount.heading")}
          icon={deleteAccountIcon}
        />
        <article>
          <WhiteButton
            text={t("accountSettings.deleteAccount.buttonText")}
            icon={deleteAccountButtonIcon}
            onClick={() => setDeleteAccountModal(true)}
          />
        </article>
      </section>
    </div>
  );
};

export default AccountSettings;
