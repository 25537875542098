import "./YellowFrame.scss";
import classNames from "classnames";

interface YellowFrameProps {
  reverse?: boolean;
  hover?: boolean;
  className?: any;
  children: any;
}

const YellowFrame = ({
  reverse,
  className,
  children,
  hover,
}: YellowFrameProps) => {
  return (
    <div
      className={classNames(
        {
          "frame-reverse": reverse,
          "frame-animate-hover": hover,
          [className]: className,
        },
        "frame"
      )}
    >
      <div className="frame-inner">{children}</div>
    </div>
  );
};

export default YellowFrame;
