import "./ModalContainer.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import YellowFrame from "../../YellowFrame/YellowFrame";
import CloseIcon from "../../../../assets/icons/modalCloseBtn.svg";

interface ModalContainerProps {
  children: any;
  variant?: string;
  onClose?: any;
}

const ModalContainer = ({
  children,
  variant,
  onClose,
}: ModalContainerProps) => {
  return (
    <Modal
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box className={`custom-modal-wrapper ${variant ? variant : ""}`}>
        <YellowFrame>
          <img
            src={CloseIcon}
            alt="ikona"
            className="close-btn"
            onClick={() => onClose()}
          />
          {children}
        </YellowFrame>
      </Box>
    </Modal>
  );
};

export default ModalContainer;
