import { Formik } from "formik";
import * as Yup from "yup";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import "./InvoiceData.scss";
import YellowButton from "../../../../common/components/YellowButton/YellowButton";
import YellowFrame from "../../../../common/components/YellowFrame/YellowFrame";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Auth } from "@aws-amplify/auth";
import { initUser, saveGuestInvoiceData } from "../../../Auth/Auth.reducers";
import { addNewNotification } from "../../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../../common/components/Notification/Notification.types";
import { translate } from "../../../../common/helpers";
import { useTranslation } from "react-i18next";
import {
  BillingDataViews,
  PaymentFlow,
} from "../../../../common/components/Modals/PaymentModal/interfaces";

interface FormValuesProps {
  invoiceCompanyName: string;
  invoiceNip: string;
  invoiceEmail: string;
  invoiceStreet: string;
  invoiceHouseNr: string;
  invoiceApartmentNr: string;
  invoicePostCode: string;
  invoiceCity: string;
}

interface CompanyFormProps {
  flow?: PaymentFlow;
  setCurrentView?: React.Dispatch<React.SetStateAction<BillingDataViews>>;
}

const CompanyForm = ({ flow, setCurrentView }: CompanyFormProps) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const submitForm = async (form: FormValuesProps) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:inv:b:company_name": form.invoiceCompanyName,
        "custom:inv:b:nip": form.invoiceNip,
        "custom:inv:b:email": form.invoiceEmail,
        "custom:inv:b:street": form.invoiceStreet,
        "custom:inv:b:house_nr": form.invoiceHouseNr,
        "custom:inv:b:apartment_nr": form.invoiceApartmentNr,
        "custom:inv:b:post_code": form.invoicePostCode,
        "custom:inv:b:city": form.invoiceCity,
      });
      dispatch(initUser());
      dispatch(
        addNewNotification({
          text: t("common.changesSaved"),
          variant: NotificationVariant.SUCCESS,
        })
      );
      setCurrentView && setCurrentView(BillingDataViews.confirmData);
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          addNewNotification({
            text: translate(e.message),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  const submitFormAsGuest = async (form: FormValuesProps) => {
      dispatch(
        saveGuestInvoiceData({
          data: {
            companyName: form.invoiceCompanyName,
            nip: form.invoiceNip,
            email: form.invoiceEmail,
            street: form.invoiceStreet,
            houseNr: form.invoiceHouseNr,
            apartmentNr: form.invoiceApartmentNr,
            postCode: form.invoicePostCode,
            city: form.invoiceCity,
          },
          invoiceFor: "b",
        })
      );
      setCurrentView && setCurrentView(BillingDataViews.confirmData);
  };

  return (
    <Formik<FormValuesProps>
      initialValues={{
        invoiceCompanyName: profile?.invoice?.b?.companyName || "",
        invoiceNip: profile?.invoice?.b?.nip || "",
        invoiceEmail: profile?.invoice?.b?.email || "",
        invoiceStreet: profile?.invoice?.b?.street || "",
        invoiceHouseNr: profile?.invoice?.b?.houseNr || "",
        invoiceApartmentNr: profile?.invoice?.b?.apartmentNr || "",
        invoicePostCode: profile?.invoice?.b?.postCode || "",
        invoiceCity: profile?.invoice?.b?.city || "",
      }}
      validationSchema={Yup.object({
        invoiceCompanyName: Yup.string().required(
          t("validation.fieldRequired")
        ),
        invoiceNip: Yup.string()
          .required(t("validation.fieldRequired"))
          .matches(/^[0-9]{10}$/, t("validation.nipError")),
        invoiceEmail: Yup.string()
          .required(t("validation.fieldRequired"))
          .email(t("validation.email")),
        invoiceStreet: Yup.string().required(t("validation.fieldRequired")),
        invoiceHouseNr: Yup.string().required(t("validation.fieldRequired")),
        invoiceApartmentNr: Yup.string(),
        invoicePostCode: Yup.string().required(t("validation.fieldRequired")),
        invoiceCity: Yup.string().required(t("validation.fieldRequired")),
      })}
      onSubmit={async (values) => {
        flow === PaymentFlow.reportGuest
          ? await submitFormAsGuest(values)
          : await submitForm(values);
      }}
      component={(props) => <Form {...props} flow={flow} />}
    />
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  flow,
}) => {
  const { t } = useTranslation();

  return (
    <div className="form-wrapper">
      <YellowFrame>
        <form onSubmit={handleSubmit} className="form">
          <div className="fullwidth">
            <p className="label">{t("userData.companyName")}</p>
            <TextField
              fullWidth
              error={!!errors.invoiceCompanyName && touched.invoiceCompanyName}
              helperText={
                touched.invoiceCompanyName ? errors.invoiceCompanyName : null
              }
              hiddenLabel
              name="invoiceCompanyName"
              value={values.invoiceCompanyName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="grid-wrapper">
            <div>
              <p className="label">{t("userData.nip")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceNip && touched.invoiceNip}
                helperText={touched.invoiceNip ? errors.invoiceNip : null}
                hiddenLabel
                name="invoiceNip"
                value={values.invoiceNip}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <p className="label">{t("userData.invoiceEmail")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceEmail && touched.invoiceEmail}
                helperText={touched.invoiceEmail ? errors.invoiceEmail : null}
                hiddenLabel
                name="invoiceEmail"
                value={values.invoiceEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div>
              <p className="label">{t("userData.street")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceStreet && touched.invoiceStreet}
                helperText={touched.invoiceStreet ? errors.invoiceStreet : null}
                hiddenLabel
                name="invoiceStreet"
                value={values.invoiceStreet}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="input-wrapper house-nr">
              <div>
                <p className="label">{t("userData.houseNr")}</p>
                <TextField
                  fullWidth
                  error={!!errors.invoiceHouseNr && touched.invoiceHouseNr}
                  helperText={
                    touched.invoiceHouseNr ? errors.invoiceHouseNr : null
                  }
                  hiddenLabel
                  name="invoiceHouseNr"
                  value={values.invoiceHouseNr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div>
                <p className="label">{t("userData.apartmentNr")}</p>
                <TextField
                  fullWidth
                  error={
                    !!errors.invoiceApartmentNr && touched.invoiceApartmentNr
                  }
                  helperText={
                    touched.invoiceApartmentNr
                      ? errors.invoiceApartmentNr
                      : null
                  }
                  hiddenLabel
                  name="invoiceApartmentNr"
                  value={values.invoiceApartmentNr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div>
              <p className="label">{t("userData.postCode")}</p>
              <TextField
                fullWidth
                error={!!errors.invoicePostCode && touched.invoicePostCode}
                helperText={
                  touched.invoicePostCode ? errors.invoicePostCode : null
                }
                hiddenLabel
                name="invoicePostCode"
                value={values.invoicePostCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div>
              <p className="label">{t("userData.city")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceCity && touched.invoiceCity}
                helperText={touched.invoiceCity ? errors.invoiceCity : null}
                hiddenLabel
                name="invoiceCity"
                value={values.invoiceCity}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {flow === PaymentFlow.reportGuest && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.dataProcessingAgreement}
                  className={
                    !!errors.dataProcessingAgreement &&
                    touched.dataProcessingAgreement &&
                    "error"
                  }
                  required
                />
              }
              label={
                <>
                  {t("signupFlow.registerPage.marketingAgreement")}
                  {!!errors.dataProcessingAgreement &&
                    touched.dataProcessingAgreement && (
                      <span className="errorMsg">
                        {t("validation.fieldRequired")}
                      </span>
                    )}
                </>
              }
              onChange={handleChange}
              name="dataProcessingAgreement"
            />
          )}
          <YellowButton text={t("common.save")} />
        </form>
      </YellowFrame>
    </div>
  );
};

export default CompanyForm;
