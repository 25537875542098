import { Popover } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import React from "react";
import "./PlotExternalIds.scss";

const PlotExtrenalIds = ({ plotIds, variant }: { plotIds: string[], variant?: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <div className={`plot-ids-container ${variant}`}>
      <div className="button" onClick={handleClick}>
        <p>{plotIds[0]}</p>
        <IconButton>
          <ArrowDropDownIcon />
        </IconButton>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
        onClick={(event) => event.stopPropagation()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: variant === "sidebar-plot-ids" ? "center" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: variant === "sidebar-plot-ids" ? "center" : "left",
        }}
        PaperProps={{ className: `plot-ids-paper ${variant}` }}
        elevation={0}
      >
        <div className="list">
          {plotIds.slice(1).map((id: string) => (
            <div className="list-item" key={id}>
              <p>{id}</p>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default PlotExtrenalIds;
