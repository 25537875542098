import "./PaymentsHistory.scss";
import PaymentItem, { PaymentItemInterface } from "./PaymentItem";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getPaymentsList } from "../../Profile.reducers";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../../common/components/PageLoader/PageLoader";
import moneyIcon from "../../../../assets/icons/sidebar/money.svg";
import Pagination from "@mui/material/Pagination";

type SectionHeadingType = {
  text: string;
  icon: string;
};

const SectionHeading = ({ text, icon }: SectionHeadingType) => (
  <h2 className="payments-history-heading">
    <img src={icon} />
    {text}
  </h2>
);

const PaymentsHistory = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { paymentsHistory, error, loading } = useAppSelector(
    (state) => state.payments
  );
  const totalPagesCount = Math.ceil(paymentsHistory?.total / 10);
  const [page, setPage] = useState<number>(1);
  const [listHasLoaded, setListHasLoaded] = useState(false);

  useEffect(() => {
    const fetchReportsList = async () => {
      await dispatch(getPaymentsList({ page }));
    };
    setListHasLoaded(true);
    fetchReportsList();
  }, [dispatch, page]);

  const isLoading = loading && !listHasLoaded;

  return (
    <div className="payments-history-wrapper">
      <div className="payments-history">
        <SectionHeading text={t("paymentsHistory.subtitle")} icon={moneyIcon} />
        <PageLoader
          open={isLoading}
          preloaded={paymentsHistory?.items?.length}
          transparentBg
        />
        {error && <p className="error">{t("common.error")}</p>}
        {paymentsHistory?.items?.length === 0 && (
          <p className="error">{t("paymentsHistory.emptyState")}</p>
        )}
        {paymentsHistory?.items?.length > 0 && listHasLoaded && (
          <>
            <ul>
              {paymentsHistory?.items?.length > 0 &&
                paymentsHistory?.items?.map((payment: PaymentItemInterface) => (
                  <PaymentItem payment={payment} key={payment.date} />
                ))}
            </ul>
            <Pagination
              style={{
                opacity: paymentsHistory?.items?.length > 0 ? "1" : "0",
              }}
              count={totalPagesCount}
              showFirstButton
              showLastButton
              page={page}
              onChange={(_, value) => setPage(value)}
              shape="rounded"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentsHistory;
