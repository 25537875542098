import { useTranslation } from "react-i18next";
import SwitchBtn from "../SwitchBtn/SwitchBtn";

interface FilterByStatusProps {
  setStatusFilter: any;
  setPage: any;
}

const FilterByStatus = ({ setStatusFilter, setPage }: FilterByStatusProps) => {
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusFilter(e.target.checked ? "completed" : "all");
    setPage(1);
  };

  return (
    <div className="searchbox searchbox-filters">
      <p className="filter-title">{t("reportsList.display")}</p>
      <SwitchBtn
        handleChange={handleChange}
        leftLabel={t("reportsList.all")}
        rightLabel={t("reportsList.completed")}
      />
    </div>
  );
};

export default FilterByStatus;
