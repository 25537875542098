import { useCallback, useEffect, useState } from "react";
import "./Report.styles.scss";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import WhiteButton from "../../../common/components/WhiteButton/WhiteButton";
import ReportsListIcon from "../../../assets/icons/menuReportsList.svg";
import RefreshImage from "../../../assets/images/after-refresh 1.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface RefreshInterface {
  isShared?: boolean;
}

const ReportRefresh = ({ isShared }: RefreshInterface) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    report: { external_id },
  } = useAppSelector((state: RootState) => state.report);
  const [timeLeft, setTimeLeft] = useState(10);
  const [externalId, setExternalId] = useState(null);

  const redirect = useCallback(() => {
    navigate(isShared ? "/" : "/reports");
  }, [isShared, navigate]);

  useEffect(() => {
    setExternalId(external_id);

    return () => {
      setExternalId(null);
    };
  }, [external_id]);

  useEffect(() => {
    if (!timeLeft) return redirect();

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [redirect, timeLeft]);

  return (
    <div className="report-refreshing">
      <h1>{t("reportRefresh.heading")}</h1>
      <article className="report-refreshing-content">
        <section>
          <h2>
            {t("reportRefresh.info", { externalId })}{" "}
            {isShared
              ? t("reportRefresh.tryAgain")
              : t("reportRefresh.goToReports")}
          </h2>
          <small>
            {t("reportRefresh.redirectionIn")}{" "}
            {t("helpers.time.seconds.secondsWithCount", { count: timeLeft })}
          </small>
        </section>
        <img className="report-refreshing-img" src={RefreshImage} alt="" />
      </article>
      <WhiteButton
        text={
          isShared ? t("reportRefresh.backToHomepage") : t("common.reportsList")
        }
        icon={!isShared && ReportsListIcon}
        onClick={redirect}
      />
    </div>
  );
};

export default ReportRefresh;
