import ModalContainer from "../ModalContainer/ModalContainer";
import WhiteButton from "../../WhiteButton/WhiteButton";
import "./RefreshModal.scss";
import CloseIcon from "../../../../assets/icons/closeBtn.svg";
import { addNewNotification } from "../../Notification/Notification.reducers";
import { NotificationVariant } from "../../Notification/Notification.types";
import { http } from "../../../../config/http";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../../config/config";
import { useTranslation } from "react-i18next";

const RefreshModal = ({ onClose }: any) => {
  const {
    report: { task },
  }: any = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const refreshReport = async () => {
    try {
      await http.post(
        `${API_URL}plot-analysis/tasks/${task.id}/regenerate`,
        {}
      );
      dispatch(
        addNewNotification({
          text: t("reportRefresh.successNotification", { name: task.name }),
          variant: NotificationVariant.INFO,
        })
      );
      navigate("/report-refresh");
    } catch (e: any) {
      dispatch(
        addNewNotification({
          text: t("reportRefresh.failNotification"),
          variant: NotificationVariant.ERROR,
        })
      );
    }
  };
  //todo add payment flow if user doesn't have a free refresh anymore

  return (
    <ModalContainer variant="refresh-modal" onClose={() => onClose()}>
      <p className="title">{t("reportSidebar.refreshReportBtn")}</p>
      <p className="text">{t("reportModals.refreshText")}</p>
      <div className="buttons-wrapper">
        <WhiteButton
          text={t("reportModals.refresh")}
          animatedIcon="refresh"
          onClick={() => refreshReport()}
        />
        <WhiteButton
          text={t("common.cancel")}
          icon={CloseIcon}
          onClick={() => onClose()}
        />
      </div>
    </ModalContainer>
  );
};

export default RefreshModal;
