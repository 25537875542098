import { useEffect, useState } from "react";
import "./ForgotPassword.scss";
import NotLoggedInContainer from "../../../common/components/NotLoggedInContainer/NotLoggedInContainer";
import YellowFrame from "../../../common/components/YellowFrame/YellowFrame";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import YellowButton from "../../../common/components/YellowButton/YellowButton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { translate } from "../../../common/helpers";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { useAppDispatch } from "../../../redux/hooks";
import { Trans, useTranslation } from "react-i18next";

interface FormValuesProps {
  code: string;
  password: string;
}

const NewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    if (!searchParams.get("email")) {
      navigate("/");
    }
    setEmail(searchParams.get("email")?.replace(" ", "+") as string);
    const timer = setTimeout(() => {
      (document.getElementsByName("password")[0] as HTMLInputElement).value =
        "";
      (document.getElementsByName("code")[0] as HTMLInputElement).value = "";
    }, 700);
    return () => clearTimeout(timer);
  }, [navigate, searchParams]);

  const submitForm = async (form: FormValuesProps) => {
    try {
      await Auth.forgotPasswordSubmit(email, form.code, form.password);
      navigate("/login?updated=true");
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          addNewNotification({
            text: translate(e.message),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  return (
    <NotLoggedInContainer
      text={t("signupFlow.common.newPassword")}
      variant="forgot-password"
      title={t("common.logIn")}
    >
      <YellowFrame>
        <Formik<FormValuesProps>
          initialValues={{
            code: "",
            password: "",
          }}
          validationSchema={Yup.object({
            code: Yup.string().required(t("validation.fieldRequired")),
            password: Yup.string()
              .required(t("validation.fieldRequired"))
              .matches(/^(?=.*[0-9])/, t("validation.passMatch"))
              .min(8, t("validation.minPass")),
          })}
          onSubmit={async (values) => {
            await submitForm(values);
          }}
          component={(props) => <Form {...{ ...props, email }} />}
        />
        <Link to="/login">{t("signupFlow.common.backToLogin")}</Link>
      </YellowFrame>
    </NotLoggedInContainer>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  email,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="new-password-form">
      <p className="activate">
        <Trans
          i18nKey="signupFlow.common.emailConfirmation"
          values={{ email }}
          components={[<strong />]}
        />
      </p>

      <p className="label">{t("signupFlow.common.confirmationCode")}</p>
      <TextField
        fullWidth
        autoComplete="off"
        error={!!errors.code && touched.code}
        helperText={touched.code ? errors.code : null}
        hiddenLabel
        name="code"
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <p className="label">{t("signupFlow.common.newPassword")}</p>
      <TextField
        fullWidth
        autoComplete="off"
        type="password"
        error={!!errors.password && touched.password}
        helperText={touched.password ? errors.password : null}
        hiddenLabel
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <YellowButton text={t("common.send")} />
    </form>
  );
};

export default NewPassword;
