import "./Footer.scss";
import WhiteButton from "../WhiteButton/WhiteButton";

interface FooterProps {
  text: string;
  buttonIcon?: any;
  buttonText: string;
  onButtonClick?: any;
}

function Footer({ text, buttonIcon, buttonText, onButtonClick }: FooterProps) {
  return (
    <div className="footer">
      <div className="footer-inner">
        <p className="text">{text}</p>
        <WhiteButton
          text={buttonText}
          icon={buttonIcon}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
}
export default Footer;
