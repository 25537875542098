import ModalContainer from "../../../../common/components/Modals/ModalContainer/ModalContainer";
import "./Modal.scss";
import { TextField } from "@mui/material";
import WhiteButton from "../../../../common/components/WhiteButton/WhiteButton";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Auth } from "aws-amplify";
import { addNewNotification } from "../../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../../common/components/Notification/Notification.types";
import { translate } from "../../../../common/helpers";

type ButtonType = {
  icon?: string;
  text: string;
};

type ModalProps = {
  onClose: () => void;
  submitAction: () => void;
  title: string;
  lead: string;
  button: ButtonType;
  className?: string;
};

interface FormValuesProps {
  password: string;
}

interface FormContentProps {
  lead: string;
  button: ButtonType;
}

const AccountSettingsModal = ({
  onClose,
  submitAction,
  title,
  lead,
  button,
  className,
}: ModalProps) => {
  const { profile } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onModalClose = () => {
    onClose();
  };

  const handleSubmit = async ({ password }: { password: string }) => {
    if (profile?.email) {
      try {
        await Auth.signIn(profile.email, password);
        await submitAction();
      } catch (error: any) {
        dispatch(
          addNewNotification({
            text: translate(
              error.message === "Incorrect username or password."
                ? "Incorrect password."
                : error.message
            ),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  return (
    <ModalContainer
      variant={`profile-modal${className ? ` ${className}` : ""}`}
      onClose={onModalClose}
    >
      <div className="profile-modal-inner">
        <h2 className="title">{title}</h2>

        <Formik<FormValuesProps>
          initialValues={{
            password: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string().required(t("validation.fieldRequired")),
          })}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          component={(props) => <Form {...props} lead={lead} button={button} />}
        />
      </div>
    </ModalContainer>
  );
};

const Form: (
  props: FormContentProps & FormikProps<FormValuesProps>
) => JSX.Element = ({
  handleSubmit,
  errors,
  touched,
  handleChange,
  handleBlur,
  lead,
  button,
}) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit} className="profile-modal-form">
      <p className="profile-modal-lead">
        {lead}
        <span>{t("accountSettings.modal.lead")}</span>
      </p>
      <div>
        <p className="label">{t("accountSettings.modal.password")}</p>
        <TextField
          error={!!errors.password && touched.password}
          helperText={touched.password ? errors.password : null}
          name="password"
          hiddenLabel
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
        />
      </div>
      <WhiteButton text={button.text} icon={button.icon} type="submit" />
    </form>
  );
};

export default AccountSettingsModal;
