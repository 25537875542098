import "./RegisterNr.scss";
import WhiteButton from "../../../../../common/components/WhiteButton/WhiteButton";
import CopyIcon from "../../../../../assets/icons/sidebar/copy.svg";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { addNewNotification } from "../../../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../../../common/components/Notification/Notification.types";
import { CircularProgress } from "@mui/material";
import { getPaymentIntent } from "../../../../PlotSearch/PlotSearch.reducers";

type RegisterNrProps = {
  registerNrData: {
    external_id: string;
    is_paid: boolean;
    identity: string | null;
  };
};

function RegisterNr({
  registerNrData: { external_id, is_paid, identity },
}: RegisterNrProps) {
  const isLoading = is_paid && !identity;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCopy = () => {
    if (identity) {
      navigator.clipboard.writeText(identity);
      dispatch(
        addNewNotification({
          text: t("common.copied"),
          variant: NotificationVariant.INFO,
        })
      );
    }
  };

  const handlePayment = () => {
    dispatch(getPaymentIntent([external_id]));
  };

  return (
    <li className="register-nr">
      <p className="register-nr-plot">{external_id}</p>
      {isLoading ? (
        <div className="register-nr-loading">
          <p>{t("report.registerNr.loading")}</p>
          <CircularProgress size="26px" />
        </div>
      ) : identity ? (
        <div className="register-nr-copy">
          <p>{identity}</p>
          <button onClick={handleCopy}>
            <img src={CopyIcon} alt="copy-icon" />
          </button>
        </div>
      ) : (
        <div>
          <WhiteButton
            text="39zł"
            animatedIcon="payment"
            onClick={handlePayment}
          />
        </div>
      )}
    </li>
  );
}

export default RegisterNr;
