import OpenArrowIcon from "../../../../../assets/icons/openArrow.svg";
import InProgressIcon from "../../../../../assets/icons/statuses/inProgress.svg";
import CompletedIcon from "../../../../../assets/icons/statuses/finished.svg";
import ErrorIcon from "../../../../../assets/icons/statuses/fatal.svg";
import { ReportItemInterface } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import PlotExtrenalIds from "../../../../../common/components/PlotExternalIds/PlotExtrenalIds";

interface Report {
  report: ReportItemInterface;
}

const ReportItem = ({ report }: Report) => {
  const { created_at, plots, status, plot_external_ids, name } = report;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const plot = plots[0];
  const hasBeenOpened = report.has_been_opened;

  const onReportItemClick = () => {
    if (status === "completed") {
      navigate(`/reports/${report.id}`);
    }
  };

  const getProperIcon = () => {
    if (status === "completed") {
      return CompletedIcon;
    } else if (status === "canceled") {
      return ErrorIcon;
    } else {
      return InProgressIcon;
    }
  };

  return (
    <li
      key={created_at}
      className={classNames(
        {
          "yellow-border": status === "completed",
          "not-opened": !hasBeenOpened,
        },
        "report-item"
      )}
      onClick={onReportItemClick}
    >
      <span className="report-id">
        <span>{plot?.commune}</span>
        {plot_external_ids.length > 1 ? (
          <PlotExtrenalIds plotIds={plot_external_ids} />
        ) : (
          <span>{plot_external_ids[0]}</span>
        )}
        <p className="name">{name ? name.substring(0,25) : ''}</p>
      </span>
      <span className="report-date">
        {new Intl.DateTimeFormat(i18n.language, {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date(created_at))}
      </span>
      <span
        className={`report-status${
          (status === "in_progress" || status==="error") ? " report-status-spin" : ""
        }`}
      >
        <img src={getProperIcon()} alt="ikona" className="report-status-icon" />
        <span>{t(`helpers.reportStatuses.${status}`)}</span>
      </span>
      <span className="report-button center">
        <img
          src={OpenArrowIcon}
          alt="icon"
          className={`${status !== "completed" ? "report-disabled" : ""}`}
        />
      </span>
    </li>
  );
};

export default ReportItem;
