import L, { Icon } from "leaflet";
import gpzIconSVG from "../../../../../../assets/icons/signal.svg";

export const gpzIcon = new Icon({
  iconUrl: gpzIconSVG,
  iconSize: [35, 35],
});
export const nearestGpzIcon = L.divIcon({
  // html: `<span>${cluster.getChildCount()}</span>`,
  html: `<span>GPZ</span>`,
  className: "nearest-gpz-clusterIcon",
  iconSize: L.point(40, 40, true),
});

export const gpzDivIcon = L.divIcon({
  // html: `<span>${cluster.getChildCount()}</span>`,
  html: `<span>GPZ</span>`,
  className: "marker-cluster-custom",
  iconSize: L.point(40, 40, true),
});
export const createClusterCustomIcon = () =>
  L.divIcon({
    // html: `<span>${cluster.getChildCount()}</span>`,
    html: `<span>GPZ</span>`,
    className: "marker-cluster-custom",
    iconSize: L.point(45, 45, true),
  });
