import React from "react";
import "./DataBox.scss";

interface DataBoxProps {
  title: string;
  content: any;
  icon?: any;
  subtext?: any;
}

function DataBox({ title, content, icon, subtext }: DataBoxProps) {
  return (
    <div className="data-container">
      {icon && (
        <div className="img-wrapper">
          <img src={icon} alt="icon" />
        </div>
      )}
      <span>{title}</span>
      <p>{content}</p>
      {subtext && <span>Stan na: {subtext}</span>}
    </div>
  );
}
export default DataBox;
