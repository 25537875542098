import BackArrowIcon from "../../../../../assets/icons/backArrow.svg";
import { StepsWrapperProps } from "../interfaces";
import ActiveStepIcon from "../../../../../assets/icons/activeStep.svg";
import InactiveStepIcon from "../../../../../assets/icons/inactiveStep.svg";

const StepsWrapper = ({
  activeStep,
  setActiveStep,
  classname,
  children,
  stepsAmount,
}: StepsWrapperProps) => {
  const steps = Array.from({ length: stepsAmount }, (_, i) => i + 1);

  return (
    <div className={`steps-wrapper ${classname}`}>
      {activeStep > 1 && (
        <img
          src={BackArrowIcon}
          alt="ikona"
          className="back-btn"
          onClick={() => setActiveStep(activeStep - 1)}
        />
      )}
      <div className={`payment-steps ${stepsAmount === 4 ? "wide" : ""}`}>
        {steps.map((step: number) => (
          <div
            className="step"
            key={step}
            onClick={activeStep >= step ? () => setActiveStep(step) : undefined}
          >
            <img
              src={activeStep >= step ? ActiveStepIcon : InactiveStepIcon}
              alt="ikona"
            />
            <p>{step}</p>
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

export default StepsWrapper;
