import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./Login.styles.scss";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import YellowButton from "../../../common/components/YellowButton/YellowButton";
import YellowFrame from "../../../common/components/YellowFrame/YellowFrame";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import WhiteButton from "../../../common/components/WhiteButton/WhiteButton";
import LogoShort from "../../../assets/images/logoShort.png";
import Logo1 from "../../../assets/images/UeLogos/logo-1.svg";
import Logo2 from "../../../assets/images/UeLogos/logo-2.svg";
import Logo3 from "../../../assets/images/UeLogos/logo-3.svg";
import Logo5 from "../../../assets/images/UeLogos/logo-5.svg";
import Logo6 from "../../../assets/images/UeLogos/logo-6.svg";
import Logo4 from "../../../assets/images/UeLogos/logo-4.svg";
import LogoFB from "../../../assets/icons/logoFb.svg";
import LogoGoogle from "../../../assets/icons/logoGoogle.svg";
import { useAppDispatch } from "../../../redux/hooks";
import { signIn } from "../Auth.reducers";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { translate } from "../../../common/helpers";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { useTranslation } from "react-i18next";
import NotLoggedInContainer from "../../../common/components/NotLoggedInContainer/NotLoggedInContainer";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

interface FormValuesProps {
  email: string;
  password: string;
}

type RespType = {
  payload: {
    error: string;
  };
  meta: {
    requestStatus: string;
  };
  type: string;
};

const Login = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const submitForm = async (values: FormValuesProps) => {
    try {
      const result = (await dispatch(
        signIn({ username: values.email, password: values.password })
      )) as RespType;
      if (result.meta.requestStatus !== "fulfilled") {
        const { payload } = result;

        if (payload.error === "Password reset required for the user") {
          dispatch(
            addNewNotification({
              textWithLinks: {
                i18nKey: "helpers.translate.changedPassword",
                links: [`/new-password?email=${values.email}`],
              },
              variant: NotificationVariant.INFO,
            })
          );
          return;
        }

        dispatch(
          addNewNotification({
            text: translate(payload.error),
            variant: NotificationVariant.ERROR,
          })
        );
      } else if (searchParams.get("guest")) {
        searchParams.delete("guest");
        navigate("/plot-analysis");
      } else {
        navigate("/");
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          addNewNotification({
            text: translate(e.message),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  if (searchParams.get("activated")) {
    searchParams.delete("activated");
    dispatch(
      addNewNotification({
        text: t("signupFlow.notifications.accountActivated"),
        variant: NotificationVariant.SUCCESS,
      })
    );
  }
  if (searchParams.get("updated")) {
    searchParams.delete("updated");
    dispatch(
      addNewNotification({
        text: t("signupFlow.notifications.passwordChanged"),
        variant: NotificationVariant.SUCCESS,
      })
    );
  }

  return (
    <NotLoggedInContainer variant="login-page" title={t("common.logIn")}>
      <div className="login-content-wrapper">
        <div className="cards-wrapper">
          <Formik<FormValuesProps>
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string().required(t("validation.fieldRequired")),
              password: Yup.string().required(t("validation.fieldRequired")),
            })}
            onSubmit={async (values) => {
              await submitForm(values);
            }}
            component={(props) => <Form {...props} />}
          />
          <div className="help-wrapper">
            <YellowFrame className="help-frame" reverse>
              <p className="title">{t("signupFlow.loginPage.yellowHeading")}</p>
              <img height="120px" src={LogoShort} alt="logo" />
              <a href="https://www.sunmetric.pl/upselling" target="_blank">
                <WhiteButton text={t("signupFlow.common.ctaBtn")} />
              </a>
            </YellowFrame>
          </div>
        </div>

        <div className="login-footer">
          <div className="logos-wrapper">
            <img src={Logo6} alt="logo" />
            <img src={Logo1} alt="logo" />
            <img src={Logo2} alt="logo" />
            <img src={Logo3} alt="logo" />
            <img src={Logo4} alt="logo" />
            <img src={Logo5} alt="logo" />
          </div>
          <p className="ue-text">{t("signupFlow.loginPage.footerInfo")}</p>
          <p className="ams">{t("signupFlow.loginPage.footerInvestor")}</p>
        </div>
      </div>
    </NotLoggedInContainer>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="login-form-wrapper">
      <YellowFrame>
        <form onSubmit={handleSubmit} className="login-form">
          <p className="label">{t("userData.email")}</p>
          <TextField
            fullWidth
            error={!!errors.email && touched.email}
            helperText={touched.email ? errors.email : null}
            hiddenLabel
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              "data-testid": "login-email",
            }}
          />

          <p className="label">{t("userData.password")}</p>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            error={!!errors.password && touched.password}
            helperText={touched.password ? errors.password : null}
            hiddenLabel
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              "data-testid": "login-password",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon
                        style={{
                          color: "#9E9E9E",
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="remember-me-wrapper">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label={t("signupFlow.loginPage.rememberMe")}
                onChange={handleChange}
                name="rememberMe"
              />
            </FormGroup>
            <Link to="/forgot-password">
              {t("signupFlow.loginPage.forgotPassword")}
            </Link>
          </div>

          <YellowButton text={t("common.signin")} />
          <p className="register-text">
            {t("signupFlow.loginPage.noAccount")}{" "}
            <Link to="/register">{t("common.signUp")}</Link>
          </p>
          <p className="register-text">
            {t("signupFlow.loginPage.loginWith")}
          </p>
          <div className="sm-buttons-wrapper">
            <WhiteButton
              text="Facebook"
              icon={LogoFB}
              onClick={() =>
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Facebook,
                })
              }
              type="button"
            />
            <WhiteButton
              text="Google"
              icon={LogoGoogle}
              onClick={() =>
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Google,
                })
              }
              type="button"
            />
          </div>
        </form>
      </YellowFrame>
    </div>
  );
};

export default Login;
