import { createSlice } from "@reduxjs/toolkit";
import { Action, GroupsUsersState } from "./Notification.types";

const initialState: GroupsUsersState = {
  notifications: [],
  latestNotification: null,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNewNotification: (state, action: Action) => {
      state.latestNotification = action.payload;
      state.notifications = [...state.notifications, action.payload];
    },
  },
});

export const { addNewNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
