import { useEffect } from "react";
import "./Report.styles.scss";
import ReportSidebar from "./components/ReportSidebar/ReportSidebar";
import BasicInformation from "./components/BasicInformation/BasicInformation";
import ElectricityParams from "./components/ElectricityParams/ElectricityParams";
import AreaMap from "./components/AreaMap/AreaMap";
import { getReport } from "./Report.reducers";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import ReportRefresh from "./Report.refresh";
import PageLoader from "../../../common/components/PageLoader/PageLoader";
import RegisterNrSection from "./components/RegisterNr/RegisterNrSection";

const SharedReport = () => {
  const { loading, report,error } = useAppSelector(
    (state: RootState) => state.report
  );
  const dispatch = useAppDispatch();
  const id = window.location.href.split("share/")[1];

  useEffect(() => {
    const fetchReport = async () => {
      dispatch(getReport({ id, isShared: true }));
    };
    fetchReport();
  }, [dispatch, id]);

  const reportStatus = report?.task?.status;

  return (
    <>
      <PageLoader open={loading} />
      {!loading && error && <></>}
      {!loading && !error && (
        <div className="report-content">
          {reportStatus !== "completed" ? (
            <ReportRefresh isShared />
          ) : (
            <>
              <ReportSidebar isShared />
              <div className="sections">
                <BasicInformation />
                <ElectricityParams />
                <RegisterNrSection />
                <AreaMap />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SharedReport;
