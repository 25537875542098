import WhiteButton from "../../../WhiteButton/WhiteButton";
import UserIcon from "../../../../../assets/icons/sidebar/avatar-man.svg";
import PencilIcon from "../../../../../assets/icons/pencil.svg";
import BuildingIcon from "../../../../../assets/icons/building.svg";
import { useAppSelector } from "../../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { BillingDataViews, ConfirmInvoiceDataProps } from "../interfaces";

const ConfirmInvoiceData = ({
  setActiveStep,
  activeStep,
  invoiceFor,
  setCurrentView,
}: ConfirmInvoiceDataProps) => {
  const { profile } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  return (
    <div className="confirm-wrapper">
      {invoiceFor === "b" ? (
        <div className="confirm-invoice-data">
          <p className="title">{t("common.confirmData")}</p>
          <p>{profile?.invoice?.b.companyName || "-"}</p>
          <p>{profile?.invoice?.b.nip || "-"}</p>
          <p>{`${profile?.invoice?.b.street || "-"} ${
            profile?.invoice?.b.houseNr || "-"
          } ${profile?.invoice?.b.apartmentNr || ""}`}</p>
          <p>{`${profile?.invoice?.b.city || "-"}, ${
            profile?.invoice?.b.postCode || "-"
          }`}</p>
        </div>
      ) : (
        <div className="confirm-invoice-data">
          <p className="title">{t("common.confirmData")}</p>
          <p>{`${profile?.invoice?.np.firstName || "-"} ${
            profile?.invoice?.np.lastName || "-"
          }`}</p>
          <p>{`${profile?.invoice?.np.street || "-"} ${
            profile?.invoice?.np.houseNr || "-"
          } ${profile?.invoice?.np.apartmentNr || ""}`}</p>
          <p>{`${profile?.invoice?.np.city || "-"}, ${
            profile?.invoice?.np.postCode || "-"
          }`}</p>
        </div>
      )}
      <div className="buttons-wrapper">
        <WhiteButton
          text={t("common.edit")}
          icon={PencilIcon}
          onClick={() => setCurrentView(BillingDataViews.form)}
        />
        <WhiteButton
          text={t("common.confirmData")}
          icon={invoiceFor === "b" ? BuildingIcon : UserIcon}
          onClick={() => setActiveStep(activeStep + 1)}
        />
      </div>
    </div>
  );
};

export default ConfirmInvoiceData;
