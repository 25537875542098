import "./Section.scss";
import SectionTitle from "../../../../../../common/components/SectionTitle/SectionTitle";
import React, { ReactElement, RefObject } from "react";

interface SectionProps {
  title: string;
  preTitle?: string | ReactElement;
  icon: any;
  id: string | undefined;
  children: any;
  grayBg?: boolean;
  borderless?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
}

function Section({
  title,
  preTitle,
  icon,
  id,
  children,
  grayBg,
  borderless,
  innerRef,
}: SectionProps) {
  return (
    <div
      id={id}
      className={`section${grayBg ? " section-gray" : ""}${
        borderless ? " section-borderless" : ""
      }`}
      ref={innerRef}
      tabIndex={0}
    >
      {preTitle && preTitle}
      <SectionTitle title={title} icon={icon} />
      <div className="section-content">{children}</div>
    </div>
  );
}
export default Section;
