import EnviromentProtectionIcon from "../../../../../assets/icons/report/enviromentProtection.svg";
import DitchesIcon from "../../../../../assets/icons/report/ditches.svg";
import WatersIcon from "../../../../../assets/icons/report/waters.svg";
import FloodplainsIcon from "../../../../../assets/icons/report/floodplains.svg";
import BuildingsIcon from "../../../../../assets/icons/report/buildings.svg";
import TreeIcon from "../../../../../assets/icons/report/tree.svg";
import GroundClassIcon from "../../../../../assets/icons/report/groundClass.svg";
import i18n from "i18next";

export interface Impediment {
  type: string;
  text: string;
  icon: string;
  color: string;
}

export const getImpedimentsData = () => [
  {
    type: "forests",
    text: i18n.t("report.areaMap.forestsBtn"),
    icon: TreeIcon,
    color: "#87b265",
  },
  {
    type: "environment_protections",
    text: i18n.t("report.areaMap.enviromentProtectionsBtn"),
    icon: EnviromentProtectionIcon,
    color: "#bda3de",
  },
  {
    type: "buildings",
    text: i18n.t("report.areaMap.buildingsBtn"),
    icon: BuildingsIcon,
    color: "#080606",
  },
  {
    type: "ditches",
    text: i18n.t("report.areaMap.ditchesBtn"),
    icon: DitchesIcon,
    color: "#cd7320",
  },
  {
    type: "water",
    text: i18n.t("report.areaMap.waterBtn"),
    icon: WatersIcon,
    color: "#97cfe8",
  },
  {
    type: "floodplains",
    text: i18n.t("report.areaMap.floodplainsBtn"),
    icon: FloodplainsIcon,
    color: "#577999",
  },
];

export const groundsData = [
  {
    type: "IV",
    text: "IV",
    icon: GroundClassIcon,
    color: "#080606",
  },
  {
    type: "VI",
    text: "VI",
    icon: GroundClassIcon,
    color: "#080606",
  },
  {
    type: "AB",
    text: "AB",
    icon: GroundClassIcon,
    color: "#080606",
  },
];
