import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import i18n from "i18next";

interface PaymentsState {
  payment: {
    showPaymentModal: boolean;
    showPaymentConfirmationModal: boolean;
  };
}

const initialState: PaymentsState = {
  payment: {
    showPaymentModal: false,
    showPaymentConfirmationModal: false,
  },
};



export const paymentModalSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    openPaymentModal: (state) => {
      state.payment.showPaymentModal = true;
    },
    closePaymentModal: (state) => {
      state.payment.showPaymentModal = false;
    },
  },
});

export const { openPaymentModal, closePaymentModal } = paymentModalSlice.actions;