import "./Error.styles.scss";
import YellowButton from "../../common/components/YellowButton/YellowButton";
import ErrorImg from "../../assets/images/errorPage.svg";
import { useNavigate } from "react-router-dom";
import Navbar from "../../common/components/Navbar/Navbar";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <div className="page-not-found-wrapper">
        <h1>Wystąpił błąd</h1>
        <div className="img">
          <p className="error-type">404</p>
          <img src={ErrorImg} alt="404" />
        </div>
        <YellowButton text="Powrót" onClick={() => navigate("/")} />
      </div>
    </div>
  );
};

export default NotFoundPage;
