import { Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import "./Register.styles.scss";
import YellowButton from "../../../common/components/YellowButton/YellowButton";
import YellowFrame from "../../../common/components/YellowFrame/YellowFrame";
import { Link, useNavigate } from "react-router-dom";
import NotLoggedInContainer from "../../../common/components/NotLoggedInContainer/NotLoggedInContainer";
import { Auth } from "@aws-amplify/auth";
import { translate } from "../../../common/helpers";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { useAppDispatch } from "../../../redux/hooks";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { Trans, useTranslation } from "react-i18next";
import LogoShort from "../../../assets/images/logoShort.png";
import WhiteButton from "../../../common/components/WhiteButton/WhiteButton";
import AgreementsBoxes from "../../Profile/components/AgreementsBoxes/AgreementsBoxes";

interface FormValuesProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | number;
  password: string;
  repeatPassword: string;
  tosAgreement: boolean;
  marketingAgreement: boolean;
}

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const submitForm = async (form: FormValuesProps) => {
    const getPhoneNumber = () => {
      if (form.phoneNumber) {
        const phoneNumber = form.phoneNumber.toString().replace(/\s/g, "");
        return phoneNumber.substring(0, 1) === "+"
          ? phoneNumber
          : `+48${phoneNumber}`;
      } else {
        return " ";
      }
    };

    try {
      await Auth.signUp({
        username: form.email,
        password: form.password,
        attributes: {
          email: form.email,
          given_name: form.firstName,
          family_name: form.lastName,
          phone_number: getPhoneNumber(),
          ["custom:marketing_agreement"]: form.marketingAgreement.toString(),
          ["custom:tos_agreement"]: form.tosAgreement.toString(),
        },
        autoSignIn: {
          enabled: true,
        },
      });
      navigate(`/confirm?email=${form.email}`);
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          addNewNotification({
            text: translate(e.message),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  return (
    <NotLoggedInContainer
      variant="register-wrapper"
      title={t("common.registration")}
    >
      <div className="cards-wrapper">
        <Formik<FormValuesProps>
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            password: "",
            repeatPassword: "",
            tosAgreement: false,
            marketingAgreement: false,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t("validation.fieldRequired")),
            lastName: Yup.string().required(t("validation.fieldRequired")),
            email: Yup.string()
              .required(t("validation.fieldRequired"))
              .email(t("validation.email")),
            phoneNumber: Yup.string()
              .min(9, t("validation.minPhone"))
              .max(23, t("validation.maxPhone")),
            password: Yup.string()
              .required(t("validation.fieldRequired"))
              .matches(/^(?=.*[0-9])/, t("validation.passMatch"))
              .min(8, t("validation.minPass")),
            repeatPassword: Yup.string()
              .required(t("validation.fieldRequired"))
              .matches(/^(?=.*[0-9])/, t("validation.passMatch"))
              .min(8, t("validation.minPass")),
            tosAgreement: Yup.bool().oneOf(
              [true],
              t("validation.fieldRequired")
            ),
            marketingAgreement: Yup.bool(),
          })}
          onSubmit={async (values) => {
            await submitForm(values);
          }}
          component={(props) => <Form {...props} />}
        />
        <YellowFrame reverse className="register-card-wrapper">
          <p className="register-text">
            <Trans
              i18nKey="signupFlow.registerPage.heading"
              components={[<br />, <span />]}
            />
          </p>
          <img height="160px" src={LogoShort} alt="logo" />
          <a href="https://app.sunmetric.pl/login" target="_blank">
            <WhiteButton text={t("signupFlow.common.raportExample")} />
          </a>
        </YellowFrame>
      </div>
    </NotLoggedInContainer>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation();

  return (
    <div className="register-form-wrapper">
      <YellowFrame>
        <form onSubmit={handleSubmit} className="register-form">
          <div className="input-wrapper">
            <div>
              <p className="label">{t("userData.name")}</p>
              <TextField
                fullWidth
                error={!!errors.firstName && touched.firstName}
                helperText={touched.firstName ? errors.firstName : null}
                hiddenLabel
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <p className="label">{t("userData.surname")}</p>
              <TextField
                fullWidth
                error={!!errors.lastName && touched.lastName}
                helperText={touched.lastName ? errors.lastName : null}
                hiddenLabel
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <p className="label">{t("userData.email")}</p>
          <TextField
            fullWidth
            error={!!errors.email && touched.email}
            helperText={touched.email ? errors.email : null}
            hiddenLabel
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <p className="label">{t("userData.phone")}</p>
          <TextField
            fullWidth
            error={!!errors.phoneNumber && touched.phoneNumber}
            helperText={touched.phoneNumber ? errors.phoneNumber : null}
            hiddenLabel
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <div className="input-wrapper">
            <div>
              <p className="label">{t("userData.password")}</p>
              <TextField
                type="password"
                fullWidth
                error={!!errors.password && touched.password}
                helperText={touched.password ? errors.password : null}
                hiddenLabel
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div>
              <p className="label">{t("userData.repeatPassword")}</p>
              <TextField
                type="password"
                fullWidth
                error={!!errors.repeatPassword && touched.repeatPassword}
                helperText={
                  touched.repeatPassword ? errors.repeatPassword : null
                }
                hiddenLabel
                name="repeatPassword"
                value={values.repeatPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <AgreementsBoxes
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />

          <YellowButton text={t("common.signUp")} />

          <p className="login-link">
            {t("signupFlow.common.hasAccount")}{" "}
            <Link to="/login">{t("common.signin")}</Link>
          </p>
        </form>
      </YellowFrame>
    </div>
  );
};

export default Register;
