import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import "./Register.styles.scss";
import "../EmailConfirmed/EmailConfirmed.scss";
import YellowButton from "../../../common/components/YellowButton/YellowButton";
import YellowFrame from "../../../common/components/YellowFrame/YellowFrame";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import NotLoggedInContainer from "../../../common/components/NotLoggedInContainer/NotLoggedInContainer";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { translate } from "../../../common/helpers";
import { useAppDispatch } from "../../../redux/hooks";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { useTranslation } from "react-i18next";

interface FormValuesProps {
  code: string;
}

const Confirm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (!searchParams.get("email")) {
      navigate("/email-confirmed");
    }
    setEmail(searchParams.get("email")?.replace(" ", "+") as string);
  }, [navigate, searchParams]);

  const submitForm = async (form: FormValuesProps) => {
    try {
      await Auth.confirmSignUp(email, form.code);
      navigate("/login?activated=true");
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          addNewNotification({
            text: translate(e.message),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  return (
    <NotLoggedInContainer
      text={t("signupFlow.emailConfirmed.heading")}
      variant="confirm-wrapper"
      title={t("common.registration")}
    >
      <Formik<FormValuesProps>
        initialValues={{
          code: "",
        }}
        validationSchema={Yup.object({
          code: Yup.string().required(t("validation.fieldRequired")),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
        component={(props) => <ConfirmForm {...props} email={email} />}
      />
    </NotLoggedInContainer>
  );
};

const ConfirmForm: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation();

  return (
    <div className="confirm-form-wrapper">
      <div className="cards-wrapper">
        <YellowFrame>
          <form onSubmit={handleSubmit} className="confirm-form">
            <p className="activate">
              {t("signupFlow.common.emailConfirmation")}
            </p>
            <TextField
              fullWidth
              error={!!errors.code && touched.code}
              helperText={touched.code ? errors.code : null}
              hiddenLabel
              name="code"
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p>{t("signupFlow.common.emailConfirmationHelper")}</p>
            <YellowButton text={t("signupFlow.common.confirmCode")} />
          </form>
        </YellowFrame>
      </div>
    </div>
  );
};

export default Confirm;
