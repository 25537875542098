import React, { useEffect } from "react";
import "./SidebarWrapper.scss";
import { Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import logoLong from "../../../assets/images/sunmetricDark.svg";
import logoShort from "../../../assets/images/logoShort.svg";
import burgerBg from "../../../assets/icons/burger-bg.svg";
import sidebarArrow from "../../../assets/icons/sidebar-arrow.svg";
import LangSelect from "../Navbar/components/LangSelect";

interface SidebarProps {
  children: any;
  open: boolean;
  setOpen: any;
  isMobile?: boolean;
}

const SidebarWrapper = ({
  children,
  setOpen,
  open,
  isMobile,
}: SidebarProps) => {
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  useEffect(() => {
    if (isMobile) setOpen(false);
  }, [isMobile, setOpen]);

  return (
    <>
      <div className="sidebar-wrapper-offset"></div>
      <div className={`sidebar-wrapper ${!open ? "nav-closed" : ""}`}>
        <nav className="sidebar-wrapper-mobile-menu">
          <Link to="/" className="sidebar-logo">
            <img src={logoLong} alt="logo" />
          </Link>
          <button
            className={`sidebar-toggle-btn toggle-btn-mobile${
              open ? " is-opened" : ""
            }`}
            onClick={() => {
              setOpen(!open);
            }}
            type="button"
          >
            <img className="sidebar-toggle-bg" src={burgerBg} alt="" />
            <span />
            burger
          </button>
        </nav>

        <Drawer
          anchor={"left"}
          open={open || !isMobile}
          onClose={toggleDrawer(false)}
          variant={isMobile ? "temporary" : "persistent"}
          sx={{
            width: open ? (isMobile ? "100vw" : 350) : isMobile ? 0 : "100px",
            transition: "width .3s",
            zIndex: isMobile ? 102 : 10,
            ".MuiDrawer-paper": {
              backgroundColor: isMobile ? "#fff" : "#F6F8FA",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              zIndex: 10,
            },
          }}
        >
          <div className="sidebar-wrapper-inner">
            <Link to="/" className="sidebar-logo">
              {open || isMobile ? (
                <img src={logoLong} alt="logo" />
              ) : (
                <img
                  src={logoShort}
                  alt="logo"
                  className="sidebar-logo-short"
                />
              )}
            </Link>
            <div className="sidebar-lang-select">
              <LangSelect />
            </div>
            <div className="sidebar-wrapper-content">{children}</div>
            <button
              className={`sidebar-toggle-btn toggle-btn-desktop${
                open ? " is-opened" : ""
              }`}
              onClick={() => {
                setOpen(!open);
              }}
              type="button"
            >
              <img className="sidebar-toggle-bg" src={burgerBg} alt="" />
              <img
                className="sidebar-toggle-arrow"
                src={sidebarArrow}
                alt="arrow icon"
              />
            </button>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default SidebarWrapper;
