import "./Menu.scss";
import ProfileIcon from "../../../../assets/icons/menuProfile.svg";
import ReportsListIcon from "../../../../assets/icons/menuReportsList.svg";
import PlotAnalysesIcon from "../../../../assets/icons/menuPlotAnalysis.svg";
import { Link, useLocation } from "react-router-dom";
import LogoLong from "../../../../assets/images/sunmetricDark.svg";
import LogOutIcon from "../../../../assets/icons/sidebar/logout.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getAvailableReportsCount } from "../../../../lib/Report/Report.service";
import { updateAvailableReportsCount } from "../../../../lib/Report/ReportsList/ReportsList.reducers";

interface MenuProps {
  mobile?: boolean;
}

interface MenuItemProps {
  text: string;
  icon: any;
  isActive?: boolean;
  className?: string;
  link: string;
  badge?: number;
}

const MenuItem = ({
  text,
  icon,
  link,
  isActive,
  className,
  badge,
}: MenuItemProps) => {
  return (
    <li
      className={`main-menu-item${isActive ? " is-active" : ""}${
        className ? ` ${className}` : ""
      }${badge ? " main-menu-item-badge" : ""}`}
    >
      <Link to={link} className="main-menu-link">
        <img src={icon} alt="icon" />
        <span>{text}</span>
        {badge ? <span className="main-menu-badge">{badge}</span> : ""}
      </Link>
    </li>
  );
};

const Menu = ({ mobile }: MenuProps) => {
  const dispatch = useAppDispatch();
  const { availableReportsCount } = useAppSelector(
    (state) => state.reportsList
  );
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAvailableReportsCount = async () => {
      const count = await getAvailableReportsCount();
      dispatch(updateAvailableReportsCount(count));
    };
    fetchAvailableReportsCount();
  }, [dispatch]);

  const menuItems = [
    {
      text: t("common.plotAnalysis"),
      icon: PlotAnalysesIcon,
      link: "/plot-analysis",
      active: pathname === "/plot-analysis",
    },
    {
      text: t("common.reportsList"),
      icon: ReportsListIcon,
      link: "/reports",
      badge: availableReportsCount,
      active: pathname === "/reports",
    },
    {
      text: t("menu.profile"),
      icon: ProfileIcon,
      link: "/profile/personal-data",
      active: pathname.includes("/profile"),
    },
    {
      text: mobile ? t("menu.logout") : "",
      icon: LogOutIcon,
      link: "/logout",
      className: "main-menu-logout",
    },
  ];

  return (
    <nav
      className={`main-menu${
        mobile ? " main-menu-mobile" : " main-menu-desktop"
      }`}
    >
      {mobile && (
        <Link to="/" className="main-menu-logo">
          <img src={LogoLong} alt="logo" className="logo" />
        </Link>
      )}
      <ul className="main-menu-list">
        {menuItems.map((item) => (
          <MenuItem
            text={item.text}
            icon={item.icon}
            link={item.link}
            key={item.link}
            isActive={item?.active}
            className={item?.className}
            badge={item?.badge}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
