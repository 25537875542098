import { useEffect } from "react";
import "./Report.styles.scss";
import ReportSidebar from "./components/ReportSidebar/ReportSidebar";
import BasicInformation from "./components/BasicInformation/BasicInformation";
import ElectricityParams from "./components/ElectricityParams/ElectricityParams";
import RegisterNrSection from "./components/RegisterNr/RegisterNrSection";
import AreaMap from "./components/AreaMap/AreaMap";
import { getReport } from "./Report.reducers";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import ReportRefresh from "./Report.refresh";
import PageLoader from "../../../common/components/PageLoader/PageLoader";
import { useParams} from "react-router-dom";
import { useWebsockets } from "../../../common/hooks";

const Report = () => {
  const { loading, report, error } = useAppSelector(
    (state: RootState) => state.report
  );
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useWebsockets();

  useEffect(() => {
    const fetchReport = async () => {
      dispatch(getReport({ id }));
    };
    fetchReport();
  }, [dispatch, id]);

  const reportStatus = report?.task?.status;
  const isLoading = loading && report?.id !== parseInt(id as string);
  return (
    <>
      <PageLoader
        open={isLoading}
        preloaded={report?.id === parseInt(id as string)}
      />
      {!isLoading && error && <></>}
      {!isLoading && !error && (
        <div className="report-content">
          {reportStatus === "in_progress" ? (
            <ReportRefresh />
          ) : ( 
          <>
            <ReportSidebar />
            <div className="sections">
              <BasicInformation />
              <ElectricityParams />
              <RegisterNrSection />
              <AreaMap />
            </div>
          </>
           )} 
        </div>
      )}
    </>
  );
};

export default Report;
