import { useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { MapContainer, TileLayer } from "react-leaflet";
import { useAppSelector } from "../../../../../../redux/hooks";
import CustomElements from "./components/CustomElements";
import { ReactComponent as CheckboxActive } from "../../../../../../assets/icons/checkboxActive.svg";
import { ReactComponent as CheckboxInactive } from "../../../../../../assets/icons/checkboxInactive.svg";
import { useTranslation } from "react-i18next";
import "./ElectricityMap.scss";

const Option = ({ fullWidth, checked, onChange, label }: any) => (
  <Grid item xs={12} md={fullWidth ? 12 : 6}>
    <FormGroup>
      <FormControlLabel
        className="electricity-map-option"
        control={
          <Checkbox
            checked={!!checked}
            disableRipple
            icon={<CheckboxInactive />}
            checkedIcon={<CheckboxActive />}
          />
        }
        onChange={onChange}
        label={label}
      />
    </FormGroup>
  </Grid>
);

export interface MapOptionsInterface {
  substationsVisibility: boolean;
  highVoltageLinesWaysVisibility: boolean;
  lowVoltageLinesWaysVisibility: boolean;
  nearestCableVisibility: boolean;
  nearestSubstationVisibility: boolean;
}

const ElectricityMap = () => {
  const analysisData = useAppSelector((state: any) => state.report.report);
  const { t } = useTranslation();

  const [mapOptions, setMapOptions] = useState<MapOptionsInterface>({
    substationsVisibility: true,
    highVoltageLinesWaysVisibility: true,
    lowVoltageLinesWaysVisibility: true,
    nearestCableVisibility: true,
    nearestSubstationVisibility: true,
  });

  const changeMapOption = (key: keyof MapOptionsInterface) => {
    setMapOptions((prev) => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  return (
    <div className="electricity-map">
      <div className="data">
        <Grid container spacing={1}>
          <Option
            fullWidth
            checked={mapOptions.substationsVisibility}
            onChange={() => changeMapOption("substationsVisibility")}
            label={t("report.electricityMap.substations")}
          />
          <Option
            checked={mapOptions.highVoltageLinesWaysVisibility}
            onChange={() => changeMapOption("highVoltageLinesWaysVisibility")}
            label={t("report.electricityMap.highVoltageLines")}
          />
          <Option
            checked={mapOptions.lowVoltageLinesWaysVisibility}
            onChange={() => changeMapOption("lowVoltageLinesWaysVisibility")}
            label={t("report.electricityMap.lowVoltageLines")}
          />
          <Option
            checked={mapOptions.nearestCableVisibility}
            onChange={() => changeMapOption("nearestCableVisibility")}
            label={t("report.electricityMap.nearestCable")}
          />
          <Option
            checked={mapOptions.nearestSubstationVisibility}
            onChange={() => changeMapOption("nearestSubstationVisibility")}
            label={t("report.electricityMap.nearestSubstation")}
          />
        </Grid>
      </div>

      <MapContainer
        center={{
          lat: analysisData.plot_analysis.latitude,
          lng: analysisData.plot_analysis.longitude,
        }}
        zoom={14}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        style={{
          isolation: "isolate",
          maxWidth: 600,
          height: 400,
          margin: "30px auto 0",
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <CustomElements analysisData={analysisData} mapOptions={mapOptions} />
      </MapContainer>
    </div>
  );
};

export default ElectricityMap;
