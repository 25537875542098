import { Backdrop, Fade, Modal } from "@mui/material";
import "./PageLoader.scss";
import NutIcon from "../../../assets/icons/burger-bg.svg";
import SunIcon from "../../../assets/icons/sun.svg";
import { useEffect, useState } from "react";

type LoaderProps = {
  open: boolean;
  preloaded?: boolean;
  transparentBg?: boolean;
};

const PageLoader = ({ open, preloaded, transparentBg }: LoaderProps) => {
  const [isOpen, setIsOpen] = useState(!preloaded);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      className="page-loader"
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backgroundColor: transparentBg ? "transparent" : "#fff" },
      }}
    >
      <Fade in={isOpen}>
        <div className="page-loader-inner">
          <img src={SunIcon} alt="loading icon" />
          <img src={NutIcon} alt="loading icon" />
        </div>
      </Fade>
    </Modal>
  );
};

export default PageLoader;
