import { useState } from "react";
import "./SearchBox.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputAdornment, TextField } from "@mui/material";
import MagnifyingGlassIcon from "../../../assets/icons/magnifyingGlass.svg";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface InputProps {
  query: string;
}

interface SearchBoxProps {
  setSearchQuery: any;
  setPage: any;
}

const SearchBox = ({ setSearchQuery, setPage }: SearchBoxProps) => {
  return (
    <div className="searchbox">
      <p className="filter-title">{t("reportsList.findReport")}</p>
      <Formik<InputProps>
        initialValues={{
          query: "",
        }}
        validationSchema={Yup.object({
          query: Yup.string(),
        })}
        onSubmit={async (values) => {
          console.log("form", values);
        }}
        component={(props) => (
          <Form {...props} setSearchQuery={setSearchQuery} setPage={setPage} />
        )}
      />
    </div>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setSearchQuery,
  setPage,
}) => {
  const [time, setTime] = useState<any>("");
  const { t } = useTranslation();

  const handleOnChange = (event: any) => {
    const search = () => {
      setSearchQuery(event.target.value);
      setPage(1);
    };
    if (time) clearTimeout(time);
    setTime(setTimeout(() => search(), 300));
  };

  return (
    <form onSubmit={handleSubmit} onChange={handleOnChange} className="">
      <TextField
        error={!!errors.query && touched.query}
        helperText={touched.searchQuery ? errors.query : null}
        hiddenLabel
        name="query"
        value={values.query}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={t("reportsList.search")}
        autoFocus={values.query.length ? true : false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img width="32px" src={MagnifyingGlassIcon} alt="" />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchBox;
