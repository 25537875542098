import { useEffect } from "react";
import "./Select.scss";
import TickIcon from "../../../../../../../assets/images/TickSmall.svg";
import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { LayerOptionType } from "../../AreaMap";

type Props = {
  subcategories: string[];
  setSubcategories: any;
  optionsList: LayerOptionType[];
};

const SubcategoriesSelect = ({
  subcategories,
  setSubcategories,
  optionsList,
}: Props) => {
  const { t } = useTranslation();
  useEffect(() => {
    const markAllOptions = [...optionsList.map((option) => option.key), ""];

    setSubcategories(markAllOptions);
  }, [optionsList, setSubcategories]);

  const handleChange = (e: SelectChangeEvent<typeof subcategories>) => {
    const {
      target: { value },
    } = e;
    setSubcategories(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl size="small" className="category-select">
      <Select
        value={subcategories}
        multiple
        onChange={handleChange}
        renderValue={() => {
          return <p>{t("report.areaMap.selectLayer")}</p>;
        }}
      >
        {optionsList.map(({ key, name }) => (
          <MenuItem value={key} className="category-select-menu" key={key}>
            <p>{name}</p>
            {subcategories.indexOf(key) > -1 && (
              <img width={20} src={TickIcon} />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SubcategoriesSelect;
