import React from "react";
import "./SectionTitle.scss";

interface SectionTitleProps {
  title: string;
  icon: any;
}

function SectionTitle({ title, icon }: SectionTitleProps) {
  return (
    <div className="title">
      <img src={icon} alt={title} />
      <p>{title}</p>
    </div>
  );
}
export default SectionTitle;
