import "./PlotSearch.styles.scss";
import YellowButton from "../../common/components/YellowButton/YellowButton";
import PlotSearchMap from "./PlotSearchMap/PlotSearchMap";
import MarkerIcon from "../../assets/icons/marker.svg";
import ReportsListIcon from "../../assets/icons/menuReportsList.svg";
import Footer from "../../common/components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PlotItem, SelectedPlotItemProps } from "./interfaces";
import InProgressIcon from "../../assets/icons/statuses/inProgress.svg";
import Navbar from "../../common/components/Navbar/Navbar";
import { PaymentFlow } from "../../common/components/Modals/PaymentModal/interfaces";
import PaymentModal from "../../common/components/Modals/PaymentModal/PaymentModal";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../config/config";
import { Elements } from "@stripe/react-stripe-js";
import PaymentConfirmationModal from "../../common/components/Modals/PaymentConfirmationModal/PaymentConfirmation";
import { submitPlots } from "../PlotAnalysis/PlotAnalysis.service";
import { addNewNotification } from "../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../common/components/Notification/Notification.types";
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
import i18n from "i18next";
import { cleanPlotsList, getPaymentIntent } from "./PlotSearch.reducers";
import { useEffect } from "react";
import NoSelectedPlotsImg from "../../assets/images/noSelectedPlots.svg";

const SelectedPlotItem = ({ externalId, address }: SelectedPlotItemProps) => {
  return (
    <div className="plot-list-item">
      <img src={MarkerIcon} alt="ikona pinezki" />
      <div className="inner">
        <p>{address}</p>
        <p>{externalId}</p>
      </div>
    </div>
  );
};

const PlotSearch = () => {
  const { selectedPlots, selectedPlotLoading } = useAppSelector(
    (state) => state.plotSearch
  );
  const { profile } = useAppSelector((state) => state.auth);
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
  const { showPaymentModal } = useAppSelector(
    (state) => state.paymentModal.payment
  );
  const { appLang } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const admin = profile?.groups?.includes("admin");

  const generateRaport = async () => {
    const plotsIds = selectedPlots.map((plot) => plot.externalId);
    if (admin) {
      try {
        await submitPlots(plotsIds);
        dispatch(
          addNewNotification({
            text: i18n.t("plotAnalysisPage.notification"),
            variant: NotificationVariant.SUCCESS,
          })
        );
        navigate("/reports");
        dispatch(cleanPlotsList());
      } catch (eror) {
        dispatch(
          addNewNotification({
            text: i18n.t("common.error"),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    } else {
      dispatch(getPaymentIntent(plotsIds));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(cleanPlotsList());
    };
  }, []);

  let flow;

  if (isAuthenticated === true) {
    flow = PaymentFlow.reportUser;
  } else if (isAuthenticated === false) {
    flow = PaymentFlow.reportGuest;
  } else if (isAuthenticated === null) {
    return <></>;
  }

  return (
    <>
      <Navbar />
      <div className="plot-analysis-wrapper">
        <div className="plot-analysis">
          <div className="header">
            <h1>{t("plotAnalysisPage.heading")}</h1>
            <p>{t("plotAnalysisPage.lead")}</p>
          </div>
          <div className="inner">
            <PlotSearchMap />
            <div className="plots-wrapper">
              <div
                className={`plots-wrap ${
                  selectedPlots.length > 0 || selectedPlotLoading
                    ? "visible"
                    : ""
                }`}
              >
                {(selectedPlots.length > 0 || selectedPlotLoading) && (
                  <div className="selectedPlots">
                    <h1>{t("plotAnalysisPage.pickedAreas")}</h1>
                    <div className="plots-list">
                      {selectedPlots.map((item: PlotItem) => (
                        <SelectedPlotItem
                          externalId={item.externalId}
                          address={item.address}
                          key={item.plotId}
                        />
                      ))}
                      {selectedPlotLoading && (
                        <div className="plot-list-item">
                          <img
                            src={InProgressIcon}
                            alt="loading icon"
                            className="spinning-loader"
                          />
                          <div className="inner">
                            <p>{t("plotAnalysisPage.plotLoading")}</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <p className="cost">
                       {t("plotAnalysisPage.reportCost")}:{" "}
                      <span>{selectedPlots.length * 139}zł</span>
                    </p>
                  </div>
                )}
              </div>
              <div
                className={`no-plots-wrap ${
                  !selectedPlotLoading && selectedPlots.length === 0
                    ? "visible"
                    : ""
                }`}
              >
                {!selectedPlotLoading && selectedPlots.length === 0 && (
                  <div className="no-plots-selected">
                    <div>
                      <h1>{t("plotAnalysisPage.emptyState1")}</h1>
                      <h1>{t("plotAnalysisPage.emptyState2")}</h1>
                    </div>
                    <img src={NoSelectedPlotsImg} alt="no plots selected" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <YellowButton
            text={t("plotAnalysisPage.button")}
            onClick={() => generateRaport()}
            disabled={!selectedPlots.length}
          />
        </div>
        <Footer
          text={t("plotAnalysisPage.footer")}
          buttonIcon={ReportsListIcon}
          buttonText={t("common.reportsList")}
          onButtonClick={() => navigate("/reports")}
        />
        {showPaymentModal && (
          <PaymentModal
            flow={
              isAuthenticated ? PaymentFlow.reportUser : PaymentFlow.reportGuest
            }
          />
        )}
        <Elements
          options={{ appearance: { theme: "stripe" }, locale: appLang }}
          stripe={stripePromise}
        >
          <PaymentConfirmationModal flow={flow} />
        </Elements>
      </div>
    </>
  );
};

export default PlotSearch;
