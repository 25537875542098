/* eslint-disable indent */
import CreatedIcon from '../assets/icons/statuses/created.svg';
import InProgressIcon from '../assets/icons/statuses/inProgress.svg';
import FinishedIcon from '../assets/icons/statuses/finished.svg';
import ErrorIcon from '../assets/icons/statuses/error.svg';
import FatalIcon from '../assets/icons/statuses/fatal.svg';
import i18n from 'i18next';
import { LatLngExpression } from 'leaflet';

export const getReportsStatuses = () => [
	{
		label: i18n.t('helpers.reportStatuses.pending'),
		value: 'pending',
		icon: CreatedIcon,
	},
	{
		label: i18n.t('helpers.reportStatuses.in_progress'),
		value: 'in_progress',
		icon: InProgressIcon,
	},
	{
		label: i18n.t('helpers.reportStatuses.completed'),
		value: 'completed',
		icon: FinishedIcon,
	},
	{
		label: i18n.t('helpers.reportStatuses.incomplete'),
		value: 'incomplete',
		icon: ErrorIcon,
	},
	{
		label: i18n.t('helpers.reportStatuses.error'),
		value: 'error',
		icon: FatalIcon,
	},
];

export function getIcon(status: string) {
	switch (status) {
		case 'pending':
			return CreatedIcon;
		case 'in_progress':
			return InProgressIcon;
		case 'completed':
			return FinishedIcon;
		case 'incomplete':
			return ErrorIcon;
		case 'error':
			return FatalIcon;
		default:
			return CreatedIcon;
	}
}

export function translate(message: string) {
  switch (message) {
    case "Invalid phone number format.":
      return i18n.t("helpers.translate.invalidPhone");
    case "Invalid code provided, please request a code again.":
      return i18n.t("helpers.translate.invalidCode");
    case "An account with the given email already exists.":
      return i18n.t("helpers.translate.emailAlreadyExists");
    case "ULDK API ERROR: Plot ID is not valid":
      return i18n.t("helpers.translate.plotNotValid");
    case "Entity already exists!":
      return i18n.t("helpers.translate.entityAlreadyExists");
    case "Invalid verification code provided, please try again.":
      return i18n.t("helpers.translate.invalidCodeProvided");
    case "Attempt limit exceeded, please try after some time.":
      return i18n.t("helpers.translate.attemptLimitExceeded");
    case "Password does not conform to policy: Password not long enough":
      return i18n.t("helpers.translate.tooShortPassword");
    case "Incorrect username or password.":
      return i18n.t("helpers.translate.incorrectCredentials");
    case "Incorrect password.":
      return i18n.t("helpers.translate.incorrectPassword");
    case "Password attempts exceeded":
      return i18n.t("helpers.translate.attemptsExceeded");
    case "User is disabled.":
      return i18n.t("helpers.translate.accountDisabled");
    default:
      return i18n.t("helpers.translate.default");
  }
}

export const switchLatLng = (arr: any[]) =>
  arr.map((item: LatLngExpression[]) => [item[1], item[0]]);
