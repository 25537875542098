import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { fetchReport, fetchSharedReport } from "../Report.service";
import i18n from "i18next";

type ReportState = {
  report: any;
  error: boolean;
  loading: boolean;
  payment: {
    showPaymentModal: boolean;
  };
  multiplot: boolean | null;
  external_ids: string[];
};

const initialState: ReportState = {
  report: { plot_analysis: {}, external_ids: [] },
  error: false,
  loading: true,
  payment: {
    showPaymentModal: false,
  },
  multiplot: null,
  external_ids: [],
};

export const getReport = createAsyncThunk<
  ReportState,
  { id: any; isShared?: boolean }
>("report", async ({ id, isShared }, { dispatch, rejectWithValue }) => {
  try {
    if (isShared) {
      return await fetchSharedReport(id);
    } else {
      return await fetchReport(id);
    }
  } catch (error) {
    dispatch(
      addNewNotification({
        text: i18n.t("reducersNotifications.report"),
        variant: NotificationVariant.ERROR,
      })
    );
    if (error instanceof Error) {
      return rejectWithValue({ error: error.message });
    }
  }
});

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    updateReportName: (state, action) => {
      const { payload } = action;
      state.report.task.name = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.report = action.payload;
      state.error = false;
      state.loading = false;
      state.multiplot = action.payload.external_ids.length > 1 ? true : false;
    });
    builder.addCase(getReport.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export const { updateReportName } =
  reportSlice.actions;
