import { useState, useEffect } from "react";
import "./ScrollTopButton.scss";
import ArrowIcon from "../../../assets/icons/yellow-arrow.svg";
import ButtonBorder from "../../../assets/icons/inactiveStep.svg";
import { animateScroll } from "react-scroll";

const ScrollTopButton = () => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const updateIsShown = () => {
      setIsShown(window.scrollY > 600);
    };

    updateIsShown();

    window.addEventListener("scroll", updateIsShown);
    return () => {
      window.removeEventListener("scroll", updateIsShown);
    };
  }, [isShown]);

  return (
    <button
      className={`scroll-top-button${isShown ? " is-shown" : ""}`}
      onClick={() => animateScroll.scrollToTop()}
    >
      <img className="scroll-top-button-arrow" src={ArrowIcon} />
      <img className="scroll-top-button-border" src={ButtonBorder} />
    </button>
  );
};

export default ScrollTopButton;
