import "./YellowButton.styles.scss";

interface YellowButton {
  text: string;
  variant?: string;
  onClick?: any;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
}

const YellowButton = ({
  text,
  variant,
  onClick,
  disabled,
  type = "submit",
}: YellowButton) => {
  return (
    <button
      className={`yellow-button ${variant ? variant : ""}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default YellowButton;
