import { useEffect, useState } from "react";
import "./Notification.scss";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import ErrorAlertIcon from "../../../assets/icons/warningAlert.svg";
import SuccessAlertIcon from "../../../assets/icons/successAlert.svg";
import InfoAlertIcon from "../../../assets/icons/statuses/finished.svg";
import WhiteButton from "../WhiteButton/WhiteButton";
import { useAppSelector } from "../../../redux/hooks";
import "react-toastify/dist/ReactToastify.css";
import { MessageProps } from "./Notification.types";
import { Trans } from "react-i18next";

const Message = ({
  notification: { variant, text, textWithLinks, coloredText, button },
}: MessageProps) => {
  const navigate = useNavigate();

  const handleRedirectButton = (path: string) => {
    navigate(path);
  };

  return (
    <Box className="notification-wrap">
      <Box className="notification-body">
        <div className="notification-content">
          <img
            src={
              variant === "error"
                ? ErrorAlertIcon
                : variant === "info"
                ? InfoAlertIcon
                : SuccessAlertIcon
            }
            alt="notification icon"
          />
          <Box className="notification-text">
            <p>
              {textWithLinks && (
                <Trans
                  i18nKey={textWithLinks.i18nKey}
                  components={textWithLinks.links.map((link) => (
                    <Link to={link} onClick={() => toast.dismiss()} />
                  ))}
                />
              )}
              {text}
              <span className={variant}> {coloredText ? coloredText : ""}</span>
            </p>
          </Box>
        </div>
        {button && (
          <WhiteButton
            text="Zobacz"
            onClick={() => handleRedirectButton("/")}
          />
        )}
      </Box>
    </Box>
  );
};

const Notification = () => {
  const { latestNotification } = useAppSelector((state) => state.notifications);
  const [currentNotificationId, setCurrentNotificationId] = useState<number>(1);

  useEffect(() => {
    if (latestNotification) {
      toast(<Message notification={latestNotification} />, {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        closeButton: true,
        toastId: currentNotificationId,
        className: `alert-style ${latestNotification.variant}`,
      });
    }

    if (currentNotificationId > 2) {
      // remove old toasts if more then 2 are on screen
      setTimeout(() => {
        toast.dismiss(currentNotificationId - 2);
      }, 500);
    }
    setCurrentNotificationId(currentNotificationId + 1);
  }, [latestNotification]);

  return null;
};

export default Notification;
