import React from "react";
import "./SectionSubtitle.scss";

interface SectionTitleProps {
  title: string;
  icon: any;
}

function Subtitle({ title, icon }: SectionTitleProps) {
  return (
    <div className="sub-title">
      <img src={icon} alt={title} />
      <p>{title}</p>
    </div>
  );
}
export default Subtitle;
