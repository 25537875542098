import "./Profile.styles.scss";
import Sidebar from "./components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const Profile = () => {
  return (
    <div className="profile">
      <Sidebar />
      <div className="profile-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;
