import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { fetchClientSecret } from "../../../../../lib/Report/Report.service";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { LinearProgress } from "@mui/material";
import {
  STRIPE_KW_PRODUCT_ID,
  STRIPE_PLOT_PRODUCT_ID,
  STRIPE_PUBLIC_KEY,
} from "../../../../../config/config";
import { PaymentFlow, PaymentProps } from "../interfaces";
import { addNewNotification } from "../../../Notification/Notification.reducers";
import { useTranslation } from "react-i18next";
import { NotificationVariant } from "../../../Notification/Notification.types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Payment = ({
  activeStep,
  setActiveStep,
  invoiceFor,
  flow,
}: PaymentProps) => {
	const { appLang } = useAppSelector((state) => state.auth);
  const { paymentIntent } = useAppSelector((state) => state.plotSearch);
  const { profile } = useAppSelector((state) => state.auth);
  const [clientSecret, setClientSecret] = useState(null);
  const [amount, setAmount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let stripeProductId;
        if (flow === PaymentFlow.kw) {
          stripeProductId = STRIPE_KW_PRODUCT_ID;
        } else {
          stripeProductId = STRIPE_PLOT_PRODUCT_ID;
        }
        let customerDetails;
        if (invoiceFor === "np") {
          customerDetails = {
            email: profile?.invoice?.np?.email,
            firstName: profile?.invoice?.np?.firstName,
            lastName: profile?.invoice?.np?.lastName,
            street: profile?.invoice?.np?.street,
            houseNr: profile?.invoice?.np?.houseNr,
            apartmentNr: profile?.invoice?.np?.apartmentNr || '',
            postCode: profile?.invoice?.np?.postCode,
            city: profile?.invoice?.np?.city,
          };
        } else {
          customerDetails = {
            email: profile?.invoice?.b?.email,
            firstName: "guestFirstName",
            lastName: "guestLastName",
            companyName: profile?.invoice?.b?.companyName,
            nip: profile?.invoice?.b?.nip,
						street: profile?.invoice?.b?.street,
						houseNr: profile?.invoice?.b?.houseNr,
						apartmentNr: profile?.invoice?.b?.apartmentNr || '',
						postCode: profile?.invoice?.b?.postCode,
						city:profile?.invoice?.b?.city,
          };
        }
				
        const response = await fetchClientSecret(
          stripeProductId,
          invoiceFor,
          paymentIntent,
          customerDetails
        );
        setClientSecret(response.client_secret);
        setAmount(response.amount.amount / 100);
      } catch (e) {
        setActiveStep(activeStep - 1);
        dispatch(
          addNewNotification({
            text: t("paymentModal.paymentError"),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    };
    fetchData();
  }, [dispatch, t, activeStep, setActiveStep, invoiceFor, flow]);

  return (
    <>
      {clientSecret ? (
        <Elements
          options={{
            appearance: { theme: "stripe" },
            locale: appLang,
            clientSecret,
          }}
          stripe={stripePromise}
        >
          <PaymentForm amount={amount} />
        </Elements>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default Payment;
