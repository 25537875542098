import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import { fetchReportsList } from "../Report.service";
import i18n from "i18next";

type ReportQueryParams = {
  limit: string;
  ordering: string;
  index?: string;
  name?: string;
  status?: string;
};

type ReportsListState = {
  total: number;
  items: any;
  error: boolean;
  loading: boolean;
  availableReportsCount: number;
};

const initialState: ReportsListState = {
  total: 0,
  items: [],
  error: false,
  loading: true,
  availableReportsCount: 0,
};

export const getReportsList = createAsyncThunk<
  ReportsListState,
  { searchQuery: string; statusFilter: string; page: number }
>(
  "reportsList",
  async (
    { searchQuery, statusFilter, page },
    { dispatch, rejectWithValue }
  ) => {
    const params: ReportQueryParams = {
      limit: "10",
      ordering: "-created_at",
    };

    if (page) {
      params["index"] = ((page - 1) * 10).toString();
    }

    if (searchQuery.length > 0) {
      params["name"] = searchQuery;
    }

    if (statusFilter.length > 0 && statusFilter !== "all") {
      params["status"] = statusFilter;
    }

    const query = new URLSearchParams(params).toString();
    try {
      return await fetchReportsList(query);
    } catch (error) {
      dispatch(
        addNewNotification({
          text: i18n.t("reducersNotifications.report"),
          variant: NotificationVariant.ERROR,
        })
      );
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);

export const reportsListSlice = createSlice({
  name: "reportsList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      const { payload } = action;
      const item = state.items.find(
        (item: any) => item.id === action.payload.id
      );
      if (!item) {
        return;
      }
      item.status = payload.status;
    },
    setAsPaid: (state, action) => {
      const { payload } = action;
      const item = state.items.find((item: any) => item.plot.id === payload.id);
      if (!item) {
        return;
      }
      item.plot.is_paid = true;
    },
    updateAvailableReportsCount: (state, action) => {
      const { payload } = action;
      state.availableReportsCount = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportsList.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.total = action.payload.total;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(getReportsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReportsList.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export const {
  updateStatus,
  setAsPaid,
  updateAvailableReportsCount,
} = reportsListSlice.actions;
