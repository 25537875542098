import { useEffect, useState } from "react";
import "./PaymentConfirmation.scss";
import ModalContainer from "../ModalContainer/ModalContainer";
import ReportsListIcon from "../../../../assets/icons/menuReportsList.svg";
import InProgressIcon from "../../../../assets/icons/statuses/inProgress.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import WhiteButton from "../../WhiteButton/WhiteButton";
import { useStripe } from "@stripe/react-stripe-js";
import { Trans, useTranslation } from "react-i18next";
import {
  PaymentConfirmationProps,
  PaymentErrorProps,
  PaymentFlow,
  PaymentStatusMessageProps,
} from "../PaymentModal/interfaces";
import YellowButton from "../../YellowButton/YellowButton";

const PaymentProccessingMessage = ({
  closeModal,
}: PaymentStatusMessageProps) => {
  const { t } = useTranslation();
  return (
    <div className="payment-inprogress-message">
      <p className="title">
        {t("paymentStatusModal.proccessing.paymentProccessing")}
      </p>
      <img src={InProgressIcon} alt="ikona" className="payment-status-icon" />
      <YellowButton text={t("common.back")} onClick={closeModal} />
    </div>
  );
};

const PaymentSuccessMessage = ({
  closeModal,
  flow,
}: PaymentStatusMessageProps) => {
  const { t } = useTranslation();
  return (
    <div className="payment-success-message">
      <p className="title">{t("paymentStatusModal.success.congratulations")}</p>
      <div>
        <p>{t("paymentStatusModal.success.paymentSuccessful")}</p>
        {(flow === PaymentFlow.reportUser ||
          flow === PaymentFlow.reportGuest) && (
          <>
            <p>{t("paymentStatusModal.success.analysisStart")}</p>
          </>
        )}
      </div>
      {flow === PaymentFlow.reportGuest && (
        <p className="bold">{t("paymentStatusModal.success.email")}</p>
      )}
      {flow === PaymentFlow.reportUser ? (
        <WhiteButton
          text={t("common.reportsList")}
          icon={ReportsListIcon}
          onClick={closeModal}
        />
      ) : (
        <YellowButton text={t("common.back")} onClick={closeModal} />
      )}
    </div>
  );
};

const PaymentErrorMessage = ({ flow, amount, date }: PaymentErrorProps) => {
  const transactionDate = new Date(date * 1000);
  const readableDate = transactionDate.toLocaleString();
  const { t } = useTranslation();

  return (
    <div className="payment-error-message">
      <p>
        <Trans
          i18nKey="paymentStatusModal.error.text1"
          values={{
            amount: amount,
            entity:
              flow === PaymentFlow.kw
                ? t("paymentStatusModal.error.kw")
                : t("paymentStatusModal.error.report"),
          }}
        />
      </p>
      <p>
        <Trans i18nKey="paymentStatusModal.error.text2" />
      </p>
      <p>
        {t("paymentStatusModal.error.date")}
        {readableDate}
      </p>
      <p>
        <Trans i18nKey="paymentStatusModal.error.text3" components={[<br />]} />
      </p>
      <p>
        <Trans i18nKey="paymentStatusModal.error.text4" components={[<br />]} />
      </p>
    </div>
  );
};

const PaymentConfirmationModal = ({ flow }: PaymentConfirmationProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const stripe = useStripe();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showProccessingMessage, setShowProccessingMessage] =
    useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [date, setDate] = useState<number>(0);

  const closeModal = () => {
    setShowConfirmationModal(false);
    setSearchParams({});
    if (flow === PaymentFlow.reportUser && showSuccessMessage) {
      navigate(`/reports`);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    async function checkStatus() {
      if (!stripe) {
        return;
      }

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = (await stripe.retrievePaymentIntent(
        clientSecret
      )) as any;

      const isSuccess = () => {
        setShowSuccessMessage(true);
      };
      setShowConfirmationModal(true);
      switch (paymentIntent?.status) {
        case "succeeded":
          isSuccess();
          break;
        case "processing":
          setShowProccessingMessage(true);
          break;
        case "requires_payment_method":
          setAmount(paymentIntent?.amount / 100);
          setDate(paymentIntent?.created);
          setShowErrorMessage(true);
          break;
        default:
          setShowErrorMessage(true);
          break;
      }
    }
    checkStatus();
  }, [stripe]);

  if (!showConfirmationModal) {
    return <></>;
  }

  return (
    <ModalContainer
      variant="payment-modal payment-confirmation"
      onClose={closeModal}
    >
      {showProccessingMessage && (
        <PaymentProccessingMessage closeModal={closeModal} />
      )}
      {showErrorMessage && (
        <PaymentErrorMessage flow={flow} amount={amount} date={date} />
      )}
      {showSuccessMessage && (
        <PaymentSuccessMessage closeModal={closeModal} flow={flow} />
      )}
    </ModalContainer>
  );
};

export default PaymentConfirmationModal;
