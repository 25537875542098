/* eslint-disable no-undef */
import { useState } from "react";
import "./ReportSidebar.scss";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Link } from "react-scroll";
import RefreshIcon from "../../../../../assets/icons/sidebar/refresh.svg";
import InProgressIcon from "../../../../../assets/icons/statuses/inProgress.svg";
import DownloadIcon from "../../../../../assets/icons/download.svg";
import ShareIcon from "../../../../../assets/icons/sidebar/share.svg";
import CopyIcon from "../../../../../assets/icons/sidebar/copy.svg";
import PencilIcon from "../../../../../assets/icons/pencil.svg";
import BasicInfoIcon from "../../../../../assets/icons/sidebar/basicInfo.svg";
import ElectricityParamsIcon from "../../../../../assets/icons/sidebar/electricityParams.svg";
import RegisterNrIcon from "../../../../../assets/icons/sidebar/registerNr.svg";
import AreaMapIcon from "../../../../../assets/icons/sidebar/areaMap.svg";
import ReportsListIcon from "../../../../../assets/icons/menuReportsList.svg";
import SidebarWrapper from "../../../../../common/components/SidebarWrapper/SidebarWrapper";
import WhiteButton from "../../../../../common/components/WhiteButton/WhiteButton";
import { useWindowSize } from "../../../../../common/hooks";
import { addNewNotification } from "../../../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../../../common/components/Notification/Notification.types";
import RefreshModal from "../../../../../common/components/Modals/RefreshModal/RefreshModal";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import RenameModal from "../../../../../common/components/Modals/RenameModal/RenameModal";
import { fetchReportToken, generatePDF } from "../../../Report.service";
import { getReport } from "../../Report.reducers";
import { API_URL, APP_URL } from "../../../../../config/config";
import { Button } from "@mui/material";
import PlotExtrenalIds from "../../../../../common/components/PlotExternalIds/PlotExtrenalIds";
import { http } from "../../../../../config/http";

interface SidebarInterface {
  isShared?: boolean;
}

const getNextRefreshData = (date: string) => {
  const today = new Date();
  const nextRefreshDate = new Date(date);

  const differenceInDays = (
    (nextRefreshDate.getTime() - today.getTime()) /
    (1000 * 3600 * 24)
  ).toFixed(0);

  return { canBeRefreshed: nextRefreshDate < today, differenceInDays };
};

function ReportSidebar({ isShared }: SidebarInterface) {
  const { multiplot }: { multiplot: boolean | null } = useAppSelector(
    (state) => state.report
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tabs = [
    {
      section: "basicInfo",
      icon: BasicInfoIcon,
      title: t("report.basicInfo.title"),
    },
    {
      section: "electricityParams",
      icon: ElectricityParamsIcon,
      title: t("report.electricityParams.title"),
    },
    {
      section: "registerNr",
      icon: RegisterNrIcon,
      title: t("reportSidebar.registerNr"),
    },
    { section: "areaMap", icon: AreaMapIcon, title: t("report.areaMap.title") },
  ];

  const {
    report: { task, external_ids },
  }: any = useAppSelector((state) => state.report);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width !== 0 && windowSize.width < 769;
  const [openSidebar, setOpenSidebar] = useState<boolean>(!isMobile);
  const [refreshModal, setRefreshModal] = useState<boolean>(false);
  const [renameModal, setRenameModal] = useState<boolean>(false);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isReportShared = task?.shared?.token;
  const { id } = useParams();

  const revalidateReport = async () => {
    await dispatch(getReport({ id }));
  };

  const shareReport = async () => {
    try {
      await fetchReportToken(task.id, 360);
      await revalidateReport();
    } catch (e) {
      dispatch(
        addNewNotification({
          variant: NotificationVariant.ERROR,
          text: t("common.error"),
        })
      );
    }
  };

  const copyLink = () => {
    const reportLink = `${APP_URL}/share/${task?.shared?.token}`;
    navigator.clipboard.writeText(reportLink);
    dispatch(
      addNewNotification({
        text: t("common.copied"),
        variant: NotificationVariant.INFO,
      })
    );
  };

  const nextRefreshData = getNextRefreshData(
    task?.next_free_regenerate_timestamp
  );

  const downloadReport = async () => {
    let url = "";
    const token = task?.shared?.token;

    if (token) {
      setPdfLoading(true);
      const response = await http.get(
        `${API_URL}plot-analysis/multiplots/${task?.id}/pdf`
      );
      setPdfLoading(false);
      url = `${API_URL}plot-analysis/${response?.data?.path}`;
    } else {
      try {
        setPdfLoading(true);
        const pdf = await generatePDF(id);
        setPdfLoading(false);
        url = `${API_URL}plot-analysis/${pdf?.path}`;
      } catch (error) {
        setPdfLoading(false);
        dispatch(
          addNewNotification({
            variant: NotificationVariant.ERROR,
            text: t("common.error"),
          })
        );
      }
    }
    setPdfLoading(true);
    const response = await http.get(url, { responseType: "blob" });
    setPdfLoading(false);
    const blob = response.data;
    const urlObject = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = urlObject;
    link.download = `Raport Sunmetric ${task?.name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {refreshModal && <RefreshModal onClose={() => setRefreshModal(false)} />}
      {renameModal && <RenameModal onClose={() => setRenameModal(false)} />}
      <SidebarWrapper
        setOpen={setOpenSidebar}
        open={openSidebar}
        isMobile={isMobile}
      >
        <div
          className={`report-sidebar${
            isShared ? " report-sidebar-shared" : ""
          }`}
        >
          <div className="report-sidebar-inner">
            {task?.name && external_ids[0] !== task?.name && (
              <p className="report-name">{task?.name}</p>
            )}
            <div className={`plot-ids ${multiplot ? "multi" : ""}`}>
              <p className="title">
                {multiplot
                  ? t("reportSidebar.plotIds")
                  : t("reportSidebar.plotId")}
                :
              </p>
              {multiplot ? (
                <PlotExtrenalIds
                  plotIds={external_ids}
                  variant="sidebar-plot-ids"
                />
              ) : (
                <p>{external_ids[0]}</p>
              )}
            </div>
            {!isShared && (
              <div className="report-actions">
                {openSidebar || isMobile ? (
                  <>
                    <WhiteButton
                      text={t("reportSidebar.reportNameBtn")}
                      icon={PencilIcon}
                      variant="slim"
                      onClick={() => setRenameModal(true)}
                    />
                    {task?.status === "completed" &&
                      (nextRefreshData.canBeRefreshed ? (
                        <WhiteButton
                          text={t("reportSidebar.refreshReportBtn")}
                          animatedIcon="refresh"
                          variant="slim"
                          onClick={() => setRefreshModal(true)}
                        />
                      ) : (
                        <WhiteButton
                          text={
                            <Trans
                              i18nKey="reportSidebar.nextRefresh"
                              values={{
                                days: nextRefreshData.differenceInDays,
                              }}
                            />
                          }
                          variant="slim centered disabled"
                        />
                      ))}
                    <WhiteButton
                      onClick={isReportShared ? copyLink : shareReport}
                      text={
                        isReportShared
                          ? t("reportSidebar.reportShared")
                          : t("reportSidebar.shareBtn")
                      }
                      icon={isReportShared ? CopyIcon : ShareIcon}
                      variant="slim"
                    />
                    <WhiteButton
                      onClick={() => downloadReport()}
                      text={t("reportSidebar.downloadBtn")}
                      icon={pdfLoading ? InProgressIcon : DownloadIcon}
                      variant={`slim ${pdfLoading ? "btn-icon-spin" : ""}`}
                    />
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setRenameModal(true)}
                      className="report-icon-button"
                    >
                      <img src={PencilIcon} />
                    </button>
                    <button
                      onClick={() => setRefreshModal(true)}
                      className="report-icon-button"
                      disabled={!nextRefreshData.canBeRefreshed}
                    >
                      <img src={RefreshIcon} />
                    </button>
                    <button
                      onClick={isReportShared ? copyLink : shareReport}
                      className="report-icon-button"
                    >
                      <img src={ShareIcon} />
                    </button>
                    <button
                      onClick={() => downloadReport()}
                      className="report-icon-button"
                    >
                      <img src={DownloadIcon} />
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="tabs">
            {tabs.map((tab) => (
              <Link
                key={`${tab.section}`}
                activeClass="active"
                to={`${tab.section}`}
                spy={true}
                smooth={true}
                onClick={() => {
                  isMobile && setOpenSidebar(false);
                }}
                offset={isMobile ? 110 * -1 : 0}
              >
                <Button>
                  <img src={tab.icon} />{" "}
                  <span className="tab-title">{tab.title}</span>
                </Button>
              </Link>
            ))}
          </div>
          <div className="report-sidebar-inner">
            {!isShared &&
              (openSidebar || isMobile ? (
                <WhiteButton
                  text={t("common.reportsList")}
                  icon={ReportsListIcon}
                  variant="big-centered"
                  onClick={() => navigate("/reports")}
                />
              ) : (
                <button
                  onClick={() => navigate("/reports")}
                  className="report-icon-button"
                >
                  <img
                    src={ReportsListIcon}
                    onClick={() => navigate("/reports")}
                  />
                </button>
              ))}
          </div>
        </div>
      </SidebarWrapper>
    </>
  );
}

export default ReportSidebar;
