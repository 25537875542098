import { Formik } from "formik";
import * as Yup from "yup";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import "./InvoiceData.scss";
import YellowButton from "../../../../common/components/YellowButton/YellowButton";
import YellowFrame from "../../../../common/components/YellowFrame/YellowFrame";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Auth } from "@aws-amplify/auth";
import { initUser, saveGuestInvoiceData } from "../../../Auth/Auth.reducers";
import { addNewNotification } from "../../../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../../../common/components/Notification/Notification.types";
import { translate } from "../../../../common/helpers";
import { useTranslation } from "react-i18next";
import {
  BillingDataViews,
  PaymentFlow,
} from "../../../../common/components/Modals/PaymentModal/interfaces";

interface FormValuesProps {
  invoiceFirstName: string;
  invoiceLastName: string;
  invoiceEmail: string;
  invoiceStreet: string;
  invoiceHouseNr: string;
  invoiceApartmentNr: string;
  invoicePostCode: string;
  invoiceCity: string;
}

interface NaturalPersonFormProps {
  flow?: PaymentFlow;
  setCurrentView?: React.Dispatch<React.SetStateAction<BillingDataViews>>;
}

const NaturalPersonForm = ({
  flow,
  setCurrentView,
}: NaturalPersonFormProps) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const submitForm = async (form: FormValuesProps) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:inv:np:first_name": form.invoiceFirstName,
        "custom:inv:np:last_name": form.invoiceLastName,
        "custom:inv:np:email": form.invoiceEmail,
        "custom:inv:np:street": form.invoiceStreet,
        "custom:inv:np:house_nr": form.invoiceHouseNr,
        "custom:inv:np:apartment_nr": form.invoiceApartmentNr,
        "custom:inv:np:post_code": form.invoicePostCode,
        "custom:inv:np:city": form.invoiceCity,
      });
      dispatch(initUser());
      dispatch(
        addNewNotification({
          text: t("common.changesSaved"),
          variant: NotificationVariant.SUCCESS,
        })
      );
      setCurrentView && setCurrentView(BillingDataViews.confirmData);
    } catch (e) {
      if (e instanceof Error) {
        console.log('e', e);
        dispatch(addNewNotification({ text: translate(e.message), variant: NotificationVariant.ERROR }));
      }
    }
  };

  const submitFormAsGuest = async (form: FormValuesProps) => {
      dispatch(
        saveGuestInvoiceData({
          data: {
            firstName: form.invoiceFirstName,
            lastName: form.invoiceLastName,
            email: form.invoiceEmail,
            street: form.invoiceStreet,
            houseNr: form.invoiceHouseNr,
            apartmentNr: form.invoiceApartmentNr,
            postCode: form.invoicePostCode,
            city: form.invoiceCity,
          },
          invoiceFor: "np",
        })
      );
  };

  return (
    <Formik<FormValuesProps>
      initialValues={{
        invoiceFirstName: profile?.invoice?.np?.firstName || "",
        invoiceLastName: profile?.invoice?.np?.lastName || "",
        invoiceEmail: profile?.invoice?.np?.email || "",
        invoiceStreet: profile?.invoice?.np?.street || "",
        invoiceHouseNr: profile?.invoice?.np?.houseNr || "",
        invoiceApartmentNr: profile?.invoice?.np?.apartmentNr || "",
        invoicePostCode: profile?.invoice?.np?.postCode || "",
        invoiceCity: profile?.invoice?.np?.city || "",
      }}
      validationSchema={Yup.object({
        invoiceFirstName: Yup.string().required(t("validation.fieldRequired")),
        invoiceLastName: Yup.string().required(t("validation.fieldRequired")),
        invoiceEmail: Yup.string()
          .required(t("validation.fieldRequired"))
          .email(t("validation.email")),
        invoiceStreet: Yup.string().required(t("validation.fieldRequired")),
        invoiceHouseNr: Yup.string().required(t("validation.fieldRequired")),
        invoiceApartmentNr: Yup.string(),
        invoicePostCode: Yup.string().required(t("validation.fieldRequired")),
        invoiceCity: Yup.string().required(t("validation.fieldRequired")),
      })}
      onSubmit={async (values) => {
        flow === PaymentFlow.reportGuest
          ? await submitFormAsGuest(values)
          : await submitForm(values);
      }}
      component={(props) => <Form {...props} flow={flow} />}
    />
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
	flow,
}) => {
  const { t } = useTranslation();

  return (
    <div className="form-wrapper">
      <YellowFrame>
        <form onSubmit={handleSubmit} className="form">
          <div className="grid-wrapper">
            <div>
              <p className="label">{t("userData.name")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceFirstName && touched.invoiceFirstName}
                helperText={
                  touched.invoiceFirstName ? errors.invoiceFirstName : null
                }
                hiddenLabel
                name="invoiceFirstName"
                value={values.invoiceFirstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <p className="label">{t("userData.surname")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceLastName && touched.invoiceLastName}
                helperText={
                  touched.invoiceLastName ? errors.invoiceLastName : null
                }
                hiddenLabel
                name="invoiceLastName"
                value={values.invoiceLastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="fullwidth">
            <p className="label">{t("userData.invoiceEmail")}</p>
            <TextField
              fullWidth
              error={!!errors.invoiceEmail && touched.invoiceEmail}
              helperText={touched.invoiceEmail ? errors.invoiceEmail : null}
              hiddenLabel
              name="invoiceEmail"
              value={values.invoiceEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="grid-wrapper">
            <div>
              <p className="label">{t("userData.street")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceStreet && touched.invoiceStreet}
                helperText={touched.invoiceStreet ? errors.invoiceStreet : null}
                hiddenLabel
                name="invoiceStreet"
                value={values.invoiceStreet}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="input-wrapper house-nr">
              <div>
                <p className="label">{t("userData.houseNr")}</p>
                <TextField
                  fullWidth
                  error={!!errors.invoiceHouseNr && touched.invoiceHouseNr}
                  helperText={
                    touched.invoiceHouseNr ? errors.invoiceHouseNr : null
                  }
                  hiddenLabel
                  name="invoiceHouseNr"
                  value={values.invoiceHouseNr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div>
                <p className="label">{t("userData.apartmentNr")}</p>
                <TextField
                  fullWidth
                  error={
                    !!errors.invoiceApartmentNr && touched.invoiceApartmentNr
                  }
                  helperText={
                    touched.invoiceApartmentNr
                      ? errors.invoiceApartmentNr
                      : null
                  }
                  hiddenLabel
                  name="invoiceApartmentNr"
                  value={values.invoiceApartmentNr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div>
              <p className="label">{t("userData.postCode")}</p>
              <TextField
                fullWidth
                error={!!errors.invoicePostCode && touched.invoicePostCode}
                helperText={
                  touched.invoicePostCode ? errors.invoicePostCode : null
                }
                hiddenLabel
                name="invoicePostCode"
                value={values.invoicePostCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <p className="label">{t("userData.city")}</p>
              <TextField
                fullWidth
                error={!!errors.invoiceCity && touched.invoiceCity}
                helperText={touched.invoiceCity ? errors.invoiceCity : null}
                hiddenLabel
                name="invoiceCity"
                value={values.invoiceCity}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {flow === PaymentFlow.reportGuest && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.dataProcessingAgreement}
                  className={
                    !!errors.dataProcessingAgreement &&
                    touched.dataProcessingAgreement &&
                    "error"
                  }
                  required
                />
              }
              label={
                <>
                  {t("signupFlow.registerPage.marketingAgreement")}
                  {!!errors.dataProcessingAgreement &&
                    touched.dataProcessingAgreement && (
                      <span className="errorMsg">
                        {t("validation.fieldRequired")}
                      </span>
                    )}
                </>
              }
              onChange={handleChange}
              name="dataProcessingAgreement"
            />
          )}
          <YellowButton text={t("common.save")} />
        </form>
      </YellowFrame>
    </div>
  );
};

export default NaturalPersonForm;
