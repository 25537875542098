import YellowFrame from "../../common/components/YellowFrame/YellowFrame";
import "./Welcome.styles.scss";
import PlotAnalysisIcon from "../../assets/icons/menuPlotAnalysis.svg";
import ReportsList from "../../assets/icons/menuReportsList.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SocialRegisterModal from "./components/SocialRegisterModal";

type Props = {
  socialRegister?: boolean;
};

const WelcomePage = ({ socialRegister }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="welcome-page-wrapper">
        <Link to="/plot-analysis">
          <YellowFrame hover>
            <p>{t("common.analysePlot")}</p>
            <img src={PlotAnalysisIcon} alt="ikona" />
          </YellowFrame>
        </Link>
        <Link to="/reports">
          <YellowFrame reverse hover>
            <p>{t("common.reportsList")}</p>
            <img src={ReportsList} alt="ikona" />
          </YellowFrame>
        </Link>
      </div>

      {socialRegister && <SocialRegisterModal />}
    </>
  );
};

export default WelcomePage;
