import { Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import "./PersonalData.scss";
import YellowButton from "../../../../common/components/YellowButton/YellowButton";
import YellowFrame from "../../../../common/components/YellowFrame/YellowFrame";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Auth } from "@aws-amplify/auth";
import { addNewNotification } from "../../../../common/components/Notification/Notification.reducers";
import { translate } from "../../../../common/helpers";
import { NotificationVariant } from "../../../../common/components/Notification/Notification.types";
import { initUser } from "../../../Auth/Auth.reducers";
import { FormValuesProps } from "../../Profile.types";
import { useTranslation } from "react-i18next";

const PersonalData = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const submitForm = async (form: FormValuesProps) => {
    const phoneNumber = form.phone.toString().replace(/\s/g, "");
    const formatPhoneNumber =
      phoneNumber.substring(0, 1) === "+" ? phoneNumber : `+48${phoneNumber}`;

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        given_name: form.firstName,
        family_name: form.lastName,
        phone_number: form.phone ? formatPhoneNumber : "",
      });
      dispatch(initUser());
      dispatch(
        addNewNotification({
          text: t("common.changesSaved"),
          variant: NotificationVariant.SUCCESS,
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          addNewNotification({
            text: translate(e.message),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  return (
    <div className="personal-data-wrapper">
      <div className="personal-data">
        <Formik<FormValuesProps>
          initialValues={{
            firstName: profile?.firstName || "",
            lastName: profile?.lastName || "",
            email: profile?.email || "",
            phone: profile?.phone || "",
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t("validation.fieldRequired")),
            lastName: Yup.string().required(t("validation.fieldRequired")),
            email: Yup.string()
              .required(t("validation.fieldRequired"))
              .email(t("validation.email")),
            phone: Yup.string()
              .min(9, t("validation.minPhone"))
              .max(23, t("validation.maxPhone")),
          })}
          onSubmit={async (values) => {
            await submitForm(values);
          }}
          component={(props) => <Form {...props} />}
        />
      </div>
    </div>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation();

  return (
    <div className="personal-form-wrapper">
      <YellowFrame>
        <form onSubmit={handleSubmit} className="personal-form">
          <div>
            <p className="label">{t("userData.name")}</p>
            <TextField
              fullWidth
              error={!!errors.firstName && touched.firstName}
              helperText={touched.firstName ? errors.firstName : null}
              hiddenLabel
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <p className="label">{t("userData.surname")}</p>
            <TextField
              fullWidth
              error={!!errors.lastName && touched.lastName}
              helperText={touched.lastName ? errors.lastName : null}
              hiddenLabel
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <p className="label">{t("userData.email")}</p>
          <TextField
            disabled
            fullWidth
            error={!!errors.email && touched.email}
            helperText={touched.email ? errors.email : null}
            hiddenLabel
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <p className="label">{t("userData.phone")}</p>
          <TextField
            fullWidth
            error={!!errors.phone && touched.phone}
            helperText={touched.phone ? errors.phone : null}
            hiddenLabel
            name="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <YellowButton text={t("common.save")} />
        </form>
      </YellowFrame>
    </div>
  );
};

export default PersonalData;
