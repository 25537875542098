import "./LangSelect.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FlagPL from "../../../../assets/images/flagPL.svg";
import FlagUK from "../../../../assets/images/flagUK.svg";
import TickIcon from "../../../../assets/images/TickSmall.svg";
import i18next from "i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { changeLang } from "../../../../lib/Auth/Auth.reducers";
import { Auth } from "aws-amplify";

const languages = [
  { label: "Polski", value: "pl", flagIcon: FlagPL },
  { label: "English", value: "en", flagIcon: FlagUK },
];

const LangSelect = () => {
  const { appLang } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleChange = async (value: string) => {
    dispatch(changeLang({ lang: value }));
    await i18next.changeLanguage(value);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:language": value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormControl size="small" className="lang-select">
      <Select
        value={appLang}
        onChange={(e) => handleChange(e.target.value)}
        renderValue={() => {
          return <p>{appLang.toUpperCase()}</p>;
        }}
      >
        {languages.map(({ value, label, flagIcon }) => (
          <MenuItem value={value} className="lang-select-menu" key={value}>
            <img width={30} src={flagIcon} />
            <p>{label}</p>
            {appLang === value && <img width={20} src={TickIcon} />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LangSelect;
