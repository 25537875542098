import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import "./ReportsList.styles.scss";
import PlotAnalysisIcon from "../../../assets/icons/menuPlotAnalysis.svg";
import NoReportsImg from "../../../assets/images/no-reports1.png";
import List from "./components/ReportsList/ReportsList";
import { getReportsList } from "./ReportsList.reducers";
import SearchBox from "../../../common/components/SearchBox/SearchBox";
import FilterByStatus from "../../../common/components/FilterByStatus/FilterByStatus";
import { useWebsockets } from "../../../common/hooks";
import WhiteButton from "../../../common/components/WhiteButton/WhiteButton";
import Footer from "../../../common/components/Footer/Footer";
import { useSearchParams } from "react-router-dom";
import PageLoader from "../../../common/components/PageLoader/PageLoader";
import { useTranslation } from "react-i18next";

const ReportsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [searchParams] = useSearchParams();
  const pageNr = searchParams.get("p");
  const [page, setPage] = useState<any>(pageNr || 1);
  const { t } = useTranslation();

  useWebsockets();
  const {
    items: reportsList,
    error,
    loading,
  } = useAppSelector((state) => state.reportsList);

  const defaultFilters =
    searchQuery.trim() === "" && statusFilter === "all" && page === 1;
  const noReports =
    reportsList?.length === 0 && !error && !loading && defaultFilters;
  const [reportsHasLoaded, setReportsHasLoaded] = useState(false);

  useEffect(() => {
    if (pageNr) {
      setPage(parseInt(pageNr));
    }
  }, [pageNr]);

  useEffect(() => {
    const fetchReportsList = async () => {
      await dispatch(
        getReportsList({
          searchQuery: searchQuery,
          statusFilter: statusFilter,
          page: page,
        })
      );
      await setReportsHasLoaded(true);
    };
    fetchReportsList();
  }, [searchQuery, statusFilter, page, dispatch]);

  const isLoading = loading && !reportsHasLoaded;

  return (
    <>
      <PageLoader open={isLoading} preloaded={reportsList?.length} />
      {reportsHasLoaded && (
        <div className="reports-list-view">
          <div className="reports-list-wrapper">
            <h1>{t("common.reportsList")}</h1>
            {noReports ? (
              <div className="no-reports-view">
                <img src={NoReportsImg} className="no-reports-view-img" />
                <h2>{t("reportsList.emptyState")}</h2>
                <WhiteButton
                  text={t("common.analysePlot")}
                  icon={PlotAnalysisIcon}
                  onClick={() => navigate("/plot-analysis")}
                  variant="slim"
                />
              </div>
            ) : (
              <>
                <div className="filters-wrapper">
                  <SearchBox
                    setSearchQuery={setSearchQuery}
                    setPage={setPage}
                  />
                  <FilterByStatus
                    setStatusFilter={setStatusFilter}
                    setPage={setPage}
                  />
                </div>
                <List page={page} setPage={setPage} />
              </>
            )}
          </div>

          {!noReports && (
            <Footer
              text={t("reportsList.footer")}
              buttonIcon={PlotAnalysisIcon}
              buttonText={t("reportsList.footerBtn")}
              onButtonClick={() => navigate("/plot-analysis")}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ReportsList;
