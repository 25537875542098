import { Auth } from "aws-amplify";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ModalContainer from "../../../common/components/Modals/ModalContainer/ModalContainer";
import WhiteButton from "../../../common/components/WhiteButton/WhiteButton";
import AgreementsBoxes from "../../Profile/components/AgreementsBoxes/AgreementsBoxes";
import "./SocialRegisterModal.scss";
import { useAppDispatch } from "../../../redux/hooks";
import { addNewNotification } from "../../../common/components/Notification/Notification.reducers";
import { translate } from "../../../common/helpers";
import { NotificationVariant } from "../../../common/components/Notification/Notification.types";
import plotAnalysisIcon from "../../../assets/icons/accept-icon.svg";
import { useNavigate } from "react-router-dom";
import { initUser } from "../../Auth/Auth.reducers";

interface FormValuesProps {
  tosAgreement: boolean;
  marketingAgreement: boolean;
}

const SocialRegisterModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const submitForm = async (form: FormValuesProps) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        ["custom:marketing_agreement"]: form.marketingAgreement.toString(),
        ["custom:tos_agreement"]: form.tosAgreement.toString(),
      });
      await dispatch(initUser());
      dispatch(
        addNewNotification({
          text: t("common.changesSaved"),
          variant: NotificationVariant.SUCCESS,
        })
      );
      navigate("/");
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          addNewNotification({
            text: translate(e.message),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    }
  };

  return (
    <ModalContainer
      variant="social-register-modal"
      onClose={() => navigate("/logout")}
    >
      <div className="social-register-modal-inner">
        <Formik<FormValuesProps>
          initialValues={{
            tosAgreement: false,
            marketingAgreement: false,
          }}
          validationSchema={Yup.object({
            tosAgreement: Yup.bool().oneOf(
              [true],
              t("validation.fieldRequired")
            ),
            marketingAgreement: Yup.bool(),
          })}
          onSubmit={async (values) => {
            await submitForm(values);
          }}
          component={(props) => <Form {...props} />}
        />
      </div>
    </ModalContainer>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <p className="text">{t("signupFlow.common.socialRegisterInfo")}</p>

      <AgreementsBoxes
        errors={errors}
        touched={touched}
        values={values}
        handleChange={handleChange}
      />

      <WhiteButton
        text={t("common.accept")}
        icon={plotAnalysisIcon}
        type="submit"
        variant="big-centered"
      />
    </form>
  );
};

export default SocialRegisterModal;
