import "./Navbar.scss";
import LogoLong from "../../../assets/images/sunmetricDark.svg";
import { Link } from "react-router-dom";
import Menu from "./components/Menu";
import LogOutIcon from "../../../assets/icons/sidebar/logout.svg";
import LangSelect from "./components/LangSelect";
import WhiteButton from "../WhiteButton/WhiteButton";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";

interface NavbarProps {
  profilePage?: boolean;
}

const Navbar = ({ profilePage }: NavbarProps) => {
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
  const { t } = useTranslation();

  return (
    <>
      <div className="main-navbar-offset"></div>
      <header className={`main-navbar${profilePage ? " profile-page" : ""}`}>
        <div className="main-navbar-inner">
          <div className="main-navbar-left">
            <Link to="/" className="main-navbar-logo">
              <img src={LogoLong} alt="logo" className="logo" />
            </Link>
            <LangSelect />
          </div>
          <Link to="/logout" className="main-navbar-logout">
            <img src={LogOutIcon} alt="icon" />
          </Link>
          {isAuthenticated === null ? null : isAuthenticated === true ? (
            <Menu />
          ) : (
            <Link to="/login?guest=true" className="main-navbar-login-guest">
              <WhiteButton text={t("common.signin")} variant="login" />
            </Link>
          )}
        </div>
      </header>
    </>
  );
};

export default Navbar;
