import "./WhiteButton.scss";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/sidebar/refresh.svg";
import { ReactComponent as PaymentIcon } from "../../../assets/icons/money.svg";
import { ReactComponent as ReadyIcon } from "../../../assets/icons/yellowTick.svg";

interface WhiteButton {
  text: React.ReactNode;
  icon?: any;
  variant?: string;
  onClick?: any;
  animatedIcon?: string;
  type?: "submit" | "reset" | "button";
  isReady?: boolean;
  disabled?: boolean;
}

const WhiteButton = ({
  text,
  icon,
  variant,
  onClick,
  animatedIcon,
  type,
  isReady,
  disabled,
}: WhiteButton) => {
  return (
    <button
      className={`white-button ${variant ? variant : ""}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon && <img src={icon} alt="ikona" />}
      {animatedIcon === "refresh" && <RefreshIcon />}
      {animatedIcon === "payment" && <PaymentIcon />}
      <p>{text}</p>
      {isReady && <ReadyIcon className="is-ready-icon" />}
    </button>
  );
};

export default WhiteButton;
