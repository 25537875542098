import "./NotLoggedInContainer.scss";
import LogoLong from "../../../assets/images/sunmetricDark.svg";
import LangSelect from "../Navbar/components/LangSelect";

interface NotLoggedInContainer {
  children: any;
  text?: string;
  variant?: string;
  title: string;
}

const NotLoggedInContainer = ({
  children,
  text,
  variant,
  title = "",
}: NotLoggedInContainer) => {
  return (
    <div className={`wrapper ${variant ? variant : ""}`}>
      <div className="container-img">
        <img src={LogoLong} />
        {title.length ? <p className="container-img-title">{title}</p> : ""}
        <div className="lang-select-wrapper">
          <LangSelect />
        </div>
      </div>
      <div className="container-children">
        <h1 className="container-title">{text}</h1>
        {children}
      </div>
    </div>
  );
};

export default NotLoggedInContainer;
