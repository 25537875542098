import { useTranslation } from "react-i18next";
import LogoLong from "../../../../../assets/images/sunmetricDark.svg";
import YellowButton from "../../../YellowButton/YellowButton";
import WhiteButton from "../../../WhiteButton/WhiteButton";
import "../PaymentModal";
import { Link } from "react-router-dom";
import { LoginProps } from "../interfaces";

const Login = ({ setActiveStep, activeStep }: LoginProps) => {
  const { t } = useTranslation();

  const continueAsGuest = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <div className="login">
      <img src={LogoLong} alt="logo" className="logo" />
      <Link to="/login?guest=true">
        <YellowButton text={t("common.signin")} />
      </Link>
      <p>{t("common.or")}</p>
      <WhiteButton text={t("signupFlow.guest")} onClick={continueAsGuest} />
    </div>
  );
};

export default Login;
