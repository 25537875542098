import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import "./Sidebar.scss";

interface INavItem {
  text: string;
  path: string;
  icon: string;
  onClick: VoidFunction;
}

export default function NavItem({
  text,
  path,
  icon,
  onClick,
}: INavItem): JSX.Element {
  const location = useLocation();

  const checkIfSelected = (path: string) => {
    return location.pathname === path;
  };

  return (
    <ListItem
      button
      className="nav-item"
      component={Link}
      to={path}
      selected={checkIfSelected(path)}
      onClick={onClick}
    >
      <ListItemIcon>
        <img src={icon} alt="nav icon" />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}
