import WhiteButton from "../../../WhiteButton/WhiteButton";
import UserIcon from "../../../../../assets/icons/sidebar/avatar-man.svg";
import BuildingIcon from "../../../../../assets/icons/building.svg";
import { useTranslation } from "react-i18next";
import { ChooseBusinessEntityProps } from "../interfaces";

const ChooseBusinessEntity = ({
  setActiveStep,
  setInvoiceFor,
  activeStep,
}: ChooseBusinessEntityProps) => {
  const { t } = useTranslation();

  const onCompanyClick = () => {
    setActiveStep(activeStep + 1);
    setInvoiceFor("b");
  };

  const onNaturalPersonClick = () => {
    setActiveStep(activeStep + 1);
    setInvoiceFor("np");
  };

  return (
      <div className="inner">
        <p className="title">{t("paymentModal.stepOneSubtitle")}</p>
        <div className="buttons-wrapper entity">
          <WhiteButton
            text={t("userData.company")}
            icon={BuildingIcon}
            onClick={onCompanyClick}
          />
          <WhiteButton
            text={t("userData.naturalPerson")}
            icon={UserIcon}
            onClick={onNaturalPersonClick}
          />
        </div>
      </div>
  );
};

export default ChooseBusinessEntity;
