import {
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit";
import { authSlice } from "../lib/Auth/Auth.reducers";
import { reportsListSlice } from "../lib/Report/ReportsList/ReportsList.reducers";
import { notificationsSlice } from "../../src/common/components/Notification/Notification.reducers";
import { reportSlice } from "../lib/Report/Report/Report.reducers";
import { paymentsSlice } from "../lib/Profile/Profile.reducers";
import { plotSearchSlice } from "../lib/PlotSearch/PlotSearch.reducers";
import { paymentModalSlice } from "../common/components/Modals/PaymentModal/Payment.reducers";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  report: reportSlice.reducer,
  notifications: notificationsSlice.reducer,
  reportsList: reportsListSlice.reducer,
  payments: paymentsSlice.reducer,
  plotSearch: plotSearchSlice.reducer,
	paymentModal: paymentModalSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
