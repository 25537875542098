import "./SwitchBtn.scss";
import { Grid, Switch } from "@mui/material";
import { useState } from "react";

const SwitchBtn = ({
  handleChange,
  leftLabel,
  rightLabel,
  defaultValue = 0,
}: any) => {
  const [value, setValue] = useState(defaultValue);

  const toggle = (e: any) => {
    handleChange(e);
    if (e.target.checked) {
      setValue(1);
    } else {
      setValue(0);
    }
  };

  return (
    <Grid container alignItems="center" spacing={1} className="switch-button">
      <Grid item className={!value ? "searchbox-bold" : ""}>
        {leftLabel}
      </Grid>
      <Grid item>
        <Switch
          onChange={(e) => toggle(e)}
          className="searchbox-switch"
          checked={!!value}
        />
      </Grid>
      <Grid item className={value ? "searchbox-bold" : ""}>
        {rightLabel}
      </Grid>
    </Grid>
  );
};

export default SwitchBtn;
