import React from "react";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import Navbar from "../Navbar/Navbar";
import ScrollTopButton from "../ScrollTopButton/ScrollTopButton";

type CustomProps = {
  sidebar?: boolean;
};

type Props = RouteProps & CustomProps;

export const PrivateRoute: React.FC<Props> = ({ sidebar }) => {
  const { pathname } = useLocation();
  const { authenticated, profile } = useAppSelector((state) => state.auth);
  const profilePage = pathname.includes("/profile");
  const socialRegisterPage = pathname.includes("/social-register");

  if (authenticated === null) {
    return <></>;
  }

  if (authenticated === false) {
    return <Navigate to="/login" />;
  }

  if (!profile?.tosAgreement && !socialRegisterPage) {
    return <Navigate to="/social-register" />;
  }

  return (
    <>
      {!sidebar ? <Navbar /> : profilePage && <Navbar profilePage />}
      <Outlet />
      <ScrollTopButton />
    </>
  );
};
