import "./ForgotPassword.scss";
import NotLoggedInContainer from "../../../common/components/NotLoggedInContainer/NotLoggedInContainer";
import YellowFrame from "../../../common/components/YellowFrame/YellowFrame";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import YellowButton from "../../../common/components/YellowButton/YellowButton";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { useTranslation } from "react-i18next";

interface FormValuesProps {
  email: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const submitForm = async (form: FormValuesProps) => {
    await Auth.forgotPassword(form.email);
    navigate(`/new-password?email=${form.email}`);
  };

  return (
    <NotLoggedInContainer
      text={t("signupFlow.forgotPassword.heading")}
      variant="forgot-password"
      title={t("common.logIn")}
    >
      <YellowFrame>
        <>
          <Formik<FormValuesProps>
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .required(t("validation.fieldRequired"))
                .email(t("validation.email")),
            })}
            onSubmit={async (values) => {
              await submitForm(values);
            }}
            component={(props) => <Form {...props} />}
          />
        </>
        <Link to="/login">{t("signupFlow.common.backToLogin")}</Link>
      </YellowFrame>
    </NotLoggedInContainer>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="forgot-password-form">
      <p className="helper-text">{t("signupFlow.forgotPassword.info")}</p>
      <p className="label">{t("userData.email")}</p>
      <TextField
        fullWidth
        error={!!errors.email && touched.email}
        helperText={touched.email ? errors.email : null}
        hiddenLabel
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <YellowButton text={t("common.send")} />
    </form>
  );
};

export default ForgotPassword;
