import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewNotification } from "../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../common/components/Notification/Notification.types";
import { http } from "../../config/http";
import { API_URL } from "../../config/config";
import i18n from "i18next";
import wellknown from "wellknown";
import { LatLngExpression } from "leaflet";
import { switchLatLng } from "../../common/helpers";
import { MapCentre, Plot, PlotItem, PlotsListState } from "./interfaces";
import { RootState } from "../../redux/store";
import { openPaymentModal } from "../../common/components/Modals/PaymentModal/Payment.reducers";

const initialState: PlotsListState = {
  plotsList: [],
  plotsListLoading: false,
  plotsListError: false,
  plotsListSuccess: false,
  selectedPlots: [],
  fetchedPlots: [],
  selectedPlotLoading: false,
  paymentIntent: null,
  paymentIntentLoading: false,
};

export const getPlotsList = createAsyncThunk(
  "plotsList",
  async ({ lat, lng }: MapCentre, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.get(
        `${API_URL}plot-analysis/plots/polygons?x=${lng}&y=${lat}`
      );
      if (Object.keys(response.data).length === 0) {
        dispatch(
          addNewNotification({
            text: i18n.t("plotAnalysisPage.noPlots"),
            variant: NotificationVariant.ERROR,
          })
        );
      }
      const plotsArray: Plot[] = Object.entries(response.data);
      return plotsArray.map((item: Plot) => {
        const plotId = item[0];
        const plotData = item[1];
        const geoJson: any = wellknown.parse(plotData.wkt);
        const coords: LatLngExpression[] = geoJson?.coordinates[0];
        return {
          coords: switchLatLng(coords),
          isSelected: false,
          wkt: plotData.wkt,
          plotId: Number(plotId),
          neighbors: plotData.neighbors,
        };
      });
    } catch (error) {
      dispatch(
        addNewNotification({
          text: i18n.t("reducersNotifications.plotSearch"),
          variant: NotificationVariant.ERROR,
        })
      );
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);

export const getPlotId = createAsyncThunk<any, PlotItem>(
  "plotId",
  async ({ wkt, plotId }, { dispatch, rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const alreadyFetchedPlot: PlotItem | undefined =
      state.plotSearch.fetchedPlots.find((plot) => plot.plotId === plotId);

    if (alreadyFetchedPlot) return { res: alreadyFetchedPlot, wkt };

    try {
      const response = await http.post(`${API_URL}plot-analysis/plots/get_id`, {
        plot_wkt: wkt,
      });
      return { res: response.data, wkt };
    } catch (error) {
      dispatch(
        addNewNotification({
          text: i18n.t("reducersNotifications.plotId"),
          variant: NotificationVariant.ERROR,
        })
      );
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);

export const getPaymentIntent = createAsyncThunk(
  "paymentIntent",
  async (plotsIds: string[], { dispatch, rejectWithValue }) => {
    try {
      const response = await http.post(`${API_URL}payments/create_intent`, {
        plot_external_ids: plotsIds,
      });
      dispatch(openPaymentModal());
      return response.data;
    } catch (error) {
      dispatch(
        addNewNotification({
          text: i18n.t("common.error"),
          variant: NotificationVariant.ERROR,
        })
      );
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);

export const plotSearchSlice = createSlice({
  name: "plots",
  initialState,
  reducers: {
    cleanPlotsList: (state) => {
      state.selectedPlots = [];
      state.fetchedPlots = [];
    },
    savePaymentIntent: (state, action) => {
      state.paymentIntent = action.payload.plotsIds;
    },
    removePlot: (state, action) => {
      const plotId = action.payload;

      const currentPlot = state.selectedPlots.find(
        (plot) => plot.plotId === plotId
      );

      const selectedItemNeigbors = state.selectedPlots.filter((item) =>
        item.neighbors.includes(plotId)
      );

      const remainingNeighborsIds = selectedItemNeigbors
        .map((item) => item.neighbors)
        .flat();

      const remainingPlotsAreNeighbors = selectedItemNeigbors.every((item) =>
        remainingNeighborsIds.includes(item.plotId)
      );

      const shouldRemoveCurrentPlot =
        selectedItemNeigbors.length <= 1 || remainingPlotsAreNeighbors;

      const updatedState = shouldRemoveCurrentPlot
        ? state.selectedPlots.filter(
            (selectedPlot) => selectedPlot.plotId !== plotId
          )
        : currentPlot
        ? [currentPlot]
        : [];

      state.selectedPlots = updatedState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlotsList.pending, (state) => {
      state.plotsListLoading = true;
    });
    builder.addCase(getPlotsList.fulfilled, (state, action) => {
      state.plotsList = action.payload;
      state.plotsListError = false;
      state.plotsListLoading = false;
      state.plotsListSuccess = true;
      state.selectedPlots = [];
      state.fetchedPlots = [];
      state.selectedPlotLoading = false;
    });
    builder.addCase(getPlotsList.rejected, (state) => {
      state.plotsListError = true;
      state.plotsListLoading = false;
      state.selectedPlotLoading = false;
    });
    builder.addCase(getPlotId.pending, (state) => {
      state.selectedPlotLoading = true;
    });
    builder.addCase(getPlotId.fulfilled, (state, action) => {
      const selectedPlot = state.plotsList.find(
        (item: PlotItem) => item.wkt === action.payload.wkt
      );
      if (!selectedPlot) {
        return;
      }
      const isNeighbor = state.selectedPlots.some((item: PlotItem) =>
        item.neighbors.includes(parseInt(selectedPlot.plotId))
      );

      const { region, commune, external_id, address, externalId } =
        action.payload.res;
      const updatedPlot = {
        ...selectedPlot,
        externalId: external_id || externalId,
        address: address || `${region?.length > 1 ? region : "-"}, ${commune}`,
      };

      if (!address) {
        state.fetchedPlots = [...state.fetchedPlots, updatedPlot];
      }

      if (isNeighbor) {
        state.selectedPlots = [...state.selectedPlots, updatedPlot];
      } else {
        state.selectedPlots = [updatedPlot];
      }
      state.selectedPlotLoading = false;
    });
    builder.addCase(getPlotId.rejected, (state) => {
      state.selectedPlotLoading = false;
    });
    builder.addCase(getPaymentIntent.pending, (state) => {
      state.paymentIntentLoading = true;
    });
    builder.addCase(getPaymentIntent.fulfilled, (state, action) => {
      state.paymentIntent = action.payload;
      state.paymentIntentLoading = false;
    });
    builder.addCase(getPaymentIntent.rejected, (state) => {
      state.paymentIntentLoading = false;
    });
  },
});

export const { cleanPlotsList, removePlot, savePaymentIntent } =
  plotSearchSlice.actions;
