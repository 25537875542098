import "../PlotSearch.styles.scss";
import { MapContainer, TileLayer } from "react-leaflet";
import MapFeatures from "./MapFeatures";
import SearchBox from "./SearchBox";
import ZoomOnClick from "./ZoomOnClick";
import { useEffect, useState, useRef } from "react";
import { getPlotsList } from "../PlotSearch.reducers";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { MapCentre } from "../interfaces";
import { CircularProgress } from "@mui/material";

const geographicalCentrePL: MapCentre = { lng: 19.252482, lat: 52.065221 };
const positionResetCoords = { lng: 0, lat: 0 };

const PlotSearchMap = () => {
  const { plotsListLoading } = useAppSelector((state) => state.plotSearch);
  const [position, setPosition] = useState<MapCentre>(geographicalCentrePL);
  const [zoom, setZoom] = useState<number>(6);
  const minZoomToDisplayPlots = 15;
  const dispatch = useAppDispatch();

  const prevPositionRef = useRef(positionResetCoords);
  useEffect(() => {
    const fetchPlotsList = async () => {
      await dispatch(getPlotsList({ lat: position.lat, lng: position.lng }));
    };

    if (zoom > minZoomToDisplayPlots) {
      if (prevPositionRef.current !== position) {
        prevPositionRef.current = position;
        fetchPlotsList();
      }
    }
  }, [position, zoom, dispatch]);

  return (
    <div className="map-wrapper">
      <MapContainer center={position} zoom={zoom} doubleClickZoom={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <SearchBox setZoom={setZoom} />
        <MapFeatures
          setPosition={setPosition}
          setZoom={setZoom}
          zoom={zoom}
          position={position}
          minZoomToDisplayPlots={minZoomToDisplayPlots}
        />
        <ZoomOnClick
          zoom={zoom}
          minZoomToDisplayPlots={minZoomToDisplayPlots}
        />
      </MapContainer>
      {plotsListLoading && <CircularProgress size="35px" />}
    </div>
  );
};

export default PlotSearchMap;
