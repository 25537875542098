export enum PaymentFlow {
  reportUser = "REPORT_USER",
  reportGuest = "REPORT_GUEST",
  kw = "KW",
}

export enum BillingDataViews {
  confirmData = "CONFIRM_DATA",
  incompleteData = "INCOMPLETE_DATA",
  form = "FORM",
  emptyState = "",
}

export interface PaymentStepSharedProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  flow: PaymentFlow;
  selectedPlotsIds?: string[];
}

export interface PaymentStepProps {
  productId?: string;
  invoiceFor: string;
}

export interface StepsWrapperProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  classname: string;
  children: React.ReactNode;
  stepsAmount: number;
}

export interface StepsConfigurationProps {
  sharedStepProps: PaymentStepSharedProps;
  paymentStepProps: PaymentStepProps;
  setInvoiceFor: React.Dispatch<React.SetStateAction<string>>;
  invoiceFor: string;
}

export interface PaymentModalProps {
  productId?: string;
  flow: PaymentFlow;
  selectedPlotsIds?: string[];
}

export interface PaymentFormProps {
  amount: number;
}

export interface PaymentProps extends PaymentStepSharedProps {
  productId?: string;
  invoiceFor: string;
  classname: string;
}

export interface PackagesProps extends PaymentStepSharedProps {
  error: string | null;
  classname: string;
}

export interface LoginProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  classname: string;
}

export interface ConfirmInvoiceDataProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  invoiceFor: string;
  setCurrentView: React.Dispatch<React.SetStateAction<BillingDataViews>>;
}

export interface ChooseBusinessEntityProps extends PaymentStepSharedProps {
  setInvoiceFor: React.Dispatch<React.SetStateAction<string>>;
  classname: string;
}

export interface BillingDataProps extends PaymentStepSharedProps {
  invoiceFor: string;
  classname: string;
}

export interface PaymentConfirmationProps {
  flow?: PaymentFlow;
  amount?: number;
}

export interface PaymentStatusMessageProps {
  closeModal: React.MouseEventHandler<HTMLButtonElement>;
  flow?: PaymentFlow;
  plotId?: string | null | undefined;
}

export interface PaymentErrorProps {
  flow?: PaymentFlow;
  amount: number;
	date: number
}