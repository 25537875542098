import ModalContainer from "../ModalContainer/ModalContainer";
import WhiteButton from "../../WhiteButton/WhiteButton";
import "./RenameModal.scss";
import PencilIcon from "../../../../assets/icons/pencil.svg";
import { addNewNotification } from "../../Notification/Notification.reducers";
import { NotificationVariant } from "../../Notification/Notification.types";
import { http } from "../../../../config/http";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { API_URL } from "../../../../config/config";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { updateReportName } from "../../../../lib/Report/Report/Report.reducers";
import * as Yup from "yup";
import { Formik } from "formik";

interface FormValuesProps {
  reportName: string;
}

const RenameModal = ({ onClose }: any) => {
  const {
    report: { task },
  }: any = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const submitForm = async (form: FormValuesProps) => {
    try {
      await http.post(`${API_URL}plot-analysis/tasks/${task?.id}/rename`, {
        name: form.reportName,
      });
      dispatch(
        addNewNotification({
          text: t("common.changesSaved"),
          variant: NotificationVariant.INFO,
        })
      );
      dispatch(updateReportName(form.reportName));
      onClose();
    } catch (e: any) {
      dispatch(
        addNewNotification({
          text: t("common.error"),
          variant: NotificationVariant.ERROR,
        })
      );
    }
  };

  return (
    <ModalContainer
      variant="reports-modal rename-modal"
      onClose={() => onClose()}
    >
      <p className="title">{t("reportModals.renameTitle")}</p>
      <p className="text">
        {t("reportModals.renameText")}
        <span>{task.name}</span>
      </p>
      <Formik<FormValuesProps>
        initialValues={{
          reportName: "",
        }}
        validationSchema={Yup.object({
          reportName: Yup.string().required(t("validation.fieldRequired")),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
        component={(props) => <Form {...props} />}
      />
    </ModalContainer>
  );
};

const Form: (props: any) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        className="change-report-name"
        fullWidth
        hiddenLabel
        name="reportName"
        placeholder={t("reportModals.renamePlaceholder")}
        error={!!errors.reportName && touched.reportName}
        helperText={touched.reportName ? errors.reportName : null}
        value={values.reportName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <WhiteButton text={t("common.change")} icon={PencilIcon} type="submit" />
    </form>
  );
};

export default RenameModal;
