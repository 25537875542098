import "./BasicInformation.scss";
import Section from "../SectionComponents/Section/Section";
import BasicInfoIcon from "../../../../../assets/icons/sidebar/basicInfo.svg";
import LocationIcon from "../../../../../assets/icons/report/location.svg";
import ForestIcon from "../../../../../assets/icons/report/forest.svg";
import AreaIcon from "../../../../../assets/icons/report/area.svg";
import GroundsIcon from "../../../../../assets/icons/report/grounds.svg";
import MPZIcon from "../../../../../assets/icons/report/mpz.svg";
import RoadsIcon from "../../../../../assets/icons/report/roads.svg";
import SectionSubtitle from "../SectionComponents/SectionSubtitle/SectionSubtitle";
import TreeIcon from "../../../../../assets/icons/report/yellowTree.svg";
import FloodplainsIcon from "../../../../../assets/icons/report/yellowFloodplains.svg";
import EnviromentProtectionIcon from "../../../../../assets/icons/report/yellowEnvProtection.svg";
import { useAppSelector } from "../../../../../redux/hooks";
import { Report } from "../../types";
import DataBox from "../SectionComponents/DataBox/DataBox";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";

interface ReportProps {
  report: Report;
  multiplot: boolean | null;
}
//todo remove when mulitplots endpoint is integrated
const mockPlotsAmount = 3;

function BasicInformation() {
  const {
    report: {
      area,
      external_ids,
      commune,
      county,
      voivodeship,
      urban_development_plan,
      plot_analysis,
      layers,
      grounds_district_portal,
      grounds,
      task,
      has_road_access,
    },
    multiplot,
  }: ReportProps = useAppSelector((state) => state.report);
  const forests = layers?.find((layer) => layer.type === "forests");
  const floodplains = layers?.find((layer) => layer.type === "floodplains");
  const environmentProtections = layers?.find(
    (layer) => layer.type === "environment_protections"
  );
  const groundsLayers = layers?.filter(
    (layer) => layer.type === "grounds" && layer.description
  );

  const { t } = useTranslation();

  const groundsClasses = () => {
    if (
      grounds_district_portal?.length > 0 &&
      grounds_district_portal[0] !== ""
    ) {
      return grounds_district_portal.map((item) => `${item} `).join("");
    } else if (grounds?.length > 0) {
      return grounds.map((item) => `${item} `).join("");
    } else if (groundsLayers?.length > 0) {
      return groundsLayers
        ?.map(
          (item, index) =>
            `${item.description} - ${
              item.percent
                ? `${((area / 10000) * item.percent).toFixed(2)} ha`
                : "-"
            }${groundsLayers.length - 1 === index ? "" : ", "}`
        )
        .join("");
    } else {
      return "-";
    }
  };

	const getUrbanDevelopmentPlan = () => {
    switch (urban_development_plan?.has_urban_development_plan) {
      case "nieobjęta":
        return t("report.basicInfo.mpzpNotEncompassed");
      case "objęta":
        return t("report.basicInfo.mpzpEncompassed");
      case "częściowo objęta":
        return t("report.basicInfo.mpzpPartiallyEncompassed");
      default:
        return t("report.noData");
    }
  };

  const createdAt =
    task?.created_at &&
    new Intl.DateTimeFormat(i18n.language, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date(task.created_at));

  const PreTitle = () => (
    <div className="report-content-header">
      <h1 className="report-content-id">
        {multiplot ? (
          <Trans
            i18nKey="report.headingMulti"
            components={[<span />]}
            values={{ amount: external_ids?.length || "" }}
          />
        ) : (
          <Trans
            i18nKey="report.heading"
            components={[<span />]}
            values={{ id: external_ids[0] }}
          />
        )}
      </h1>
      {createdAt && (
        <p className="report-content-date">
          <Trans i18nKey="report.date" values={{ date: createdAt }} />
        </p>
      )}
    </div>
  );

  return (
    <Section
      title={t("report.basicInfo.title")}
      preTitle={<PreTitle />}
      icon={BasicInfoIcon}
      id="basicInfo"
    >
      <div className="sub-section">
        <SectionSubtitle
          title={t("report.basicInfo.location")}
          icon={LocationIcon}
        />
        <div className="data location">
          <DataBox
            title={t("report.basicInfo.county")}
            content={commune || "-"}
          />
          <DataBox
            title={t("report.basicInfo.district")}
            content={county || "-"}
          />
          <DataBox
            title={t("report.basicInfo.voivodeship")}
            content={voivodeship || "-"}
          />
          <DataBox
            title={t("report.basicInfo.long")}
            content={plot_analysis?.latitude?.toString().substring(0, 5) || "-"}
          />
          <DataBox
            title={t("report.basicInfo.lat")}
            content={
              plot_analysis?.longitude?.toString().substring(0, 5) || "-"
            }
          />
          <DataBox
            title={t("report.basicInfo.altitude")}
            content={
              plot_analysis?.altitude
                ? `${plot_analysis?.altitude
                    ?.toString()
                    .substring(0, 5)}m n.p.m.`
                : "-"
            }
          />
        </div>
      </div>
      <div className="sub-section">
        <SectionSubtitle title={t("report.basicInfo.area")} icon={AreaIcon} />
        <div className="data area">
          <DataBox
            title={t("report.basicInfo.areaInHectare")}
            content={`${(area / 10000).toFixed(2)} ha` || "-"}
          />
        </div>
      </div>
      <div className="sub-section">
        <SectionSubtitle
          title={t("report.basicInfo.impediments")}
          icon={ForestIcon}
        />
        <div className="data difficulties">
          <DataBox
            title={t("report.basicInfo.forests")}
            content={forests?.percent ? t("common.yes") : t("common.no")}
            icon={TreeIcon}
            subtext={forests?.update_date && forests?.update_date}
          />
          <DataBox
            title={t("report.basicInfo.forestsArea")}
            content={
              forests?.percent
                ? `${((area / 10000) * forests.percent).toFixed(2)} ha`
                : "0 ha"
            }
          />
          <DataBox
            title={t("report.basicInfo.floodplains")}
            content={floodplains?.percent ? t("common.yes") : t("common.no")}
            icon={FloodplainsIcon}
            subtext={floodplains?.update_date && floodplains?.update_date}
          />
          <div></div>
          <DataBox
            title={t("report.basicInfo.enviromentProtections")}
            content={
              environmentProtections?.percent ? t("common.yes") : t("common.no")
            }
            icon={EnviromentProtectionIcon}
            subtext={
              environmentProtections?.update_date &&
              environmentProtections?.update_date
            }
          />
          <DataBox
            title={t("report.basicInfo.protectionType")}
            content={
              !environmentProtections?.percent
                ? "-"
                : environmentProtections.description
                ? environmentProtections.description
                : t("report.noData")
            }
          />
        </div>
      </div>
      <div className="sub-section">
        <SectionSubtitle
          title={t("report.basicInfo.grounds")}
          icon={GroundsIcon}
        />
        <div className="data grounds">
          <DataBox
            title={t("report.basicInfo.groundsTypes")}
            content={groundsClasses()}
          />
        </div>
      </div>
      <div className="sub-section">
        <SectionSubtitle
          title={t("report.basicInfo.roadAccess")}
          icon={RoadsIcon}
        />
        <div className="data roads">
          <DataBox
            title={t("report.basicInfo.roadAccess")}
            content={
              has_road_access === null
                ? t("report.noData")
                : has_road_access == false
                ? t("common.no")
                : t("common.yes")
            }
          />
        </div>
      </div>
      <div className="sub-section">
        <SectionSubtitle title={t("report.basicInfo.mpzp")} icon={MPZIcon} />
        <div className="data mpzp">
          <DataBox
            title={t("report.basicInfo.mpzpLong")}
            content={getUrbanDevelopmentPlan()}
          />
        </div>
      </div>
    </Section>
  );
}
export default BasicInformation;
