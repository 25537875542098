import { useState } from "react";
import "./Select.scss";
import TickIcon from "../../../../../../../assets/images/TickSmall.svg";
import { FormControl, MenuItem, Select } from "@mui/material";
import i18n from "i18next";

type Props = {
  activeTab: any;
  setActiveTab: any;
  allOptionsList: any;
};

const CategoriesSelect = ({
  activeTab,
  setActiveTab,
  allOptionsList,
}: Props) => {
  const optionsList = [
    { key: "area", name: i18n.t("report.areaMap.areaBtn") },
    {
      key: "grounds",
      name: i18n.t("report.areaMap.groundsBtn"),
    },
    {
      key: "impediments",
      name: i18n.t("report.areaMap.impedimentsBtn"),
    },
  ];

  const [selectedValue, setSelectedVal] = useState<string>(activeTab);

  const handleChange = (value: any) => {
    setSelectedVal(value);
    setActiveTab(value);
  };

  return (
    <FormControl size="small" className="category-select">
      <Select
        value={selectedValue}
        onChange={(e) => handleChange(e.target.value)}
        renderValue={() => {
          return (
            <p>
              {optionsList.find((option) => option.key === selectedValue)?.name}
            </p>
          );
        }}
      >
        {optionsList.map(({ key, name }) => (
          <MenuItem
            value={key}
            className="category-select-menu"
            key={name}
            disabled={key !== "area" && !allOptionsList[key].length}
          >
            <p>{name}</p>
            {selectedValue === key && <img width={20} src={TickIcon} />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategoriesSelect;
