import { useState } from "react";
import "./Sidebar.scss";
import SidebarWrapper from "../../../../common/components/SidebarWrapper/SidebarWrapper";
import NavItem from "./NavItem";
import AvatarIcon from "../../../../assets/icons/sidebar/avatar-man.svg";
import InvoiceIcon from "../../../../assets/icons/sidebar/invoice.svg";
import SettingsIcon from "../../../../assets/icons/sidebar/repair-tool.svg";
import ReportsListIcon from "../../../../assets/icons/menuReportsList.svg";
import PlotAnalysesIcon from "../../../../assets/icons/menuPlotAnalysis.svg";
import MoneyIcon from "../../../../assets/icons/sidebar/money.svg";
import { useWindowSize } from "../../../../common/hooks";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const isMobile = windowSize.width !== 0 && windowSize.width < 769;

  const menuItems = [
    {
      text: t("common.plotAnalysis"),
      icon: PlotAnalysesIcon,
      path: "/plot-analysis",
    },
    {
      text: t("common.reportsList"),
      icon: ReportsListIcon,
      path: "/reports",
    },
  ];

  const navItems = [
    {
      text: t("userData.personalData"),
      path: "/profile/personal-data",
      icon: AvatarIcon,
    },
    {
      text: t("userData.invoiceData"),
      path: "/profile/invoice-data",
      icon: InvoiceIcon,
    },
    {
      text: t("paymentsHistory.title"),
      path: "/profile/payments-history",
      icon: MoneyIcon,
    },
    {
      text: t("accountSettings.title"),
      path: "/profile/account-settings",
      icon: SettingsIcon,
    },
  ];

  const [open, setOpen] = useState<boolean>(!isMobile);

  return (
    <SidebarWrapper setOpen={setOpen} open={open} isMobile={isMobile}>
      <div className="profile-sidebar">
        <div className={`profile-nav ${!open ? "is-closed" : ""}`}>
          {menuItems.map((item) => (
            <NavItem
              text={item.text}
              path={item.path}
              key={item.path}
              icon={item.icon}
              onClick={() => {
                if (isMobile) setOpen(false);
              }}
            />
          ))}
        </div>

        <div className="profile-list-wrap">
          {navItems.map((item) => (
            <NavItem
              text={item.text}
              path={item.path}
              key={item.path}
              icon={item.icon}
              onClick={() => {
                if (isMobile) setOpen(false);
              }}
            />
          ))}
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
