import { useCallback, useEffect } from "react";
import { Polygon, useMap } from "react-leaflet";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { MapFeaturesProps, PlotItem } from "../interfaces";
import { getPlotId, removePlot } from "../PlotSearch.reducers";

const getStyles = (arr: PlotItem[], wkt: string) => {
  const selected = !!arr.some((item: PlotItem) => item.wkt === wkt)
    
  return {
    color: selected ? "#467f1a" : "#080606",
    fillColor: selected ? "#467f1a" : "transparent",
    weight: selected ? 3 : 1,
  };
}

const MapFeatures = ({
  setPosition,
  setZoom,
  zoom,
  position,
  minZoomToDisplayPlots,
}: MapFeaturesProps) => {
  const { plotsList, selectedPlots, selectedPlotLoading, plotsListLoading } =
    useAppSelector((state) => state.plotSearch);
  const map = useMap();
  const dispatch = useAppDispatch();

  const onMapMove = useCallback(() => {
    const previousCentre = position;
    const newCentre = map.getCenter();

    const diffInDistanceLng = Math.abs(previousCentre.lng - newCentre.lng);
    const diffInDistanceLat = Math.abs(previousCentre.lat - newCentre.lat);

    if (diffInDistanceLng > 0.008 || diffInDistanceLat > 0.006) {
      setPosition(map.getCenter());
    }
  }, [map, setPosition, position]);

  const onMapZoom = useCallback(() => {
    setZoom(map.getZoom());
  }, [map, setZoom]);

  useEffect(() => {
    map.on("moveend", onMapMove);
    map.on("zoomend", onMapZoom);
    return () => {
      map.off("moveend", onMapMove);
      map.off("zoomend", onMapZoom);
    };
  }, [map, onMapMove, onMapZoom]);

  const handlePolygonClick = async (plot: PlotItem) => {
    if (!plotsListLoading) {
      const selectedPlot = selectedPlots.find(
        (item: PlotItem) => item.wkt === plot.wkt
      );
      if (!selectedPlot && !selectedPlotLoading) {
        dispatch(getPlotId(plot));
      } else {
        dispatch(removePlot(plot.plotId));
      }
    }
  };

  return zoom > minZoomToDisplayPlots
    ? plotsList.map((plot: PlotItem) => {
        return (
          <Polygon
            key={plot.plotId}
            positions={plot.coords}
            pathOptions={getStyles(selectedPlots, plot.wkt)}
            eventHandlers={{
              click: () => {
                selectedPlotLoading ? undefined : handlePolygonClick(plot);
              },
            }}
          ></Polygon>
        );
      })
    : null;
};

export default MapFeatures;
