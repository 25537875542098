import { useEffect, useState } from "react";
import WhiteButton from "../../../WhiteButton/WhiteButton";
import UserIcon from "../../../../../assets/icons/sidebar/avatar-man.svg";
import BuildingIcon from "../../../../../assets/icons/building.svg";
import CompanyForm from "../../../../../lib/Profile/components/InvoiceData/CompanyForm";
import NaturalPersonForm from "../../../../../lib/Profile/components/InvoiceData/NaturalPersonForm";
import ConfirmInvoiceData from "./ConfirmInvoiceData";
import { useAppSelector } from "../../../../../redux/hooks";
import { InvoiceData } from "../../../../../lib/Auth/Auth.reducers";
import { useTranslation } from "react-i18next";
import { BillingDataProps, BillingDataViews } from "../interfaces";

const BillingData = ({
  setActiveStep,
  invoiceFor,
  activeStep,
  flow,
}: BillingDataProps) => {
  const { profile } = useAppSelector((state) => state.auth);
  const [currentView, setCurrentView] = useState<BillingDataViews>(BillingDataViews.emptyState);
  const { t } = useTranslation();

  useEffect(() => {
    if (profile?.invoice?.b && invoiceFor === "b") {
      const { companyName, nip, houseNr, street, postCode, city, email } = (
        profile?.invoice as InvoiceData
      ).b;
      if (
        companyName &&
        nip &&
        houseNr &&
        street &&
        postCode &&
        city &&
        email
      ) {
        setCurrentView(BillingDataViews.confirmData);
      } else {
        setCurrentView(BillingDataViews.incompleteData);
      }
    } else if (profile?.invoice?.np && invoiceFor === "np") {
      const { firstName, lastName, houseNr, street, postCode, city, email } = (
        profile?.invoice as InvoiceData
      ).np;
      if (
        firstName &&
        lastName &&
        houseNr &&
        street &&
        postCode &&
        city &&
        email
      ) {
        setCurrentView(BillingDataViews.confirmData);
      } else {
        setCurrentView(BillingDataViews.incompleteData);
      }
    } else setCurrentView(BillingDataViews.form);
  }, [invoiceFor, profile?.invoice]);

  return (
   <div className="inner">
        {currentView === BillingDataViews.confirmData && (
          <ConfirmInvoiceData
            setActiveStep={setActiveStep}
            invoiceFor={invoiceFor}
            activeStep={activeStep}
            setCurrentView={setCurrentView}
          />
        )}
        {currentView === BillingDataViews.incompleteData && (
          <div className="incomplete">
            <p className="title">{t("paymentModal.incompleteData")}</p>
            <WhiteButton
              text={t("paymentModal.fillData")}
              icon={invoiceFor === "b" ? BuildingIcon : UserIcon}
              onClick={() => setCurrentView(BillingDataViews.form)}
            />
          </div>
        )}
        {currentView === BillingDataViews.form && invoiceFor === "b" && (
          <CompanyForm setCurrentView={setCurrentView} flow={flow} />
        )}
        {currentView === BillingDataViews.form && invoiceFor === "np" && (
          <NaturalPersonForm setCurrentView={setCurrentView} flow={flow} />
        )}
      </div>
  );
};

export default BillingData;
