import { useEffect } from "react";
import { useMap } from "react-leaflet";
import * as GeoSearch from "leaflet-geosearch";
import { useTranslation } from "react-i18next";
import { SearchBoxProps } from "../interfaces";

const SearchBox = ({ setZoom }: SearchBoxProps) => {
  const { t } = useTranslation();
  const map = useMap();

  const searchControl = GeoSearch.GeoSearchControl({
    provider: new GeoSearch.OpenStreetMapProvider({
      params: {
        "accept-language": "pl",
        countrycodes: "pl",
      },
    }),
    style: "bar",
    searchLabel: t("plotAnalysisPage.typeAddress"),
    notFoundMessage: t("common.notFound"),
    showMarker: false,
  });

  useEffect(() => {
    map.addControl(searchControl);
    setZoom(map.getZoom());
    return () => {
      map.removeControl(searchControl);
    };
  }, [map, searchControl, setZoom]);

  return null;
};

export default SearchBox;
