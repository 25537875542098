import "./RegisterNr.scss";
import Section from "../SectionComponents/Section/Section";
import RegisterNrIcon from "../../../../../assets/icons/sidebar/registerNr.svg";
import WhiteButton from "../../../../../common/components/WhiteButton/WhiteButton";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import PaymentModal from "../../../../../common/components/Modals/PaymentModal/PaymentModal";
import { useTranslation } from "react-i18next";
import RegisterNr from "./RegisterNr";
import { PaymentFlow } from "../../../../../common/components/Modals/PaymentModal/interfaces";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../../../../config/config";
import { Elements } from "@stripe/react-stripe-js";
import PaymentConfirmationModal from "../../../../../common/components/Modals/PaymentConfirmationModal/PaymentConfirmation";
import { getPaymentIntent } from "../../../../PlotSearch/PlotSearch.reducers";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface RegisterDataProps {
  external_id: string;
  is_paid: boolean;
  identity: string | null;
}

const RegisterNrSection = () => {
  const { hash } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hash === "#registerNr" && containerRef) {
      containerRef.current?.focus();
    }
  }, [hash]);

  const {
    report: { land_register },
  } = useAppSelector((state) => state.report);
  const { showPaymentModal } = useAppSelector(
    (state) => state.paymentModal.payment
  );
	const { appLang } = useAppSelector(
    (state) => state.auth
  );
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const plotsWithUnpaidKW = land_register
    ?.filter((registerData: RegisterDataProps) => !registerData.is_paid)
    .map((registerData: RegisterDataProps) => registerData.external_id);

  const handlePaymentForAll = () => {
    dispatch(getPaymentIntent(plotsWithUnpaidKW));
  };

  return (
    <Section
      title={t("report.registerNr.title")}
      icon={RegisterNrIcon}
      id="registerNr"
      grayBg
      innerRef={containerRef}
    >
      <ul className="register-nr-list">
        {land_register?.map((registerNrData: RegisterDataProps) => (
          <RegisterNr
            key={registerNrData.external_id}
            registerNrData={registerNrData}
          />
        ))}
      </ul>

      {plotsWithUnpaidKW.length > 1 && (
        <WhiteButton
          text={t("common.payForAll")}
          animatedIcon="payment"
          onClick={handlePaymentForAll}
          variant="big-centered"
        />
      )}

      {showPaymentModal && <PaymentModal flow={PaymentFlow.kw} />}
      {
        <Elements
          options={{ appearance: { theme: "stripe" }, locale: appLang }}
          stripe={stripePromise}
        >
          <PaymentConfirmationModal flow={PaymentFlow.kw} />
        </Elements>
      }
    </Section>
  );
};

export default RegisterNrSection;
