import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewNotification } from "../../common/components/Notification/Notification.reducers";
import { NotificationVariant } from "../../common/components/Notification/Notification.types";
import { http } from "../../config/http";
import { API_URL } from "../../config/config";
import i18n from "i18next";

interface PaymentsState {
  paymentsHistory: any;
  loading: boolean;
  error: boolean;
  success: boolean;
}

const initialState: PaymentsState = {
  paymentsHistory: [],
  loading: false,
  error: false,
  success: false,
};

export const getPaymentsList = createAsyncThunk<
  PaymentsState,
  { page: number }
>("payments", async ({ page }, { dispatch, rejectWithValue }) => {
  const index = ((page - 1) * 10).toString();
  try {
    const response = await http.get(
      `${API_URL}payments/transactions?ordering=-created_at&limit=10&index=${index}`
    );
    return response.data;
  } catch (error) {
    dispatch(
      addNewNotification({
        text: i18n.t("reducersNotifications.profile"),
        variant: NotificationVariant.ERROR,
      })
    );
    if (error instanceof Error) {
      return rejectWithValue({ error: error.message });
    }
  }
});

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentsList.fulfilled, (state, action) => {
      state.paymentsHistory = action.payload;
      state.error = false;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getPaymentsList.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});
