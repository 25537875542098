import React from "react";
import "./ReportsList.scss";
import Pagination from "@mui/material/Pagination";
import { useAppSelector } from "../../../../../redux/hooks";
import ReportItem from "./ReportItem";
import { ReportItemInterface } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ReportsListProps {
  page: number;
  setPage: any;
}

const ReportsList = ({ page, setPage }: ReportsListProps) => {
  const { items: reportsList } = useAppSelector((state) => state.reportsList);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const totalPagesCount = useAppSelector((state) => {
    return Math.ceil(state.reportsList.total / 10);
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (value === 1) {
      navigate(`/reports`);
    } else {
      navigate(`/reports/?p=${value}`);
    }
  };

  return (
    <div className="reports-list">
      <ul>
        <li className="report-item report-item-head">
          <span className="report-id">{t("reportsList.tableColName")}</span>
          <span className="report-date">{t("reportsList.tableColDate")}</span>
          <span className="report-status center">
            {t("reportsList.tableColStatus")}
          </span>
          <span className="report-button center">
            {t("reportsList.tableColAction")}
          </span>
        </li>
        {reportsList.length > 0 ? (
          reportsList.map((report: ReportItemInterface) => (
            <ReportItem report={report} key={report.created_at} />
          ))
        ) : (
          <h2>{t("reportsList.noReports")}</h2>
        )}
      </ul>
      <Pagination
        style={{ opacity: reportsList.length > 0 ? "1" : "0" }}
        count={totalPagesCount}
        showFirstButton
        showLastButton
        page={page}
        onChange={handleChange}
        shape="rounded"
      />
    </div>
  );
};

export default ReportsList;
