export interface GroupsUsersState {
  notifications: any[];
  latestNotification: any;
}

export enum NotificationVariant {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
}

export type Notification = {
  text?: string;
  textWithLinks?: {
    i18nKey: string;
    links: string[];
  };
  variant: NotificationVariant;
  coloredText?: string;
  button?: boolean;
};

export type Action = {
  payload: Notification;
};

export interface MessageProps {
  notification: {
    variant: string;
    text?: string;
    textWithLinks?: {
      i18nKey: string;
      links: string[];
    };
    coloredText?: string;
    button?: boolean;
  };
}
