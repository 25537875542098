import "./ElectricityParams.scss";
import Section from "../SectionComponents/Section/Section";
import ElectricityLinesIcon from "../../../../../assets/icons/report/electricityParams.svg";
import ElectricityParamsIcon from "../../../../../assets/icons/sidebar/electricityParams.svg";
import GPZIcon from "../../../../../assets/icons/report/gpz.svg";
import ElectricityMapIcon from "../../../../../assets/icons/report/electricityMap.svg";
import SectionSubtitle from "../SectionComponents/SectionSubtitle/SectionSubtitle";
import DataBox from "../SectionComponents/DataBox/DataBox";
import { useAppSelector } from "../../../../../redux/hooks";
import { Report } from "../../types";
import ErrorIcon from "../../../../../assets/icons/report/error.svg";
import ElectricityMap from "./ElectricityMap/ElectricityMap";
import { useTranslation } from "react-i18next";

interface ReportProps {
  report: Report;
  loading: boolean;
}

function ElectricityParams() {
  const { report, loading }: ReportProps = useAppSelector(
    (state) => state.report
  );
  const cableData = report.electricity?.find((item) => item.type === "cable");
  const substationData = report.electricity?.find(
    (item) => item.type === "substation"
  );
  const mapError = !report?.plot_analysis?.latitude;

  const { t } = useTranslation();

  return (
    <Section
      title={t("report.electricityParams.title")}
      icon={ElectricityParamsIcon}
      id="electricityParams"
      borderless
    >
      {report.electricity ? (
        <>
          <div className="sub-section">
            <SectionSubtitle
              title={t("report.electricityParams.closestElectricityLine")}
              icon={ElectricityLinesIcon}
            />
            <div className="data electricity-lines">
              <DataBox
                title={t("report.electricityParams.distance")}
                content={
                  cableData?.distance
                    ? `${Math.round(cableData?.distance)} m`
                    : t("report.noData")
                }
              />
              <DataBox
                title={t("report.electricityParams.power")}
                content={
                  cableData?.voltages[0]
                    ? `${cableData?.voltages[0] / 1000} kV`
                    : t("report.noData")
                }
              />
            </div>
          </div>
          <div className="sub-section">
            <SectionSubtitle
              title={t("report.electricityParams.closestGPZ")}
              icon={GPZIcon}
            />
            <div className="data gpz">
              <DataBox
                title={t("report.electricityParams.name")}
                content={
                  substationData?.name
                    ? `${substationData?.name}`
                    : t("report.noData")
                }
              />
              <DataBox
                title={t("report.electricityParams.distance")}
                content={
                  substationData?.distance
                    ? `${Math.round(substationData?.distance)} m`
                    : t("report.noData")
                }
              />
              <DataBox
                title={t("report.electricityParams.power")}
                content={
                  substationData?.voltages[0]
                    ? `${substationData?.voltages[0] / 1000} kV`
                    : t("report.noData")
                }
              />
            </div>
          </div>
          <div className="sub-section">
            <SectionSubtitle
              title={t("report.electricityParams.map")}
              icon={ElectricityMapIcon}
            />

            {!mapError && !loading ? (
              <ElectricityMap />
            ) : (
              <div className="data electricity-map-error">
                <img src={ErrorIcon} alt="" />
                <p>
                  {t("report.electricityParams.error", {
                    entity: "mapy sieci energetycznej",
                  })}
                </p>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="sub-section">
          <div className="data electricity-map-error">
            <img src={ErrorIcon} alt="" />
            <p>
              {t("report.electricityParams.error", {
                entity: "danych parametrów energetycznych",
              })}
            </p>
          </div>{" "}
        </div>
      )}
    </Section>
  );
}
export default ElectricityParams;
