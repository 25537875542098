import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReportsList from "./lib/Report/ReportsList/ReportsList.page";
import Report from "./lib/Report/Report/Report.page";
import SharedReport from "./lib/Report/Report/Report.shared";
import Profile from "./lib/Profile/Profile.page";
import PersonalData from "./lib/Profile/components/PersonalData/PersonalData";
import InvoiceData from "./lib/Profile/components/InvoiceData/InvoiceData";
import PaymentsHistory from "./lib/Profile/components/PaymentsHistory/PaymentsHistory";
import WelcomePage from "./lib/Welcome/Welcome.page";
import Register from "./lib/Auth/Register/Register.page";
import Login from "./lib/Auth/Login/Login.page";
import ForgotPassword from "./lib/Auth/ForgotPassword/ForgotPassword";
import EmailConfirmed from "./lib/Auth/EmailConfirmed/EmailConfirmed";
import Confirm from "./lib/Auth/Register/Confirm.page";
import ErrorPage from "./lib/Error/Error.page";
import Logout from "./lib/Auth/Logout/Logout.page";
import PlotSearch from "./lib/PlotSearch/PlotSearch.page";
import { PrivateRoute } from "./common/components/Routers/PrivateRoute";
import { PublicRoute } from "./common/components/Routers/PublicRoute";
import { useAppDispatch } from "./redux/hooks";
import { useEffect } from "react";
import { initUser } from "./lib/Auth/Auth.reducers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPassword from "./lib/Auth/ForgotPassword/NewPassword";
import RefreshReport from "./lib/Report/Report/Report.refresh";
import AccountSettings from "./lib/Profile/components/AccountSettings/AccountSettings";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initUser());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="logout" element={<Logout />} />
        <Route path="share/:token/*" element={<SharedReport />} />
        <Route path="plot-analysis" element={<PlotSearch />} />
        <Route element={<PublicRoute />}>
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="confirm" element={<Confirm />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="new-password" element={<NewPassword />} />
          <Route path="email-confirmed" element={<EmailConfirmed />} />
        </Route>
        <Route element={<PrivateRoute sidebar />}>
          <Route path="reports/:id/*" element={<Report />} />
          <Route path="profile" element={<Profile />}>
            <Route path="personal-data" element={<PersonalData />} />
            <Route path="invoice-data" element={<InvoiceData />} />
            <Route path="payments-history" element={<PaymentsHistory />} />
            <Route path="account-settings" element={<AccountSettings />} />
          </Route>
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="social-register"
            element={<WelcomePage socialRegister />}
          />
          <Route path="/report-refresh" element={<RefreshReport />} />
          <Route path="/" element={<WelcomePage />} />
          <Route path="reports" element={<ReportsList />} />
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
