import { useState } from "react";
import { Polyline } from "react-leaflet";

const CustomPolyline = ({
  children,
  positions,
  color,
  closestPole,
  clearPole,
  clearLine,
  clearPlotePoint,
}: any) => {
  const [isHover, setIsHover] = useState(false);

  const handleOffLine = () => {
    if (clearPole && clearLine) {
      clearLine(null);
      clearPole(null);
    }
    if (clearPlotePoint) {
      clearPlotePoint(null);
    }
  };
  return (
    <>
      <Polyline
        pathOptions={{
          color: "transparent",
          weight: 30,
        }}
        positions={positions}
        eventHandlers={{
          mouseover: () => setIsHover(true),
          mouseout: () => setIsHover(false),
          click: (e) => (closestPole ? closestPole(e) : null),
          dblclick: () => handleOffLine(),
        }}
      >
        {children}
      </Polyline>
      <Polyline
        pathOptions={{
          color: color,
          weight: isHover ? 3 : 2,
          opacity: isHover ? 1 : 0.9,
        }}
        positions={positions}
      />
    </>
  );
};
export default CustomPolyline;
