import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Auth } from "@aws-amplify/auth";
import AlertNotification from "./common/components/Notification/Notification";
import {
  COGNITO_REGION,
  COGNITO_USER_POOL_CLIENT_ID,
  COGNITO_USER_POOL_ID,
} from "./config/config";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_pl from "../src/translations/pl.json";
import common_en from "../src/translations/en.json";
import { setupStore } from "./redux/store";
import ReactGA from "react-ga4";

Auth.configure({
  region: COGNITO_REGION,
  userPoolId: COGNITO_USER_POOL_ID,
  userPoolWebClientId: COGNITO_USER_POOL_CLIENT_ID,
  oauth: {
    domain: "auth.sunmetric.io",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: window.location.origin + "/",
    redirectSignOut: window.location.origin + "/",
    responseType: "code",
  },
});

ReactGA.initialize("G-YG1682YD3V");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

i18next.init({
  returnNull: false,
  interpolation: { escapeValue: false },
  lng: "pl",
  resources: {
    pl: {
      translation: common_pl,
    },
    en: {
      translation: common_en,
    },
  },
});
const store = setupStore();

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <AlertNotification />
      <App />
    </I18nextProvider>
  </Provider>
);

reportWebVitals();
